import React, {useState, useEffect} from "react";
import {Box, MenuItem, Typography} from "@mui/material";
import {useGlobalContext} from "context/global-context";
import {EntityResponse, getEntities} from "services/entities";
import {useFieldArray, useForm} from "react-hook-form";
import {relationships} from "constants/entity-relationships";
import SelectComponent from "components/shared/select/select.component";
import theme from "theme";
import {ChevronDown} from "lucide-react";
import { RelatedConfig, RelationshipType } from "constants/joins";
import ButtonComponent from "components/shared/button/button.component";
import EntityRelatedAssetOrEntityBox from "components/add-edit-business-entity-component/related-join/entity-related-asset-or-entity-box";
import { DataAssetColumn } from "services/data-assets";

export interface RelatedEntitys {
    id: number;
    entity: EntityResponse | null;
    relationship?: string;
}

export interface RelatedEntitiesSelectData {
    entities: RelatedConfig[];
}

export interface EntityData {
    id: number;
    entity: string;
    relationship: string;
}

interface RelatedEntitiesSelectProps {
    initialValues: RelatedEntitiesSelectData | null;
    onChange: (value: RelatedEntitiesSelectData) => void;
    entityColumns: DataAssetColumn[],
    entityName: string,
}

const RelatedEntitiesSelect: React.FC<RelatedEntitiesSelectProps> = ({
    initialValues, 
    onChange,
    entityColumns,
    entityName,
}) => {
    const [relatedEntities, setRelatedEntities] = useState<RelatedConfig[]>(initialValues?.entities || []);
    const [idCounter, setIdCounter] = useState<number>(0);

    const addRelatedEntity = () => {
            setRelatedEntities([
                {id: idCounter, destinationType:"entity", joins: [], relationship: RelationshipType.OneToOne },
                ...relatedEntities,
            ]);
            setIdCounter(idCounter + 1)
        };

    const onRelatedEntityChanged = (newRelatedEntity: RelatedConfig | null, index: number)=>{
            let newRelatedEntities:RelatedConfig[] = []
            // Removed
            if (newRelatedEntity===null){
                newRelatedEntities = relatedEntities.filter((a, i)=> i!==index)
            } else {
                // Updated
                newRelatedEntities = relatedEntities.map((prevRelatedEntity, assetIndex) =>
                    index === assetIndex
                        ? newRelatedEntity
                        : prevRelatedEntity
                )
                
            }
            setRelatedEntities(newRelatedEntities);
            onChange({entities: newRelatedEntities})
        }

    
    
    useEffect(()=>{
        setTimeout(()=>window.scrollTo({ top: 0, behavior: 'smooth' }),0);
    },[])

    return (
        <>
            <ButtonComponent
                onClick={addRelatedEntity}
                variant="contained"
                startIcon={
                    <span
                        className="material-symbols-outlined"
                        style={{
                            fontSize: "20px",
                            color: theme.palette.customColor.white,
                        }}
                    >
                        add
                    </span>
                }
                label="New related entity"
                sx={{ marginBottom: "50px", marginTop: "24px" }}
            />
            <Box className="flex-box-col" sx={{width:"100%", gap:"32px"}}>
                {relatedEntities.map((relatedEntity, index) => (
                    <EntityRelatedAssetOrEntityBox 
                        key={`${relatedEntity.id}`}
                        relatedConfig={relatedEntity} 
                        onChange={(newRelatedEntity)=>onRelatedEntityChanged(newRelatedEntity, index)}
                        entityColumns={entityColumns}
                        entityName={entityName}
                        initialValues={initialValues}/>
                ))}
            </Box>
        </>
    );
};

export default RelatedEntitiesSelect;
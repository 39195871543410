import React, { useEffect, useState } from "react";
import theme from "theme";
import ButtonComponent from "../../shared/button/button.component";
import { RelatedConfig, RelationshipType } from "constants/joins";
import EntityRelatedAssetOrEntityBox from "../related-join/entity-related-asset-or-entity-box";
import { Box } from "@mui/material";
import { RelatedAssetsStepFormData } from "../add-edit-business-entities-related-datasets/add-edit-business-entities-related-datasets.component";
import { DataAssetColumn } from "services/data-assets";

interface RelatedDataAssetProps {
    initialValues: RelatedAssetsStepFormData;
    onChange: (data: RelatedAssetsStepFormData) => void;
    entityColumns: DataAssetColumn[];
    entityName: string;
}

const RelatedDataAsset: React.FC<RelatedDataAssetProps> = ({
    initialValues,
    onChange,
    entityColumns,
    entityName,
}) => {
    const [relatedAssets, setRelatedAssets] = useState<RelatedConfig[]>(initialValues.assets);
    const [idCounter, setIdCounter] = useState<number>(0);

    const addRelatedAsset = () => {
        setRelatedAssets([
            {id: idCounter, destinationType:"asset", joins: [], relationship: RelationshipType.OneToOne },
            ...relatedAssets,
        ]);
        setIdCounter(idCounter + 1)
    };

    const onRelatedAssetChanged = (newRelatedAsset: RelatedConfig | null, index: number)=>{
        let newRelatedAssets:RelatedConfig[] = []
        // Removed
        if (newRelatedAsset===null){
            newRelatedAssets = relatedAssets.filter((a, i)=> i!==index)
        } else {
            // Updated
            newRelatedAssets = relatedAssets.map((prevRelatedAsset, assetIndex) =>
                index === assetIndex
                    ? newRelatedAsset
                    : prevRelatedAsset
            );
        }
        setRelatedAssets(newRelatedAssets)
        onChange({assets: newRelatedAssets})
    }

    useEffect(()=>{
            window.scrollTo({ top: 0, behavior: 'smooth' });
    },[])

    return (
        <>
            <ButtonComponent
                onClick={addRelatedAsset}
                variant="contained"
                startIcon={
                    <span
                        className="material-symbols-outlined"
                        style={{
                            fontSize: "20px",
                            color: theme.palette.customColor.white,
                        }}
                    >
                        add
                    </span>
                }
                label="New related data asset"
                sx={{ marginBottom: "50px", marginTop: "24px" }}
            />
            <Box className="flex-box-col" sx={{width:"100%", gap:"32px"}}>
                {relatedAssets.map((relatedAsset, index) => (
                    <EntityRelatedAssetOrEntityBox 
                        key={`${relatedAsset.id}`}
                        relatedConfig={relatedAsset} 
                        onChange={(newRelatedAsset)=>onRelatedAssetChanged(newRelatedAsset, index)}
                        entityColumns={entityColumns}
                        entityName={entityName}
                        initialValues={initialValues}/>
                ))}
            </Box>
        </>
    );
};

export default RelatedDataAsset;
import React, { useEffect } from "react";
import {Box, Container} from "@mui/material";
import DataAssetsTable from "./data-assets-table/data-assets-table.component";
import DataAssetsTitle from "./data-assets-title/data-assets-titile.component";
import { usePageTitle } from "hooks/title";

const DataAssetsComponent: React.FC = () => {
    const {setTitle} = usePageTitle();
    
    useEffect(()=>setTitle('Data Assets'),[])
    return (
        <Container sx={{
            height: "100%"
        }}>
            <Box
                className="flex-box-start"
                flexDirection="column"
            >
                <Box>
                    <DataAssetsTitle/>
                    <DataAssetsTable/>
                </Box>
            </Box>
        </Container>
    );
};

export default DataAssetsComponent;

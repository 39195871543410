import React, { useState } from "react";
import { Box } from "@mui/material";
import AddFeaturesTitleSearch from "./add-features-modal-content/add-features-title-search/add-features-title-search";
import { DataAssetFullResponse } from "services/data-assets";
import AddFeaturesMetricModalData from "./add-features-modal-content/add-features-modal-data/add-features-metric-modal-data";
import AddFeaturesFirstLastModalData from "./add-features-modal-content/add-features-modal-data/add-features-first-last-modal-data";
import { FeatureModelType } from "./feature-types";
import AddFeaturesFieldModalData
    from "./add-features-modal-content/add-features-modal-data/add-features-field-modal-data";
import { RelatedAssetsListRow } from "./add-features-modal-content/add-features-modal-data/related-assets-list";

interface AddFeaturesMetricProps {
    type: FeatureModelType;
    handleCloseModal: () => void;
}

const AddFeaturesModal: React.FC<AddFeaturesMetricProps> = ({type, handleCloseModal}) => {
    const [searchValue, setSearchValue] = useState<string>("");
    const [selectedAsset, setSelectedAsset] = useState<DataAssetFullResponse | null>(null);
    const [selectedAssetJoinName, setSelectedAssetJoinName] = useState<string | undefined>(undefined);
    
    const onAssetChanged = (changed : {asset: DataAssetFullResponse | null, options?: RelatedAssetsListRow}) =>{
        setSelectedAsset(changed.asset)
        setSelectedAssetJoinName(changed.options?.joinName)
    }

    return (
        <Box className="flex-box-col-start" sx={{ gap: "2px", height: "100%", overflowY: "auto" }}>
            <AddFeaturesTitleSearch
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                selectedAsset={selectedAsset}
                title={`Add ${type}`}
            />
            {type === "field" && (
                <AddFeaturesFieldModalData
                    searchValue={searchValue}
                    selectedAsset={selectedAsset}
                    selectedAssetJoinName={selectedAssetJoinName}
                    onAssetChanged={onAssetChanged}
                    handleCloseModal={handleCloseModal}
                />
            )}
            {type === "metric" && (
                <AddFeaturesMetricModalData
                    searchValue={searchValue}
                    selectedAsset={selectedAsset}
                    selectedAssetJoinName={selectedAssetJoinName}
                    onAssetChanged={onAssetChanged}
                    handleCloseModal={handleCloseModal}
                />
            )}
            {type === "first/last" && (
                <AddFeaturesFirstLastModalData
                    searchValue={searchValue}
                    selectedAsset={selectedAsset}
                    selectedAssetJoinName={selectedAssetJoinName}
                    onAssetChanged={onAssetChanged}
                    handleCloseModal={handleCloseModal}
                />
            )}
        </Box>
    );
};

export default AddFeaturesModal;
import axiosInstance from "../utils/axios";

export interface AppSettings {
    frontegg: {
        base_url: string,
        app_id: string,
        client_id: string
    }
}

export interface ApiToken {
    id: string,
    name: string,
    token? : string | null
}

export enum TenantUserStatus{
    Active = "active",
    Pending = "pending"
}

export interface TenantUser {
    id: string,
    name: string,
    profilePictureUrl: string,
    email: string,
    lastLogin: Date | null,
    status: TenantUserStatus
}

export const getSettings = async (): Promise<AppSettings> => {
    const response = await axiosInstance.get("/system/settings");
    return response.data;
};

export interface SystemContext {
    name: string,
    description?: string,
}

export const getSystemContexts = async (): Promise<SystemContext[]> => {
    const response = await axiosInstance.get("/system/contexts");
    return response.data;
};

export const createSystemContext = async (name: string, description: string): Promise<SystemContext> => {
    const response = await axiosInstance.post(
        `/system/contexts/${name}`,
        {name, description});
    return response.data;
};

export const deleteSystemContext = async (name: string): Promise<number> => {
    const response = await axiosInstance.delete(`/system/contexts/${name}`);
    return response.data;
};

export const getUsers = async (): Promise<TenantUser[]> => {
    const response = await axiosInstance.get("/auth/users");
    return response.data;
};

export const deleteUser = async (userId: string): Promise<void> => {
    const response = await axiosInstance.delete(`/auth/users/${userId}`);
    return response.data;
};

export const inviteUser = async (name: string, email: string): Promise<void> => {
    const response = await axiosInstance.post(`/auth/users/invite`, {name, email});
    return response.data;
};

export const getApiTokens = async (): Promise<ApiToken[]> => {
    const response = await axiosInstance.get("/system/api-tokens");
    return response.data;
};

export const deleteApiToken = async (tokenId: string): Promise<void> => {
    const response = await axiosInstance.delete(`/system/api-tokens/${tokenId}`);
    return response.data;
};

export const createApiToken = async (name: string): Promise<ApiToken> => {
    const response = await axiosInstance.put(`/system/api-tokens`, {name});
    return response.data;
};
import {Box} from "@mui/material";
import {createFieldFeature} from "actions/field-features-reducer";
import ButtonComponent from "components/shared/button/button.component";
import {useField} from "context/field-feature-context";
import React, {useMemo, useState} from "react";
import {DataAssetFullResponse} from "services/data-assets";
import theme from "theme";
import {getDataAssetName} from "../../data-asset-name";
import {FieldType, FirstLastFieldType} from "../../feature-types";
import AddFeaturesFilter from "./add-features-data-content/add-features-filter/add-features-filter";
import AddFeatureFirstLastFields
    from "./add-features-data-content/add-features-first-last-configuration/add-features-first-last-modal/add-first-last-fields";
import {SelectedFieldType} from "./add-features-first-last-modal-content";
import { FieldOption } from "./add-features-data-content/add-features-first-last-configuration/add-features-first-last-modal/add-features-first-last-fields.component";

interface AddFeaturesModalContentProps {
    selectedAsset: DataAssetFullResponse;
    selectedAssetJoinName: string | undefined
    onFieldUpdated: (field: FieldType) => void;
    onClose: () => void;
}

const AddFeaturesFieldModalContent: React.FC<AddFeaturesModalContentProps> = ({
    selectedAsset,
    selectedAssetJoinName,
    onFieldUpdated,
    onClose
}) => {
    const [fieldDraft, setFieldDraft] = useState<FieldType>({
        dataAssetId: getDataAssetName(selectedAsset),
        dataAssetType: selectedAsset.type,
    });
    const {dispatchFieldAction} = useField();
    const [selectedFields, setSelectedFields] = useState<SelectedFieldType>({});

    const isCreateFieldEnabled = useMemo(() => Object.keys(selectedFields).length > 0, [selectedFields]);

    const handleFieldsSelected = (selectedFields: FieldOption[]) => {
        const updatedFields: SelectedFieldType = {};
        selectedFields.forEach(field=>{
            updatedFields[field.name] = { name: field.name, dataType: field.dataType };
        })
        setSelectedFields(updatedFields);
    };

    const deleteSelectedField = (fieldName: FirstLastFieldType["name"]) => {
        const updatedFields = { ...selectedFields };
        delete updatedFields[fieldName];
        setSelectedFields(updatedFields);
    };

    const updateFieldAlias = (fieldName: FirstLastFieldType["name"], alias: FirstLastFieldType["alias"]) => {
        const updatedFields = JSON.parse(JSON.stringify(selectedFields));
        updatedFields[fieldName].alias = alias;
        setSelectedFields(updatedFields);
    };

    const updateFieldTimeField = (fieldName: FirstLastFieldType["name"], timeField?: string) => {
        const updatedFields = JSON.parse(JSON.stringify(selectedFields));
        updatedFields[fieldName].timeField = timeField;
        setSelectedFields(updatedFields);
    };

    const handleFieldUpdated = (field: FieldType) => {
        setFieldDraft(field);
        onFieldUpdated(field);
    };

    const handleFiltersUpdated = (filters: FieldType["filters"]) => {
        const newField: FieldType = {
            ...fieldDraft as FieldType,
            filters: filters
        };
        handleFieldUpdated(newField);
    };

    const saveField = () => {
        if (fieldDraft) {
            const fieldToSave = {
                ...fieldDraft,
                dataAssetId: getDataAssetName(selectedAsset),
                dataAssetType: selectedAsset.type,
                fields: Object.values(selectedFields),
                joinName: selectedAssetJoinName
            };
            dispatchFieldAction(createFieldFeature(fieldToSave));
        }
        onClose();
    };

    return (
        <Box sx={{height: "100%"}}>
            <Box sx={{margin: "32px 0", height: "100%"}}>
                <AddFeaturesFilter
                    assetColumns={selectedAsset["columns"]}
                    selectedFilters={fieldDraft?.filters}
                    onFiltersUpdated={handleFiltersUpdated}
                />
                <AddFeatureFirstLastFields
                    selectedAsset={selectedAsset}
                    selectedFields={selectedFields}
                    onFieldsSelected={handleFieldsSelected}
                    onAliasUpdated={updateFieldAlias}
                    onSelectedFieldDeleted={deleteSelectedField}
                    onTimeFieldUpdated={updateFieldTimeField}
                />

            </Box>
            <Box
                className="flex-box-end"
                position="sticky"
                bottom="0px"
                sx={{background: theme.palette.customColor.white}}
            >
                <ButtonComponent variant="text" label="Cancel" onClick={onClose}/>
                <ButtonComponent
                    disabled={!isCreateFieldEnabled}
                    variant="contained"
                    label="Create Field"
                    onClick={saveField}
                />
            </Box>
        </Box>
    );
};

export default AddFeaturesFieldModalContent;

import {useAuthUser} from "@frontegg/react";
import { Box } from "@mui/material";
import LoadingSpinner from "components/shared/LoadingSpinner";
import {useGlobalContext} from "context/global-context";
import { usePageTitle } from "hooks/title";
import React, {Ref, useEffect, useImperativeHandle, useState} from "react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {getGitBranches, getSnowflakeSchemas} from "services/integrations";
import { ApiError } from "types/errors";

export enum PageState{
    init = 0,
    idle = 1,
    loading = 2,
    error = 3
}

export enum TitleUpdateStrategy{
    OnInit = 0,
    EveryLoad = 1
}

export type PageTitleProp = string | (()=>string) | (()=>(Promise<string>))

interface PageComponentProps {
    dataFn: ()=>Promise<any>;
    title: PageTitleProp;
    titleStrategy:  TitleUpdateStrategy
    onStateChanged?: (state: PageState)=> void
    children?: React.ReactNode;
}

export interface PageComponentRef {
    reload: ()=>void
}

const AppPage = React.forwardRef(
    ({dataFn, title: pageTitleProp, onStateChanged, children, titleStrategy = TitleUpdateStrategy.EveryLoad}: PageComponentProps, ref: Ref<any>) => {
    const [loading, setLoading] = useState<boolean>(true);
    const {setTitle} = usePageTitle();
    const [error, setError] = useState<any>(null);
    const [state, setState] = useState<PageState>(PageState.init);
    
    useImperativeHandle(ref, () => ({
        reload() {
          loadData()
        }
    } as PageComponentRef))

    const setPageTitle = async () => {
        let title: string = ''
        if (typeof pageTitleProp == "function"){
            const fnResult = pageTitleProp()
            if (fnResult instanceof Promise)
                title = await fnResult
            else
                title = fnResult
        } else {
            title = pageTitleProp
        }
        setTitle(title)
    }

    const loadData = async ()=>{
        const isInit = state === PageState.init
        setLoading(true)
        setError(null)
        setState(PageState.loading)
        try{
            await dataFn()
            setState(PageState.idle)
            if (titleStrategy === TitleUpdateStrategy.EveryLoad || isInit)
                setPageTitle()
        } catch (err){
            setError(err)
            setState(PageState.error)
        } finally {
            setLoading(false)
        }
    }

    const renderError = ()=>{
        if (error instanceof ApiError){
            return error.render()
        }
        return (<Box>An error has occurred.</Box>)
    }

    useEffect(() => {
        loadData()
    }, []);

    useEffect(() => {
        if (onStateChanged) onStateChanged(state)
    }, [state]);

    return loading ? <Box><LoadingSpinner open={true}/></Box> : 
        error ? renderError() : 
        <>{children}</>;
});
export default AppPage;
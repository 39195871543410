import React, { useEffect } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { DataVirtual } from "assets/icons";
import { getDataAssetTitle } from "../../data-asset-name";
import theme from "theme";
import "./add-features-modal-data.scss";
import { useGlobalContext } from "context/global-context";
import {EntityDetailResponse, getEntity as getEntityApi} from "../../../../services/entities";

import pluralize from "pluralize";
import { EntityRelation } from "constants/joins";
import AssetTitle from "components/shared/assets/asset-title/asset-title.component";

export enum RelatedAssetsListEntitiesRelationFilter{
    AnyToMany="AnyToMany",
    AnyToOne="AnyToOne"
}

interface RelatedAssetsListProps {
    entityId: string;
    filter: string;
    relatedEntitiesRelationFilter?: RelatedAssetsListEntitiesRelationFilter,
    onSelectAsset: (selectedOption: RelatedAssetsListRow) => void;
}

export interface RelatedAssetsListRow{
    key: string;
    relation:string;
    joinName?: string; 
    default: boolean;
}

const RelatedAssetsList: React.FC<RelatedAssetsListProps> = ({
    filter,
    entityId,
    relatedEntitiesRelationFilter,
    onSelectAsset,
}) => {
    const [relatedEntities, setRelatedEntities] = React.useState<RelatedAssetsListRow[]>([]);
    const [relatedAssets, setRelatedAssets] = React.useState<RelatedAssetsListRow[]>([]);
    const [entity, setEntity] = React.useState<EntityDetailResponse | null>(null);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);

    const {selectedGitBranch, isEditMode} = useGlobalContext();
    useEffect(()=>{
        getEntity()
    }, [entityId])

    useEffect(()=>{
        if (entity)
            setRelatedEntitiesAndAssets(entity, filter)
    }, [filter, entity])

    const getMatchingRelations = (relations: { [key: string]: EntityRelation }, filterText: string | null = null): string[] => {
        const results = []
        for (const key in relations){
            const rel = relations[key]
            if (relatedEntitiesRelationFilter && (
                (relatedEntitiesRelationFilter == RelatedAssetsListEntitiesRelationFilter.AnyToMany && rel.relationship.endsWith("one")) ||
                (relatedEntitiesRelationFilter == RelatedAssetsListEntitiesRelationFilter.AnyToOne && rel.relationship.endsWith("many"))
            ))
                continue
            
            if (!filterText || key.toLowerCase().includes(filterText))
                results.push(key)
        }
        return results
    }

    const setRelatedEntitiesAndAssets = (entity: EntityDetailResponse, filterText: string | null = null)=>{
        const matchingEntities = getMatchingRelations(entity.relatedEntities, filterText)
        const matchingRelations = getMatchingRelations(entity.relatedAssets, filterText)

        const relatedEntities: RelatedAssetsListRow[] = []
        matchingEntities.forEach(matchingEntityId => {
            const matchingEntity = entity.relatedEntities[matchingEntityId]
            matchingEntity.joins.forEach(join=>{
                relatedEntities.push({
                    key: matchingEntityId,
                    relation: matchingEntity.relationship,
                    joinName: join.name,
                    default: join.default
                })
            })
        })

        const relatedAssets: RelatedAssetsListRow[] = []
        matchingRelations.forEach(matchingAssetId => {
            const matchingEntity = entity.relatedAssets[matchingAssetId]
            matchingEntity.joins.forEach(join=>{
                relatedAssets.push({
                    key: matchingAssetId,
                    relation: matchingEntity.relationship,
                    joinName: join.name,
                    default: join.default
                })
            })
        })

        setRelatedEntities(relatedEntities)
        setRelatedAssets(relatedAssets)
    }

    const getEntity = async () => {
        setIsLoading(true)
        const data = await getEntityApi(entityId || "", isEditMode ? selectedGitBranch : null);
        setRelatedEntitiesAndAssets(data)
        setEntity(data)
        setIsLoading(false)
    };

    const getRelatedEntityText = (relatedEntity: string, relation:string) => {
        let currentEntityAmount = "One"
        let currentEntityName = entityId
        let relatedEntityAmount = "one"
        let relatedEntityName = relatedEntity
        if (relation.startsWith("many")){
            currentEntityAmount = "Many"
            currentEntityName = pluralize(entityId, 2)
        }

        if (relation.endsWith("many")){
            relatedEntityAmount = "many"
            relatedEntityName = pluralize(relatedEntity, 2)
        }
        return `${currentEntityAmount} ${currentEntityName} has ${relatedEntityAmount} ${relatedEntityName}`
    }

    const getRelatedTitle = (row: RelatedAssetsListRow, parseAsset: boolean = false) => {
        let title = row.key
        
        if (parseAsset){
            const [db, schema, tableName] = row.key.split(".");
            title = tableName
        }

        return (
            <Box className="flex-box-center">
                <Typography variant="subtitle2" sx={{ color: theme.palette.customColor.darkGrey, marginRight: "5px" }}>
                    {title}
                </Typography>
                <span style={{
                    fontFamily: "BlinkMacSystemFont Medium, BlinkMacSystemFont Semibold, BlinkMacSystemFont, -apple-system, sans-serif",
                    fontSize: "14px",
                    color: theme.palette.customColor.grey1
                }}>{row.joinName ? `via ${row.joinName}` : ''} {row.default ? "(Default)" : ""}</span>
            </Box>
        )
    }

    return (
        <Box
            sx={{ width: "100%", height: "100%", overflowY: "scroll" }}
        >
            {isLoading ? (
                <Box sx={{textAlign: "center"}}><CircularProgress
                    sx={{
                        color: theme.palette.customColor.grey,
                        margin: "auto", 
                    }}
                    size={50}
                /></Box>
            ) : (
                <>
                    {relatedEntities.length > 0 && (
                        <Box sx={{ marginBottom: "24px" }}>
                            <Typography
                                variant="h5"
                                sx={{
                                    color: theme.palette.customColor.grey,
                                    marginBottom: "10px",
                                }}
                            >
                                Related entities
                            </Typography>
                            {relatedEntities.map((relatedEntity, index) => (
                                <Box
                                    className="modal-data flex-box-align-center"
                                    key={index}
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => onSelectAsset(relatedEntity)}
                                >
                                    <DataVirtual />
                                    <Box className="flex-box-center-space-between" sx={{ width: "100%" }}>
                                        {getRelatedTitle(relatedEntity)}
                                        <Typography variant="subtitle2" sx={{ color: theme.palette.customColor.darkGrey, marginRight: "16px" }}>
                                            {getRelatedEntityText(relatedEntity.key, relatedEntity.relation)}
                                        </Typography>
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    )}

                    {relatedAssets.length > 0 && (
                        <Box>
                            <Typography
                                variant="h5"
                                sx={{
                                    color: theme.palette.customColor.grey,
                                    marginBottom: "10px",
                                }}
                            >
                                Related data assets
                            </Typography>
                            {relatedAssets.map((asset, index) => (
                                <Box
                                    className="modal-data flex-box-align-center"
                                    key={index}
                                    onClick={() => onSelectAsset(asset)}
                                    sx={{ cursor: "pointer" }}
                                >
                                    <span className="material-symbols-outlined modal-icon-data">
                                        database
                                    </span>
                                    <AssetTitle assetId={asset.key} textFn={()=>getRelatedTitle(asset, true)}/>
                                </Box>
                            ))}
                        </Box>
                    )}

                    {relatedEntities.length === 0 && relatedAssets.length === 0 && (
                        <Typography variant="subtitle2">
                            No data assets found
                        </Typography>
                    )}
                </>
            )}
        </Box>
    );
};

export default RelatedAssetsList;
import React, { useMemo } from "react";
import { Box, MenuItem } from "@mui/material";
import theme from "theme";
import { DataAssetFullResponse } from "services/data-assets";
import SelectComponent from "components/shared/select/select.component";
import { DataTypeIcons } from "types/data-types";

interface FeatureTimeFieldSelectProps {
    selectedAsset: DataAssetFullResponse;
    value?: string;
    onChange?: (timeField?: string) => void
}

const FeatureTimeFieldSelect: React.FC<FeatureTimeFieldSelectProps> = ({ selectedAsset, value, onChange }) => {
    const dateFields = useMemo(() => {
        if (!selectedAsset) return []
        return selectedAsset.columns.filter(c=>c.type=="datetime").map(c=>({
            id: c.name,
            value: c.name,
            title: c.name
        }))
    }, [selectedAsset]);

    return (
        <SelectComponent
            options={dateFields}
            displayEmpty={true}
            disabled={!Boolean(dateFields.length)}
            onChange={(e) => onChange && onChange(e.target.value)}
            value={value}
            renderValue={value => value ? (
                <Box className="flex-box-row-start" sx={{display: "flex"}}>
                    <span className="material-symbols-outlined" style={{marginRight: "8px", lineHeight:"normal"}}>
                        {DataTypeIcons["datetime"]}
                    </span>
                    {value}
                </Box>
            ) : (dateFields.length ? "Choose time field" : "No time fields on this asset")}
            renderOption={(option) => (
                <MenuItem key={option.id} value={option.title} disableRipple={true} sx={{padding: 0}}>
                    <Box className="flex-box-row-start" sx={{display: "flex"}}>
                        <span className="material-symbols-outlined" style={{marginRight: "8px", lineHeight:"normal"}}>
                            {DataTypeIcons["datetime"]}
                        </span>
                        {option.title}
                    </Box>
                </MenuItem>
            )}
            sx={{
                "&.MuiOutlinedInput-root": {
                    "& .MuiSelect-select": {
                        border: `1px solid ${theme.palette.customColor.lightGrey1}`,
                        borderRadius: "10px",
                        boxShadow: "0px 4px 13px 0px rgba(97, 97, 97, 0.02)",
                        padding: "6px 16px",
                        color: theme.palette.customColor.grey,
                    },
                },
            }}
        />
    );
};

export default FeatureTimeFieldSelect;
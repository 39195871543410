import React, {useEffect, useState} from "react";
import {Box, Container, Divider, Typography} from "@mui/material";
import BreadcrumbsComponent from "../shared/breadcrumbs/breadcrumbs.component";
import theme from "theme";
import DataAssetDescription from "./data-asset-description/data-asset-description.component";
import DataAssetTabs from "./data-asset-tabs/data-asset-tabs.component";
import {useGlobalContext} from "context/global-context";
import {addMeasuresToDataAsset, DataAssetFullResponse, getDataAssetById} from "services/data-assets";
import {useParams} from "react-router-dom";
import LoadingSpinner from "components/shared/LoadingSpinner";
import {InfoIcon} from "lucide-react";
import {getDataAssetTitle} from "../add-features-component/data-asset-name";
import {DataAssetTypes} from "constants/common_constants";
import ButtonComponent from "../shared/button/button.component";
import MeasuresCreateModal from "../shared/modal/measures-create-modal";
import AliasesComponent from "../shared/aliases/aliases.component";
import { MeasureType } from "types/features";
import {EditSymbols} from "../../assets/icons";
import DataAssetModal from "./data-asset-modal/data-asset-modal-view.component";
import { usePageTitle } from "hooks/title";
import { AxiosError } from "axios";
import { showNotification } from "services/notifications";

const DataAssetComponent: React.FC = () => {
    const {assetId} = useParams<{ assetId: string }>();
    const {isEditMode, selectedGitBranch, selectedContext} = useGlobalContext();
    const [dataAsset, setDataAsset] = useState<DataAssetFullResponse | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isCreateMeasureModalOpen, setIsCreateMeasureModalOpen] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const {setTitle} = usePageTitle();

    const fetchDataAsset = async (assetId: string) => {
        setErrorMessage(null);
        setIsLoading(true);
        try {
            const response = await getDataAssetById(assetId, isEditMode ? selectedGitBranch : null);
            setDataAsset(response);
            setTitle(response.id)
        } catch (error: any) {
            setErrorMessage(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const saveMeasure = async (measure:MeasureType) => {
        if (dataAsset?.id){
            try {
                setIsLoading(true);
                await addMeasuresToDataAsset(dataAsset.id, [measure], isEditMode ? selectedGitBranch : null, dataAsset.type);
                fetchDataAsset(dataAsset.id);
            } catch(err){
                let message = String(err)
                if(err instanceof AxiosError){
                    message = String(err.message)
                }
                showNotification({
                    level: "error",
                    title: "Failed to create measure!",
                    message: message,
                    persist: true
                })
            }
            finally {
                setIsLoading(false);
            }
        }
    }

    useEffect(() => {
        if (assetId) fetchDataAsset(assetId);
    }, [assetId, selectedContext, selectedGitBranch]);

    return (
        <Container sx={{height: "100%"}}>
            <Box className="flex-box-start" flexDirection="column">
                <Box>
                    <BreadcrumbsComponent linkTo="/data-assets" linkText="Data assets"
                                          secondText={dataAsset?.name || ""}/>
                    <Box margin="25px 0">
                        {errorMessage && (
                            <Box className="flex-box-row" margin="20px 0">
                                <InfoIcon/>
                                <Typography variant="subtitle2" paddingLeft="10px">
                                    We couldn't load data asset due to an error: {errorMessage}
                                </Typography>
                            </Box>
                        )}
                        {dataAsset && !errorMessage && (
                            <>
                                <Box className="flex-box-center-space-between">
                                    <Typography
                                        variant="h2"
                                        sx={{
                                            fontSize: "42px",
                                            color: theme.palette.customColor.dark,
                                            letterSpacing: "-0.84px",
                                            lineHeight: "46px",
                                        }}
                                    >
                                        {dataAsset.name}
                                    </Typography>

                                    {isEditMode && (
                                        <Box>
                                            {dataAsset.type == DataAssetTypes.asset && (
                                                <ButtonComponent variant="contained"
                                                                 label="Edit"
                                                                 startIcon={<EditSymbols/>}
                                                                 onClick={() => setShowEditModal(true)}/>)}
                                            <ButtonComponent
                                                onClick={() => setIsCreateMeasureModalOpen(true)}
                                                variant="containedGrey"
                                                label="+ Add Measure"
                                                sx={{
                                                    backgroundColor: theme.palette.customColor.lightestGrey5,
                                                    margin: "0 16px",
                                                }}
                                            />
                                        </Box>
                                    )}
                                </Box>

                                {dataAsset.description && <DataAssetDescription description={dataAsset.description}/>}


                                <Box className="flex-box-start" sx={{margin: "24px 0 40px 0"}}>
                                    {dataAsset.type === DataAssetTypes.asset && (<>
                                        <Box>
                                            <Typography variant="h5"
                                                        sx={{
                                                            color: theme.palette.customColor.dark,
                                                            marginBottom: "12px"
                                                        }}>
                                                db.schema
                                            </Typography>
                                            {getDataAssetTitle(dataAsset.id, true)}
                                        </Box>
                                        <Divider orientation="vertical" sx={{
                                            backgroundColor: theme.palette.customColor.lightGrey1,
                                            width: "1px",
                                            height: "58px",
                                            border: 0,
                                            margin: "0 40px"
                                        }}/>
                                    </>)
                                    }

                                    <AliasesComponent variant="dark" title="Key" labels={dataAsset.keys}/>
                                </Box>

                                <MeasuresCreateModal
                                    open={isCreateMeasureModalOpen}
                                    onClose={() => setIsCreateMeasureModalOpen(false)}
                                    handleOpenMeasuresStep={() => setIsCreateMeasureModalOpen(false)}
                                    selectedAsset={dataAsset}
                                    handleNewMeasureCreated={saveMeasure}
                                />

                                <DataAssetTabs asset={dataAsset}/>
                            </>
                        )}
                    </Box>
                </Box>
            </Box>

            {dataAsset && <DataAssetModal
                open={showEditModal}
                onClose={(shouldRefresh) => {setShowEditModal(false); if (shouldRefresh) fetchDataAsset(dataAsset.id)}}
                dataAsset={dataAsset}
            />}

            <LoadingSpinner open={isLoading}/>
        </Container>
    );
};
export default DataAssetComponent;
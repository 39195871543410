import {Box, Typography} from "@mui/material";
import {ArrowLeftSymbols} from "assets/icons";
import ButtonComponent from "components/shared/button/button.component";
import SqlView from "components/shared/sql-view/sql-view.component";
import {useGlobalContext} from "context/global-context";
import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {DataAssetFullResponse, getDataAssetById} from "services/data-assets";
import {MetricTemplatePreviewOptions, preview, TemplateType} from "services/templates";
import theme from "theme";
import {useDebouncedCallback} from "use-debounce";
import {DataAssetName} from "../../data-asset-name";
import {MetricType} from "../../feature-types";
import AddFeaturesFieldModalContent from "./add-features-field-modal-content";
import RelatedAssetsList, { RelatedAssetsListEntitiesRelationFilter, RelatedAssetsListRow } from "./related-assets-list";
import { showNotification } from "services/notifications";

interface AddFeaturesModalDataProps {
    selectedAsset: DataAssetFullResponse | null;
    selectedAssetJoinName: string | undefined
    searchValue: string;
    onAssetChanged: (change: {asset: DataAssetFullResponse | null, options?: RelatedAssetsListRow}) => void;
    handleCloseModal: () => void;
}

const AddFeaturesFieldModalData: React.FC<AddFeaturesModalDataProps> = ({
    selectedAsset,
    selectedAssetJoinName,
    searchValue,
    onAssetChanged,
    handleCloseModal,
}) => {
    const { entityId } = useParams<{ entityId: string }>();
    const { selectedGitBranch } = useGlobalContext();
    const [sql, setSql] = useState<string>("");

    const fetchDataAsset = async (selected: RelatedAssetsListRow) => {
        try {
            const response = await getDataAssetById(selected.key, selectedGitBranch);
            onAssetChanged({asset: response, options: selected});
        } catch (error: any) {
            showNotification({
                level:"error",
                title: "Could not get asset",
                message: error.message,
                persist: true
            })
        }
    };

    const handleBack = () => {
        onAssetChanged({asset: null});
    };

    const updateSql = async (metric: MetricType) => {
        const previewOptions: MetricTemplatePreviewOptions = {
            entity_id: entityId as string,
            asset_id: metric.dataAssetId,
            filters: (metric.filters || []).map(f => ({
                field: f.name,
                operator: f.operator,
                values: f.values,
                data_type: f.dataType,
            })),
            features: (metric.measures || []).map(measure => ({
                sql: measure.definition,
                name: measure.alias || measure.name,
            })),
        };

        const { sql: newSql } = await preview(
            selectedGitBranch,
            TemplateType.metric,
            previewOptions
        );
        setSql(newSql);
    };

    const debouncedUpdateSql = useDebouncedCallback(updateSql, 300);

    if (selectedAsset) {
        return (
            <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
                <Box sx={{ width: "450px", flexShrink: 0 }}>
                    <SqlView sql={sql} />
                </Box>
                <Box sx={{ flexGrow: 1, overflow: "auto", width: "100%" }}>
                    <>
                        <Typography variant="subtitle2" sx={{color: theme.palette.customColor.darkGrey}}>
                            Selected Data Asset
                        </Typography>
                        <DataAssetName assetId={selectedAsset.id} />
                    </>

                    <ButtonComponent
                        className="select-button-back"
                        variant="text"
                        label={<ArrowLeftSymbols />}
                        onClick={handleBack}
                    />

                    <AddFeaturesFieldModalContent
                        selectedAsset={selectedAsset}
                        selectedAssetJoinName={selectedAssetJoinName}
                        onFieldUpdated={debouncedUpdateSql}
                        onClose={handleCloseModal}
                    />
                </Box>
            </Box>
        );
    }

    return (
        <RelatedAssetsList
            entityId={entityId as string}
            filter={searchValue}
            relatedEntitiesRelationFilter={RelatedAssetsListEntitiesRelationFilter.AnyToOne}
            onSelectAsset={fetchDataAsset}
        />
    );
};

export default AddFeaturesFieldModalData;
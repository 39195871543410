import { DataAssetTypes } from "constants/common_constants";
import { DataType } from "types/data-types";
import {FeatureType, FieldFeatureType, MeasureType} from "types/features";

export type FeatureModelType = "field" | "metric" | "first/last" | "formula";

export enum FilterOperators {
    is = "is",
    is_not = "is_not",
    is_set = "is_set",
    is_not_set = "is_not_set",
    lt = "lt",
    gt = "gt",
    between = "between"
}

export interface FeatureBaseType {
    featureName: string;
    type: FeatureType;
}

export interface AssetFeatureDataApi extends FeatureBaseType{
    dataAssetId: string;
    dataAssetType: DataAssetTypes;
    feature: MetricType | FirstLastType | FieldType
}


export interface AssetFeatureData extends AssetFeatureDataApi {
    id: string;
}


export interface FeatureFilterType {
    name: string,
    dataType: string,
    operator: string,
    values?: any
}


export interface DerivedFeatureDataApi extends FeatureBaseType {
    feature: FormulaType
}

export interface DerivedFeatureData extends DerivedFeatureDataApi {
    id: string;
}

export interface AssetFeatureType{
    id?: string,
    dataAssetId: string,
    dataAssetType: DataAssetTypes,
    filters?: FeatureFilterType[],
    joinName?: string
}

export interface MetricType extends AssetFeatureType{
    measures?: MeasureType[]
}

export interface FieldType extends AssetFeatureType {
    fields?: FieldFeatureType[]
}

export interface FirstLastFieldType{
    name: string,
    alias?: string,
    selected?: boolean,
    dataType: DataType,
    timeField?: string
}

// stores First/Last feature user adds a new feature
export interface FirstLastType extends AssetFeatureType {
    method: "first" | "last",
    sortBy: string,
    fields: FirstLastFieldType[]
}

// stores First/Last feature draft when user creates a new one
export interface FirstLastDraftType {
    dataAssetId?: string,
    filters?: FeatureFilterType[],
    method: "first" | "last",
    sortBy: string,
    fields?: FirstLastFieldType[]
}

export interface FormulaFeature {
    feature: string,
    alias?: string
}

export interface FormulaType {
    id?: string,
    name: string,
    sql: string,
    dataType: DataType,
    features: FormulaFeature[]
}

// Formula Feature
export interface FormulaFeatureSelection {
    name: string;
    alias?: string;
    selected?: boolean;
    id: number;
    showIcon?: boolean;
    dataType: DataType
}
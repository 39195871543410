import { Box } from "@mui/material";
import WarningMessage from "components/shared/warning/warning-message.component";
import ErrorDisplay from "components/shared/warning/warning-message.component";
import theme from "theme";

export enum ApiErrorCodes{
    InvalidYml=491
}

export interface YmlValidationError{
    type: string;
    loc: string[]
    input: any;
    msg: string;
}

export abstract class ApiError extends Error{
    constructor(){
        super()
    }

    abstract render(): JSX.Element 
}

export class InvalidYmlApiError extends ApiError{
    constructor(public filePath: string, public data: string | YmlValidationError[]) {
        super();
    }

    render(): JSX.Element {
        return (<Box className="flex-box-col" sx={{gap: "30px", overflow: "auto", width:"100%"}}>
            <WarningMessage message={`Invalid YML '${this.filePath}':`}>
                <Box sx={{
                    background: theme.palette.customColor.lightGrey3,
                    borderRadius: "10px",
                    padding: "10px",
                    width: "90%",
                    border: `1px solid var(--Gray-300, ${theme.palette.customColor.lightGrey1})`
                }}><pre style={{overflow: "auto"}}>{JSON.stringify(this.data, null, 2)}</pre></Box>
            </WarningMessage>
        </Box>)
    }
}
import React, {useState} from "react";
import "./header.component.scss";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import {LogoHeaderSecond} from "assets/icons";
import HeaderSwitch from "./header-switch/header-switch.component";
import HeaderMenu from "./header-menu/header-menu.component";
import theme from "theme";
import {useGlobalContext} from "../../context/global-context";
import {ContextHolder} from "@frontegg/react";
import {Logout} from "@mui/icons-material";
import {useLoggedInUser} from "../../context/logged-in-user-context";
import { Link } from "react-router-dom";
import HeaderContext from "./header-context/header-context.component";

const Header: React.FC = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const {drawerOpen, setDrawerOpen} = useGlobalContext();
    const {user} = useLoggedInUser();
    const isMenuOpen = Boolean(anchorEl);
    
    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    const logout = () => {
        const baseUrl = ContextHolder.getContext().baseUrl;
        window.location.href = `${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location.origin}`;
    };

    const menuId = "primary-search-account-menu";
    const renderProfileMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
            sx={{
                top: "50px",
                "& .MuiMenu-paper": {
                    border: `1px solid ${theme.palette.customColor.lightGrey1}`,
                    borderRadius: "16px",
                    boxShadow: "unset",
                }
            }}
        >
            <MenuItem onClick={handleMenuClose} key="myAccount"
                      style={{color: theme.palette.customColor.dark, fontSize: "14px"}}
                      >
               <Link to="/admin/account" style={{textDecoration: "none", display:"flex", color: "inherit"}}>
                <span className="material-symbols-outlined" style={{marginRight: "10px"}}>settings</span>
                    My Account
               </Link>
            </MenuItem>


            <MenuItem key="logout"
                      style={{color: theme.palette.error.main, fontSize: "14px"}}
                      onClick={logout}>
                <Logout/>Logout
            </MenuItem>
        </Menu>
    );

    return (
        <Box sx={{display: "flex"}}>
            <AppBar
                sx={{
                    position: "relative",
                    borderRadius: "20px 0 0 0",
                    background: theme.palette.customColor.white,
                    boxShadow: "unset",
                    color: "inherit",
                    margin: 0,
                    paddingRight: "0 !important",
                }}
            >
                <Toolbar
                    sx={{
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderBottom: `1px solid ${theme.palette.customColor.lightGrey1}`,
                        margin: "0 50px",
                        padding: "23px 0 !important",
                    }}
                >
                    <Box display="flex" sx={{marginRight: "40px"}}>
                        <IconButton
                            color="inherit"
                            aria-label={drawerOpen ? "close drawer" : "open drawer"}
                            onClick={drawerOpen ? handleDrawerClose : handleDrawerOpen}
                            edge="start"
                            sx={{m: "0 30px 0 0", p: 0}}
                        >
                            <span className="material-symbols-outlined">menu</span>
                        </IconButton>
                        <Box className="flex-box-align-center">
                            <LogoHeaderSecond/>
                            <Typography variant="h6" sx={{margin: "8px 0 0 6px"}}>
                                STUDIO
                            </Typography>
                        </Box>
                    </Box>

                    <Box className="flex-box-end" sx={{padding: "10px", gap: "16px"}}>
                        <HeaderSwitch/>
                        <HeaderContext />
                    </Box>

                    <Box
                        className="flex-box-end"
                        alignItems="center"
                        width="100%"
                    >
                        {user && <Box sx={{marginLeft: "50px"}}>
                            <IconButton
                                disableRipple
                                size="large"
                                edge="end"
                                aria-label="account of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={handleProfileMenuOpen}
                                color="inherit"
                                sx={{
                                    "&:hover": {
                                        backgroundColor: "transparent",
                                    },
                                    display: "flex",
                                    alignItems: "center",
                                    textTransform: "none",
                                    marginRight: 0,
                                    padding: 0,
                                }}
                            >
                                {user.profilePictureUrl ? 
                                    <img src={user.profilePictureUrl} style={{width:"40px", borderRadius: "50%"}} ></img>
                                : 
                                    <AccountCircle sx={{width: "40px", height: "40px", margin: 0}}/>
                                }

                                <Typography variant="subtitle2"
                                            sx={{color: theme.palette.customColor.dark, margin: "0 8px 0 16px"}}>
                                    {user.name}
                                </Typography>

                                <span className="material-symbols-outlined">keyboard_arrow_down</span>
                            </IconButton>
                        </Box>
                        }

                    </Box>
                </Toolbar>
            </AppBar>
            <HeaderMenu
                drawerOpen={drawerOpen}
                handleDrawerClose={handleDrawerClose}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
            />
            {renderProfileMenu}
        </Box>
    );
};

export default Header;

import React from 'react';
import {Box, Typography} from '@mui/material';
import theme from 'theme';


import { operatorOptions } from 'constants/related-asset-operators';
import { EntityRelationJoin, JoinType, LookupJoin, RelatedFieldsJoin, RelatedFieldsJoinField, RelatedJoin, RelatedSqlJoin } from 'constants/joins';

export interface RelationDisplayProps {
    relation: EntityRelationJoin
}

const RelationDisplay: React.FC<RelationDisplayProps> = ({relation}) => {
    if (!relation) return <></>

    const getFieldsTypeDisplay = ()=>{
        return (relation as RelatedFieldsJoin).fields.map((r, i) => {
            const operatorLabel = operatorOptions.find(o=>o.value === r.operator)?.label
            return (
                <Box key={`${r.source}-${r.destination}`} sx={{ display: 'flex', alignItems: 'center'}}>
                    <Typography variant="h5" sx={{ display: 'flex' }}>
                        <span
                            className="material-symbols-outlined"
                            style={{
                                fontSize: '20px',
                                color: theme.palette.customColor.purple,
                                marginRight: '6px',
                            }}
                        >
                            package_2
                        </span>
                        {r.source}
                    </Typography>
                    <Typography variant="h5" sx={{ margin: "0 10px" }}>{operatorLabel}</Typography>
                    <Typography variant="h5" sx={{ display: 'flex' }}>
                        <span
                            className="material-symbols-outlined"
                            style={{
                                fontSize: '20px',
                                color: theme.palette.customColor.purple,
                                marginRight: '6px',
                            }}
                        >
                            database
                        </span>
                        {r.destination}
                    </Typography>
                </Box>
            )
        })
    }

    const getDisplay = ()=>{
        switch(relation.type){
            case JoinType.fields:
                return getFieldsTypeDisplay()
            case JoinType.sql:
                return (<Typography variant="h5">{(relation as RelatedSqlJoin).sql}</Typography>)
        }
        return <></>
    }

    return (
        <Box sx={{display: "flex", flexDirection: "column", gap: "12px"}}>
            {getDisplay()}
        </Box>
    );
};

export default RelationDisplay;

import React, { useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import theme from "theme";
import ButtonComponent from "../../../shared/button/button.component";
import { ApiToken, createApiToken } from "services/system";
import copy from "copy-to-clipboard";

interface NewApiTokenProps {
    onClose: (shouldRefresh?: boolean) => void;
}

const NewApiToken: React.FC<NewApiTokenProps> = ({ onClose }) => {
    const [tokenName, setTokenName] = useState("");
    const [createdToken, setCreatedToken] = useState<ApiToken | null>(null);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTokenName(event.target.value);
    };

    const handleCreateToken = async () => {
        const createdToken = await createApiToken(tokenName)
        setCreatedToken(createdToken)
    };

    return (
        <Box sx={{height: "100%"}}>
            <Typography
                variant="h4"
                sx={{
                    color: theme.palette.customColor.black,
                    marginBottom: "32px"
                }}
            >
                Create a new API token
            </Typography>

            <Box className="flex-box-col" sx={{height: "92%"}}>
                <Box sx={{ flexGrow: 1 }}>
                    <Typography
                        variant="h5"
                        sx={{
                            color: theme.palette.customColor.darkGrey,
                            marginBottom: "10px"
                        }}
                    >
                        Token name
                    </Typography>
                    <TextField
                        variant="outlined"
                        type="text"
                        placeholder="Enter connection name"
                        value={tokenName}
                        disabled={Boolean(createdToken)}
                        onChange={handleInputChange}
                        sx={{
                            width: "100%",
                            "& .MuiInputBase-root input": {
                                color: theme.palette.customColor.dark,
                            },
                            "& input:focus": {
                                border: `1px solid ${theme.palette.customColor.lightGrey1}`,
                            },
                            "& .MuiInputBase-root input::placeholder": {
                                color: theme.palette.customColor.grey,
                            },
                        }}
                    />
                </Box>
                <Box className="flex-box-col-end">
                    {createdToken ? (
                        <>
                            <Box
                                className="flex-box-align-center"
                                sx={{
                                    boxSizing: "border-box",
                                    borderRadius: `16px`,
                                    border: `1px solid ${theme.palette.customColor.lightGrey1}`,
                                    boxShadow: "0px 4px 13px 0px rgba(97, 97, 97, 0.02)",
                                    position: "relative",
                                    marginBottom: "10px",
                                    padding: "14px 37px",
                                    width: "100%"
                                }}
                            >
                                <Typography
                                    variant="h5"
                                    sx={{
                                        color: theme.palette.customColor.dark,
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        display: "-webkit-box",
                                        "-webkit-line-clamp": "4",
                                        "-webkit-box-orient": "vertical"
                                    }}
                                >
                                    {createdToken.token}
                                </Typography>

                                <ButtonComponent
                                    variant="text"
                                    label={<span className="material-symbols-outlined"
                                                style={{fontSize: "18px", color: theme.palette.customColor.purple}}>content_copy</span>}
                                    onClick={() => copy(createdToken.token  || "")}
                                    disableRipple
                                    sx={{minWidth: "auto", padding: "5px", position:"absolute", top:"5px",right:"3px"}}
                                />
                            </Box>
                            <ButtonComponent
                                onClick={()=>onClose(true)}
                                variant="contained"
                                label="Close"
                            />
                        </>
                    )
                    :
                    <ButtonComponent
                        onClick={handleCreateToken}
                        variant="contained"
                        label="Create API Token"
                        disabled={!tokenName.trim()}
                        />
                    }
                    <Typography
                        variant="h5"
                        sx={{
                            textAlign: "right",
                            color: theme.palette.customColor.slateGrey,
                            fontWeight: 400,
                            marginTop: "16px"
                        }}
                    >
                        After closing this pane you will not be able to see the token again.<br />
                        Please make sure you copy it before closing.
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default NewApiToken;
import LoadingSpinner from "components/shared/LoadingSpinner";
import React, {useEffect, useState} from 'react';
import {Box, FormHelperText} from "@mui/material";
import {DataGrid, GridToolbar, GridColDef} from '@mui/x-data-grid';
import './add-edit-business-entities-dim-table.component.scss';
import AddEditBusinessEntitiesDimTableColumnSelect
    from './add-edit-business-entities-dim-table-column-select/add-edit-business-entities-dim-table-column-select.component';
import AddEditBusinessEntitiesDimTableColumnSecond
    from './add-edit-business-entities-dim-table-column-second/add-edit-business-entities-dim-table-column-second.component';
import AddEditBusinessEntitiesDimTableInfo
    from './add-edit-business-entities-dim-table-info/add-edit-business-entities-dim-table-info.component';
import {DataAssetResponse, getDataAssets} from "services/data-assets";
import { useForm } from 'react-hook-form';
import { AddEditBusinessEntityStepProps } from '../add-edit-business-entities-step-props';

interface EntityData {
    id: number;
    dimTable?: string;
    columnDim?: string;
}

const rows: EntityData[] = [
    {id: 1, dimTable: '', columnDim: ''},
];

export interface AddEditBusinessEntitiesDimTableFormData{
    keyTable: string | null
}

interface EntityDimTableStepProps extends AddEditBusinessEntityStepProps<AddEditBusinessEntitiesDimTableFormData>{
    showDimTableInfo: boolean;
}

export const dimValuesFromEntity  = (entity: AddEditBusinessEntitiesDimTableFormData | null): AddEditBusinessEntitiesDimTableFormData => {
    return {
        keyTable: entity?.keyTable || null
    }
}

const AddEditBusinessEntitiesDimTable: React.FC<EntityDimTableStepProps> = ({
    showDimTableInfo,
    originalEntity,
    initialValue,
    formRef,
    handleFormValues
}) => {
    const [defaultValue, setDefaultValue] = useState<AddEditBusinessEntitiesDimTableFormData|null>(null);
    const [selectedValue, setSelectedValue] = useState<DataAssetResponse | null>(null);
    const {
        register,
        handleSubmit,
        setValue,
        formState: {errors},
    } = useForm({defaultValues: async () => {
        const initVal = initialValue ||  dimValuesFromEntity(originalEntity)
        if (initVal.keyTable){
            const assetsResponse = await getDataAssets({tableId: initVal.keyTable})
            setSelectedValue(assetsResponse.assets[0])
        }

        setDefaultValue(initVal)
        return initVal
    }});
    
    const { name, ref } = register('keyTable', {required: true}); 

    const handleValueChange = (event: any, newValue: DataAssetResponse | null) => {
        setValue("keyTable", newValue?.id || null)
        setSelectedValue(newValue);
    };

    const onSubmit = (data: any) => {
        const values = {
            keyTable: data.keyTable
        }
        handleFormValues(values);
    };
    const columns: GridColDef[] = [
        {
            field: 'dimTable',
            headerName: 'DIM Table',
            flex: 1,
            sortable: true,
            renderCell: () => (
                <AddEditBusinessEntitiesDimTableColumnSelect initialValue={defaultValue?.keyTable} onChange={handleValueChange} name={name} inputRef={ref}/>
            ),
        },
        {
            field: 'columnDim',
            headerName: 'Key',
            flex: 1,
            sortable: false,
            renderCell: () => <AddEditBusinessEntitiesDimTableColumnSecond selectedValue={selectedValue}/>,
        },
    ];

    return (
        !defaultValue ? <LoadingSpinner open={true}/> : (
            <Box component="form" ref={formRef} onSubmit={handleSubmit(onSubmit)} className="flex-box-col-center"
                 sx={{width: "100%"}}>
                
                {showDimTableInfo && (
                    <Box sx={{maxWidth: "620px"}}>
                        <AddEditBusinessEntitiesDimTableInfo/>
                    </Box>
                )}

                <Box className="dim-table-style" sx={{width:"100%", maxWidth: "750px", marginTop: "32px"}}>
                    <Box>
                        <DataGrid
                            hideFooter={true}
                            rows={rows}
                            columns={columns}
                            disableColumnFilter
                            disableColumnSelector
                            disableDensitySelector
                            disableAutosize={false}
                            className="entity-data-grid"
                            slots={{toolbar: GridToolbar}}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: false,
                                },
                            }}
                            autoHeight
                            initialState={{
                                sorting: {
                                    sortModel: [{field: "dimTable", sort: "asc"}],
                                },
                            }}
                            sx={{fontSize: "14px"}}
                        />
                        {errors["keyTable"] ? (<FormHelperText id="username-helper"
                                                               error={true}>DIM Table is required
                        </FormHelperText>) : null}
                    </Box>
                </Box>
            </Box>
        )
    );
};

export default AddEditBusinessEntitiesDimTable;

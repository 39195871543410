import "./related-join-table.component.scss";
import {Box, CircularProgress, Typography} from "@mui/material";
import {DataGrid, GridColDef, GridToolbar} from "@mui/x-data-grid";
import React, {useEffect, useState} from "react";
import {DataAssetColumn, DataAssetResponse} from "services/data-assets";
import theme from "theme";
import DataAssetDestinationSelector from "components/add-edit-business-entity-component/related-join/asset-destination-selector/asset-destination-selector.component";
import { DataAssetTypes } from "constants/common_constants";
import { DestinationDataType, EntityRelationJoin, JoinType, LookupJoin, RelatedConfigType, RelatedFieldsJoin, RelatedJoin, RelatedJoinData, RelatedLookupJoin } from "constants/joins";
import { EntityToAssetRelationsEntity } from "services/entities";
import RelationEditor, { RelationSide } from "../relation-editor.component";
import { useGlobalContext } from "context/global-context";
import { useCacheContext } from "context/cache-context";

const LOADER_SIZE = 30


interface RelatedJoinTableProps {
    entityColumns: DataAssetColumn[];
    join: RelatedJoin;
    onChange: (value: RelatedJoin | null) => void;
    entityName: string;
    destinationType: RelatedConfigType
}

interface JoinTableRow{
    id: number | string
    index: number
    source: DataAssetResponse | EntityToAssetRelationsEntity,
    sourceType: RelatedConfigType
    destinationType: RelatedConfigType
    destination: DestinationDataType
    relation: EntityRelationJoin
    fullAsset?: DataAssetResponse
}


const RelatedJoinTable: React.FC<RelatedJoinTableProps> = ({
    join,
    entityColumns,
    onChange,
    entityName,
    destinationType
}) => {

    const [rows, setRows] = useState<JoinTableRow[]>([]);
    const [loading, setIsLoading] = useState<boolean>(true);
    const [currentJoin, setCurrentJoin] = useState<RelatedJoin>(join);
    const {selectedGitBranch} = useGlobalContext();
    const { getAndCacheAssetById } = useCacheContext()
    const updateRows = async (updatedJoin: RelatedJoin) => {
        setIsLoading(true)
        
        const entitySource = {
            name: entityName,
            columns: entityColumns
        } as RelationSide

        switch (updatedJoin.type){
            case JoinType.lookup:
                const lookupJoin = updatedJoin as RelatedJoin<RelatedLookupJoin>
                const rows = await Promise.all(lookupJoin.lookup.map(async (lookup, index)=>{
                    let source: RelationSide;
                    let sourceType: string = "asset";
                    // First lookup source is the entity
                    if (index === 0){
                        sourceType = "entity";
                        source = entitySource
                    // Other lookups sources are assets
                    } else {
                        source = await fetchDataAsset(lookupJoin.lookup[index-1].destination)
                    }

                    return {
                        id: `${source.name}-${lookup.destination}`,
                        index: index,
                        source: source,
                        sourceType: sourceType,
                        destination: lookup.destinationData,
                        destinationType: destinationType,
                        relation: lookup.type === JoinType.fields ? {
                            type: lookup.type,
                            fields: lookup.fields
                        } as LookupJoin : {
                            type: lookup.sql,
                            sql: lookup.sql
                        } as LookupJoin,
                        fullAsset: await fetchDataAsset(lookup.destination)
                    } as JoinTableRow
                }))
                
                setRows(rows);
                break
            default:
                const defaultRow = [{
                    id: 0,
                    index: 0,
                    source: entitySource,
                    destinationType: destinationType,
                    destination: updatedJoin.destinationData,
                    relation: updatedJoin,
                    sourceType: "entity",
                    fullAsset: await fetchDataAsset(updatedJoin.destination)
                } as JoinTableRow]
                setRows(defaultRow)
        }
        setIsLoading(false)
    }

    useEffect(()=>{
        setCurrentJoin(join)
    },[join])

    useEffect(()=>{
        updateRows(join)
    },[currentJoin])

    const onAssetChanged = async (row: JoinTableRow, selectedAsset: DataAssetResponse | null) => {
        if (!selectedAsset) return
        const defaultFieldRelation = {source: "", destination: "", operator: "equal"} 
        // First row determines the entire join type
        if (row.index == 0) {
            const baseJoinProps: RelatedJoinData = {
                type: currentJoin.type,
                default: currentJoin.default,
                destination: currentJoin.destination,
                destinationData: currentJoin.destinationData,
                name: currentJoin.name
            }

            // Direct
            if (selectedAsset.id === join.destination){
                onChange({
                    ...baseJoinProps,
                    type: JoinType.fields,
                    fields: []
                } as RelatedJoin<RelatedFieldsJoin>)
            }
            // Lookup
            else {
                onChange({
                    ...baseJoinProps,
                    type: JoinType.lookup,
                    lookup: [{
                        type: JoinType.fields,
                        fields: [{...defaultFieldRelation}],
                        destination: selectedAsset.id,
                        destinationData: selectedAsset
                    }, {
                        type: JoinType.fields,
                        fields: [{...defaultFieldRelation}],
                        destination: baseJoinProps.destination,
                        destinationData: baseJoinProps.destinationData
                    }]
                } as RelatedJoin<RelatedLookupJoin>)
            }
        } else {
            const newJoin = {...currentJoin} as RelatedJoin<RelatedLookupJoin>
            newJoin.lookup.length = row.index + 1
            newJoin.lookup[row.index] = {
                ...newJoin.lookup[row.index],
                destination: selectedAsset.id,
                destinationData: selectedAsset
            }
            if(selectedAsset.id !== join.destination){
                newJoin.lookup.push({
                    type: JoinType.fields,
                    fields: [{...defaultFieldRelation}],
                    destination: newJoin.destination,
                    destinationData: newJoin.destinationData
                })
            }
            onChange(newJoin)
        }
    };

    const fetchDataAsset = async (assetId: string): Promise<DataAssetResponse> => {
        return getAndCacheAssetById(assetId, selectedGitBranch)
    };

    const onRelationshipChanged = async (row: JoinTableRow, newRelation: EntityRelationJoin) => {
        let newJoin: RelatedJoin | null = null
        if (currentJoin.type == JoinType.lookup){
            
            const newLookups = [...currentJoin.lookup]
            newLookups[row.index] = {
                ...newLookups[row.index],
                ...newRelation as LookupJoin
            }
            newJoin = {
                ...currentJoin,
                lookup: newLookups
            } as RelatedJoin<RelatedLookupJoin>
        } else {
            newJoin = newRelation as RelatedJoin
        }
        onChange(newJoin)
    };

    const columns: GridColDef[] = [
        {
            field: "source",
            headerName: "Source",
            flex: 1,
            sortable: false,
            renderCell: (params) => {
                return (
                    <Typography
                        variant="h5"
                        sx={{
                            lineHeight: "22px",
                        }}
                    >
                        {params.row.source.name}
                    </Typography>
                );
            },
        },
        {
            field: "destination",
            headerName: "Destination",
            flex: 1,
            sortable: false,
            renderCell: (params) => {
                return (
                    <DataAssetDestinationSelector
                        name={`table_pic_${params.row.id}`}
                        type={DataAssetTypes.asset}
                        placeholder="Select destination"
                        finalDestinationId={join.destination}
                        finalDestinationType={destinationType}
                        initialValue={params.row.destination?.id} 
                        onChange={(_e, newValue) => onAssetChanged(params.row, newValue)} />
                );
            },
        },
        {
            field: "entityAssetRelations",
            headerName: "Relation",
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <Box sx={{width:"100%"}}>
                    {!params.row.destination ?
                        <Typography
                            className="dim-table-column-sec"
                            variant="h5"
                            sx={{
                                color: params.row.asset ? theme.palette.customColor.dark : theme.palette.customColor.lavenderGrey,
                                lineHeight: "22px",
                            }}
                        >
                            Choose data asset first
                        </Typography>
                        : (!params.row.fullAsset && params.row.destination) ? (
                            <Box sx={{textAlign: "center"}}><CircularProgress size={LOADER_SIZE} color="inherit"/></Box>
                        ) : (
                            <RelationEditor
                                destination={params.row.fullAsset}
                                relation={params.row.relation}
                                onChange={(relation) => onRelationshipChanged(params.row, relation)} 
                                source={params.row.source}
                                sourceType={params.row.sourceType} 
                                destinationType={"asset"}                            
                            />
                        )
                    }
                </Box>),
        }
    ];

    return (
        <Box className="flex-box-col-center" sx={{width: "100%"}}>
            <Box className="dim-table-style" sx={{width: "100%", marginTop: "25px"}}>
                {loading ? (<Box className="flex-box-center"><CircularProgress color="inherit"/></Box>) : <DataGrid
                    className="entity-data-grid"
                    columns={columns}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    disableAutosize={true}
                    getRowHeight={() => "auto"}
                    hideFooter={true}
                    rows={rows}
                    sx={{
                        margin: "16px 0 0 0",
                        "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {py: "15px"},
                    }}
                    slots={{toolbar: GridToolbar}}
                />}
            </Box>
        </Box>
    );
};

export default RelatedJoinTable;

import React from "react";
import { Drawer, Box } from "@mui/material";
import ButtonComponent from "../shared/button/button.component";

interface AdminPanelProps {
    isOpen: boolean;
    onClose: () => void;
    children?: React.ReactNode;
}

const AdminPanelComponent: React.FC<AdminPanelProps> = ({ isOpen, onClose, children }) => {
    return (
        <Drawer
            open={isOpen}
            anchor="right"
            onClose={onClose}
            BackdropProps={{ invisible: true }}
            sx={{
                border: '1px solid theme.palette.customColor.lightGrey1',
                boxShadow: '0px 4px 20px 0px rgba(97, 97, 97, 0.13)',
            }}
        >
            <Box
                className="flex-box-col"
                sx={{
                    boxSizing: "border-box",
                    width: "525px",
                    height: "100%",
                    padding: "40px",
                }}
            >
                <Box className="flex-box-end" sx={{ marginBottom: "24px" }}>
                    <ButtonComponent
                        variant="text"
                        label={<span className="material-symbols-outlined" style={{ fontSize: "18px" }}>close</span>}
                        onClick={onClose}
                        disableRipple
                        sx={{ minWidth: "auto", padding: "6px" }}
                    />
                </Box>
                <Box sx={{flexGrow: 1}}>{children}</Box>
            </Box>
        </Drawer>
    );
};

export default AdminPanelComponent;
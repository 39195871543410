import React from 'react';
import {Box} from '@mui/material';
import theme from 'theme';
import Typography from '@mui/material/Typography';

const AddEditBusinessEntitiesDimTableInfo = () => {
    return (
        <Box className="flex-box-align-center info-dim-table" sx={{marginTop: '24px'}}>
            <span className="material-symbols-outlined">info</span>
            <Typography
                variant="h5"
                sx={{
                    color: theme.palette.customColor.slateGrey,
                    fontWeight: 400,
                    lineHeight: '22px',
                    marginLeft: '16px',
                }}
            >
                For example, if the entity is “User” - The “Entity key asset” is a table <br/>
                that contains all the user ids and each user id appears only once.
            </Typography>
        </Box>
    );
};

export default AddEditBusinessEntitiesDimTableInfo;

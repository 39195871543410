import "./business-entity-data-asset-relation.component.scss";
import {
    Autocomplete,
    Box,
    Popover,
    TextField,
    Typography,
} from "@mui/material";
import { ArrowDownSymbols } from "assets/icons";
import ButtonComponent from "components/shared/button/button.component";
import CustomSwitch from "components/shared/switch/switch.component";
import React, { useEffect, useState } from "react";
import theme from "theme";
import DataAssetRelationOperatorsComponent from "./business-entity-data-asset-relation-operators/business-entity-data-asset-relation-operators.component";
import { EntityRelationJoin, JoinType, LookupJoin, RelatedConfigType, RelatedFieldsJoin, RelatedFieldsJoinField, RelatedJoin, RelatedSqlJoin } from "constants/joins";
import { DataAssetColumn } from "services/data-assets";



interface DataAssetRelationComponentProps {
    anchorEl: HTMLElement | null;
    destinationFields: DataAssetColumn[];
    sourceFields: DataAssetColumn[];
    sourceType: RelatedConfigType
    destinationType: RelatedConfigType
    relation: EntityRelationJoin,
    onChange:(relation: EntityRelationJoin) => void
    onClose: () => void;
    sourceName: string;
    destinationName: string;
}

type FieldsJoin = RelatedJoin<RelatedFieldsJoin> | LookupJoin<RelatedFieldsJoin>
type SqlJoin = RelatedJoin<RelatedSqlJoin> | LookupJoin<RelatedSqlJoin>

const DataAssetRelationComponent: React.FC<DataAssetRelationComponentProps> = ({
    anchorEl,
    destinationFields,
    sourceFields,
    sourceType,
    relation,
    onChange,
    onClose,
    sourceName,
    destinationName,
    destinationType
}) => {
    const defaultRelation: RelatedFieldsJoinField = { source: "", destination: "", operator: "equal" };
    const [currentRelation, setCurrentRelation] = useState<EntityRelationJoin>(relation);
    const [isSaveEnabled, setIsSaveEnabled] = useState<boolean>(false);
    
    const handleRelationFieldChange = (index: number, type: "source" | "destination", newValue: string) => {
        const fieldsRelation = {...currentRelation} as FieldsJoin;
        
        if (type === "source")
            fieldsRelation.fields[index].source = newValue
        else
            fieldsRelation.fields[index].destination = newValue

        setCurrentRelation(fieldsRelation)
    };

    const handleOperatorChange = (index: number, newOperator: string) => {
        const fieldsRelation = {...currentRelation} as FieldsJoin;
        fieldsRelation.fields[index].operator = newOperator
        setCurrentRelation(fieldsRelation)
    };

    const handleAddCondition = () => {
        const fieldsRelation = currentRelation as FieldsJoin;
        setCurrentRelation({
            ...fieldsRelation,
            fields: [
                ...fieldsRelation.fields,
                {...defaultRelation}
            ]
        } as FieldsJoin)
    };

    const handleRemoveCondition = (index: number) => {
        const fieldsRelation = {...currentRelation} as FieldsJoin;
        fieldsRelation.fields.splice(index, 1)
        setCurrentRelation(fieldsRelation)
    };

    const saveRelations = () => {
        onChange(currentRelation)
    };

    const isFieldsValid = (relation:RelatedFieldsJoin): boolean => {
        return Boolean(
            (relation.fields.length) &&
            (!relation.fields.some(f=> !f.destination || !f.source))
        )
    }

    const updateIsValid = () => {
        setIsSaveEnabled(Boolean(
            (currentRelation?.type == JoinType.sql && (currentRelation as SqlJoin).sql) ||
            (currentRelation?.type == JoinType.fields && isFieldsValid(currentRelation as FieldsJoin))
        ))
    }

    useEffect(() => {
        setCurrentRelation(relation)
    }, [relation]);

    useEffect(() => {
        updateIsValid()
    }, [currentRelation]);

    const getIcon = (type: RelatedConfigType) => 
        (type==="asset" ? "database" : "package_2")

    return (
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            slotProps={{
                paper: {
                    sx: {
                        padding: "16px",
                        border: `1px solid ${theme.palette.customColor.lightGrey1}`,
                        borderRadius: "16px",
                        boxShadow: "0px 4px 13px 0px rgba(97, 97, 97, 0.02)",
                        width: "500px",
                    },
                },
            }}
        >
            <Box sx={{ mb: 2 }}>
                <Typography sx={{ fontSize: "14px", color: "text.secondary" }}>
                    Define the relationship between {sourceName} and {destinationName}
                </Typography>
            </Box>
            {currentRelation?.type === JoinType.fields ? (
                <>
                    <Box className="flex-box-row" sx={{ mb: 2 }}>
                        <Typography variant="subtitle2" sx={{ display: "flex", width: "50%" }}>
                            <span className="material-symbols-outlined" style={{ fontSize: "24px", color: theme.palette.customColor.purple, marginRight: "6px" }}>
                                {getIcon(sourceType)}
                            </span>
                            {sourceName}
                        </Typography>
                        <Typography variant="subtitle2" sx={{ display: "flex", width: "50%" }}>
                            <span className="material-symbols-outlined" style={{ fontSize: "24px", color: theme.palette.customColor.purple, marginRight: "6px" }}>
                                {getIcon(destinationType)}
                            </span>
                            {destinationName}
                        </Typography>
                    </Box>
                    {currentRelation.fields.map((relationField, index) => (
                        <Box key={index} sx={{ display: "flex", alignItems: "center", mb: 1, fontSize: "16px" }}>
                            <Autocomplete
                                value={relationField.source}
                                popupIcon={<ArrowDownSymbols />}
                                onChange={(e, newValue) => handleRelationFieldChange(index, "source", newValue || "")}
                                options={sourceFields.map(s=>s.name)}
                                autoHighlight
                                renderInput={(params) => <TextField {...params} placeholder={`Choose ${sourceName} field`} />}
                                ListboxProps={{ sx: { maxHeight: "344px" } }}
                                fullWidth
                            />

                            <DataAssetRelationOperatorsComponent
                                operator={relationField.operator}
                                setOperator={(newOperator) => handleOperatorChange(index, newOperator)}
                            />

                            <Autocomplete
                                value={relationField.destination}
                                popupIcon={<ArrowDownSymbols />}
                                onChange={(event, newValue) => handleRelationFieldChange(index, "destination", newValue || "")}
                                options={destinationFields.map(c=>c.name)}
                                autoHighlight
                                renderInput={(params) => <TextField {...params} placeholder={`Choose ${destinationName} field`} />}
                                ListboxProps={{ sx: { maxHeight: "344px" } }}
                                sx={{ marginRight: "10px" }}
                                fullWidth
                            />
                            {currentRelation.fields.length > 1 ? (
                                <span role="button" className="material-symbols-outlined" 
                                    style={{ cursor:"pointer", color: theme.palette.customColor.grey }} 
                                    onClick={() => handleRemoveCondition(index)}>
                                delete
                            </span>) : <></>}
                        </Box>
                    ))}
                    <ButtonComponent
                        variant="textPurple"
                        sx={{ color: "primary.main", textTransform: "none", marginLeft: "-15px" }}
                        onClick={handleAddCondition}
                        label="+ Add condition"
                    />
                </>
            ) : (
                <TextField
                    sx={{ mb: 2, width:"100%" }}
                    placeholder="Your SQL code..."
                    minRows={3}
                    multiline
                    value={(currentRelation as SqlJoin).sql}
                    className="relation-popover-input"
                    onChange={(event) => setCurrentRelation({...currentRelation, sql:event.target.value} as SqlJoin)}
                />
            )}
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CustomSwitch 
                        checked={currentRelation?.type === JoinType.sql} 
                        onChange={(event) => setCurrentRelation({...currentRelation, type: event.target.checked ? JoinType.sql : JoinType.fields} as EntityRelationJoin)} 
                    />
                    <Typography variant="subtitle2" sx={{ ml: 1 }}>SQL</Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                    <ButtonComponent
                        variant="text"
                        label="Cancel"
                        onClick={onClose}
                        sx={{ color: "text.secondary", mr: 2, textTransform: "none" }}
                    />
                    <ButtonComponent
                        variant="contained"
                        sx={{
                            color: "white",
                            backgroundColor: "primary.main",
                            borderRadius: "8px",
                            textTransform: "none",
                            "&:hover": { backgroundColor: "primary.dark" },
                        }}
                        onClick={saveRelations}
                        disabled={!isSaveEnabled}
                        label="Apply"
                    />
                </Box>
            </Box>
        </Popover>
    );
};

export default DataAssetRelationComponent;
import React, { useEffect, useState } from 'react';
import {Box, Typography} from '@mui/material';
import theme from 'theme';


import { operatorOptions } from 'constants/related-asset-operators';
import { EntityRelationJoin, JoinType, LookupJoin, RelatedConfigType, RelatedFieldsJoin, RelatedFieldsJoinField, RelatedJoin, RelatedSqlJoin } from 'constants/joins';
import ButtonComponent from 'components/shared/button/button.component';
import DataAssetRelationComponent from '../add-edit-business-entities-related-datasets/business-entity-data-asset-relation.component';
import { DataAssetColumn, DataAssetResponse } from 'services/data-assets';
import { EntityToAssetRelationsEntity } from 'services/entities';
import RelationDisplay from './relation-display.component';

export type RelationSide = DataAssetResponse | {name: string, columns: DataAssetColumn[]}

export interface RelationEditorProps {
    relation: EntityRelationJoin
    onChange?: (relation: EntityRelationJoin)=>void
    source: RelationSide;
    sourceType: RelatedConfigType
    destination: RelationSide;
    destinationType: RelatedConfigType
}

const RelationEditor: React.FC<RelationEditorProps> = ({
    relation, 
    onChange,
    sourceType,
    source,
    destination,
    destinationType
}) => {
    const [isValid, setIsValid] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [destinationFields, setDestinationFields] = useState<DataAssetColumn[]>([]);

    useEffect(() => {
        setDestinationFields(destination.columns);
    }, [destination]);

    const isFieldsValid = (relation:RelatedFieldsJoin): boolean => {
        return Boolean(
            (relation.fields.length) &&
            (!relation.fields.some(f=> !f.destination || !f.source))
        )
    }

    useEffect(() => {
        const valid = Boolean((relation as RelatedSqlJoin).sql || isFieldsValid(relation as RelatedFieldsJoin))
        setIsValid(valid);
    }, [relation]);

    const close = ()=>{
        setAnchorEl(null)
    }

    const onRelationChange = (newRelation: EntityRelationJoin) => {
        if (onChange) onChange(newRelation);
        close()
    }
    
    
    return(<Box>
        {!isValid ? (
            <ButtonComponent
                onClick={(e)=>setAnchorEl(e.currentTarget)}
                variant="textPurple"
                label="+ Add relation"
                sx={{fontSize: "14px", width: "100%", padding: 0}}
            />
        ) : (
            <Box 
                onClick={(e)=>setAnchorEl(e.currentTarget)}
                sx={{
                    width: "100%",
                    padding: "6px 12px",
                    borderRadius: "10px",
                    border: `1px solid var(--Gray-300, ${theme.palette.customColor.lightGrey1})`,
                    background: "#FFF",
                    cursor: "pointer"
                }}
            >
                <RelationDisplay relation={relation}/>
            </Box>
        )}
        <DataAssetRelationComponent
            anchorEl={anchorEl}
            onChange={onRelationChange}
            onClose={close}
            sourceFields={source.columns}
            destinationFields={destination.columns}
            sourceName={source.name}
            destinationName={destination.name}
            relation={relation} 
            sourceType={sourceType} 
            destinationType={destinationType}
        />
    </Box>)
};

export default RelationEditor;

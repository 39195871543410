import React from "react";
import { Box, Divider, Typography, TextField } from "@mui/material";
import theme from "theme";
import CustomSwitch from "components/shared/switch/switch.component";
import RelatedJoinTable from "./related-join-table/related-join-table.component";
import { RelatedConfigType, RelatedJoin } from "constants/joins";
import { DataAssetColumn } from "services/data-assets";

interface JoinSectionProps {
    join: RelatedJoin;
    initialValues: any;
    onChange: (data: RelatedJoin | null) => void;
    entityColumns: DataAssetColumn[];
    entityName: string;
    destinationType: RelatedConfigType
}

const RelatedJoinComponent: React.FC<JoinSectionProps> = ({
    join,
    initialValues,
    onChange,
    entityColumns,
    entityName,
    destinationType,
}) => {
    return (
        <Box sx={{ width: "100%" }}>
            <Divider
                sx={{
                    height: "1px",
                    borderStyle: "none",
                    borderColor: "transparent",
                    borderRadius: "2px",
                    backgroundColor: theme.palette.customColor.lightGrey1,
                    marginBottom: "32px",
                }}
            />
            <Box className="flex-box-center-space-between">
                <Box>
                    <Typography
                        variant="h5"
                        sx={{
                            color: theme.palette.customColor.darkGrey,
                            marginBottom: "10px",
                        }}
                    >
                        Join name
                    </Typography>
                    <TextField
                        value={join.name || ""}
                        onChange={(event) =>
                            onChange({
                                ...join,
                                name: event.target.value 
                            })
                        }
                        placeholder="Enter join path name"
                        autoFocus
                        sx={{
                            width: "435px",
                            "& .MuiInputBase-input": {
                                "&::placeholder": {
                                    color: theme.palette.customColor.grey,
                                },
                                "&:focus": {
                                    borderColor: theme.palette.customColor.lightGrey1,
                                },
                            },
                        }}
                    />
                </Box>

                <Box className="flex-box-row">
                    {!join.default && (<span
                        className="material-symbols-outlined"
                        style={{ cursor: "pointer", marginRight: "20px"}}
                        onClick={() => onChange(null)}
                    >
                        delete
                    </span>)}
                    <Typography
                        variant="h5"
                        sx={{
                            color: theme.palette.customColor.slateGrey,
                            marginRight: "16px",
                        }}
                    >
                        Default
                    </Typography>
                    <CustomSwitch
                        disabled={join.default}
                        checked={join.default}
                        onChange={(e) => onChange({
                            ...join,
                            default: e.target.checked
                        })}
                    />
                </Box>
            </Box>

            <RelatedJoinTable
                join={join}
                onChange={(e)=>{
                    onChange(e)
                }}
                entityColumns={entityColumns}
                entityName={entityName}
                destinationType={destinationType}
            />
        </Box>
    );
};

export default RelatedJoinComponent;
import { EntityRelation, JoinType, RelatedConfig, RelatedConfigType, RelatedJoin, RelatedLookupJoin } from "constants/joins";
import { DataAssetFullResponse, DataAssetResponse, getDataAssetById } from "services/data-assets";

export type GetAssetFunc = (assetId: string, branch?: string | null)=>Promise<DataAssetFullResponse>

export const mapRelationToConfig = async (relationObj: { [key: string]: EntityRelation }, destinationType: RelatedConfigType, branch: string | null, getAssetFunc: GetAssetFunc = getDataAssetById) =>{
    const mappingCachedAssets : { [key:string]: Promise<DataAssetResponse> } = {}
    
    const fetchDataAsset = async (assetId: string) => {
        mappingCachedAssets[assetId] = mappingCachedAssets[assetId] || getAssetFunc(assetId, branch)
        return mappingCachedAssets[assetId]
    }
    
    return await Promise.all(Object.keys(relationObj).map(async (relId, index) => {
        return {
            id: `origin_${index}`,
            destination: relId,
            destinationType: destinationType,
            relationship: relationObj[relId].relationship,
            joins: await Promise.all(relationObj[relId].joins.map(async (join, index)=>{
                const newJoin = {
                    ...join,
                    destination: relId, 
                    destinationData: await fetchDataAsset(relId)
                } as RelatedJoin
                // If Direct 
                if (newJoin.type !== JoinType.lookup){
                    return newJoin
                // If Lookup
                } else {
                    const lookupJoin = newJoin as RelatedJoin<RelatedLookupJoin>
                    return {
                        ...lookupJoin,
                        lookup: await Promise.all(lookupJoin.lookup.map(async (lookup, index)=>{
                            return {
                                ...lookup,
                                destinationData: await fetchDataAsset(lookup.destination)
                            }
                        }))
                    } as RelatedJoin<RelatedLookupJoin>
                }
            })),
        } as RelatedConfig;
    }))
}
import React from 'react';
import {Box, Button, Typography} from '@mui/material';
import theme from 'theme';

interface EditModeBusinessEntitiesProps {
    activeStep: number;
    onStepClick: (stepIndex: number) => void
}

const textEditMode = [
    'Entity details',
    'Entity key asset',
    'Related data assets',
    'Related entities'
];

const EditModeBusinessEntities: React.FC<EditModeBusinessEntitiesProps> = ({activeStep, onStepClick}) => {

    return (
        <Box className="flex-box-center" sx={{margin: '20px -10px 0 -10px'}}>
            {textEditMode.map((text, index) => {
                const isActive = activeStep === index;
                return (
                    <Button
                        key={index}
                        onClick={()=> onStepClick(index)}
                        sx={{
                            color: isActive ? theme.palette.customColor.purple : theme.palette.customColor.slateGrey,
                            margin: '0 10px',
                            padding: '6px 10px',
                            borderRadius: isActive ? '10px' : 'none',
                            border: isActive ? `1px solid ${theme.palette.customColor.purple}` : 'none',
                            background: isActive ? `rgba(103, 73, 244, 0.10)` : 'none',
                            "&:hover": {
                                border: isActive ? `1px solid ${theme.palette.customColor.purple}` : 'none',
                                background: isActive ? `rgba(103, 73, 244, 0.10)` : 'none',
                            },
                        }}
                    >
                        {text}
                    </Button>
                );
            })}
        </Box>
    );
};

export default EditModeBusinessEntities;

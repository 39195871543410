import React, {useEffect, useState} from 'react';
import {Box, Divider, Typography} from "@mui/material";
import theme from "theme";
import ButtonComponent from "../../shared/button/button.component";
import AdminPanelComponent from "../admin-panel.component";
import NewApiToken from "./new-api-token/new-api-token";
import { ApiToken, deleteApiToken, getApiTokens } from 'services/system';
import ConfirmationModal from 'components/shared/modal/confirmation-modal';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import { usePageTitle } from 'hooks/title';

const ApiTokenComponent: React.FC = () => {
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [apiTokens, setApiTokens] = useState<ApiToken[]>([]);
    const [tokenToDelete, setTokenToDelete] = useState<string | null>(null);
    const {setTitle} = usePageTitle();

    const fetchTokens = async ()=>{
        setIsLoading(true)
        const tokens = await getApiTokens();
        setApiTokens(tokens);
        setIsLoading(false)
    }

    useEffect(()=>{
        setTitle('API Tokens - Admin')
        fetchTokens()
    },[])

    const handleOpenAdminPanel = () => {
        setDrawerOpen(true);
    };

    const onTokenCreateClosed = (shouldRefresh?: boolean) => {
        if (shouldRefresh)
            fetchTokens()
        setDrawerOpen(false);
    };

    const handleDeleteToken = (tokenId: string) => {
        setTokenToDelete(tokenId)
    };

    const deleteToken = async () => {
        await deleteApiToken(tokenToDelete as string)
        setTokenToDelete(null)
        fetchTokens()
    };

    const indentCustomS = "16px";
    const indentCustomM = "32px";

    return (
        <Box>
            <Box className="flex-box-center-space-between">
                <Typography
                    variant="h2"
                    sx={{
                        color: theme.palette.customColor.dark,
                        fontWeight: 600,
                    }}
                >
                    API Tokens
                </Typography>

                <ButtonComponent
                    onClick={handleOpenAdminPanel}
                    variant="contained"
                    label="Create a new API Token"
                    startIcon={<span className="material-symbols-outlined">key</span>}
                    sx={{
                        "& .MuiButton-startIcon": {
                            marginLeft: 0
                        }
                    }}
                />
            </Box>
            <Divider
                sx={{
                    height: "1px",
                    backgroundColor: theme.palette.customColor.lightGrey1,
                    borderColor: "unset",
                    borderBottomWidth: "inherit",
                    margin: `${indentCustomM} 0`,
                }}
            />

            { !isLoading && (
                <Box className="flex-box-col-start">
                    <Typography
                        variant="body2"
                        sx={{
                            color: theme.palette.customColor.black,
                            letterSpacing: "-0.2px",
                            marginBottom: "8px"
                        }}
                    >
                        Query tokens
                    </Typography>

                    <Typography
                        variant="subtitle2"
                        sx={{
                            color: theme.palette.customColor.grey,
                            fontWeight: 400,
                        }}
                    >
                        Query tokens are tenant-level tokens that allow you to query data via SQL API or Rest API.
                    </Typography>

                    <Box className="flex-box-col"
                        sx={{width: "100%", gap: `${indentCustomS}`, marginTop: `${indentCustomM}`}}>
                        {apiTokens.length > 0 ? (
                            apiTokens.map((apiToken, index) => (
                                <Box
                                    key={index}
                                    className="flex-box-center-space-between"
                                    sx={{
                                        width: "100%",
                                        boxSizing: "border-box",
                                        borderRadius: `${indentCustomS}`,
                                        border: `1px solid ${theme.palette.customColor.lightGrey1}`,
                                        boxShadow: "0px 4px 13px 0px rgba(97, 97, 97, 0.02)",
                                        padding: `24px ${indentCustomM}`,
                                    }}
                                >
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            color: theme.palette.customColor.slateGrey,
                                            fontWeight: 400,
                                        }}
                                    >
                                        {apiToken.name}
                                    </Typography>

                                    <Box className="flex-box-align-center">
                                        <ButtonComponent
                                            variant="text"
                                            label={<span className="material-symbols-outlined" style={{fontSize: "18px"}}>delete</span>}
                                            onClick={() => handleDeleteToken(apiToken.id)}
                                            disableRipple
                                            sx={{minWidth: "auto", padding: 0}}
                                        />
                                    </Box>
                                </Box>
                            ))
                        ) : (
                            <Box
                                className="flex-box-col-center"
                                sx={{
                                    boxSizing: "border-box",
                                    width: "100%",
                                    borderRadius: `${indentCustomS}`,
                                    backgroundColor: theme.palette.customColor.lightestGrey5,
                                    padding: `${indentCustomM} 24px`
                                }}
                            >
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: theme.palette.customColor.black,
                                        letterSpacing: "-0.2px",
                                        marginBottom: `${indentCustomS}`
                                    }}
                                >
                                    Generate an API Token for your projects.
                                </Typography>
                                <ButtonComponent
                                    onClick={handleOpenAdminPanel}
                                    variant="contained"
                                    label="Create a new API Token"
                                    startIcon={<span className="material-symbols-outlined">key</span>}
                                    sx={{
                                        "& .MuiButton-startIcon": {
                                            marginLeft: 0
                                        }
                                    }}
                                />
                            </Box>
                        )}
                    </Box>
                </Box>
            )}


            <ConfirmationModal
                open={Boolean(tokenToDelete)}
                onClose={()=>setTokenToDelete(null)}
                onConfirm={()=>deleteToken()}
                title={`Are you sure you want to remove this token?`}
                body={`Services using this token to communicate with Lynk services will no longer work.`}
                confirmText="Delete"
                cancelText="Cancel"
            />

            <AdminPanelComponent
                isOpen={isDrawerOpen}
                onClose={()=>setDrawerOpen(false)}
            >
                <NewApiToken onClose={onTokenCreateClosed}/>
            </AdminPanelComponent>
            <LoadingSpinner open={isLoading}/>
        </Box>
    );
};

export default ApiTokenComponent;
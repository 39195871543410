import React, {useEffect, useRef, useState} from "react";
import {Box, Container, Typography} from "@mui/material";
import theme from "theme";
import {useGlobalContext} from "context/global-context";
import {getEntities as getEntitiesApi, deleteEntity as deleteEntityApi} from "services/entities";
import {EntityResponse} from "types/index";
import {ArrowBack} from "../../assets/icons";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import {containsText} from "../../utils/contains-text";
import BusinessEntitiesList from "./business-entities-list/business-entities-list.component";
import BusinessEntitiesModalViewComponent from "./business-entities-modal/business-entities-modal-view.component";
import BusinessEntitiesEditWelcomeComponent from "./business-entities-welcome/business-entities-edit-welcome.component";
import BusinessEntitiesWelcomeComponent from "./business-entities-welcome/business-entities-welcome.component copy";
import AppPage, { PageComponentRef, TitleUpdateStrategy } from "page/page.component";

const BusinessEntitiesComponent: React.FC = () => {
    const {selectedGitBranch, isEditMode, selectedContext} = useGlobalContext();
    const [entities, setEntities] = useState<EntityResponse[]>([]);
    const pageRef = useRef<PageComponentRef>()
    const [editingEntityId, setEditingEntityId] = useState<string | null | undefined>(null);
    const [openModal, setOpenModal] = useState(false);
    const [searchValue, setSearchValue] = useState<string>("");
    const {entityId} = useParams<{ entityId: string }>();
    const navigate = useNavigate();

    const openAddEditEntityModal = (entityId?: string) => {
        setEditingEntityId(entityId || null);
        setOpenModal(true);
    };

    const handleCloseModal = async (shouldRefresh: boolean) => {
        setOpenModal(false);
        if (shouldRefresh) pageRef.current?.reload();
    };

    const deleteEntity = async (entityId: string) => {
        await deleteEntityApi(entityId, selectedGitBranch || "");
        pageRef.current?.reload()
        navigate("/entities");
    };

    const getEntities = async () => {
        const data : EntityResponse[] = await getEntitiesApi(isEditMode ? selectedGitBranch : null);
        const sortedData = data.sort((a, b) => a.name.localeCompare(b.name));
        setEntities(sortedData);
    };

    const filteredEntities = entities.filter((entity) =>
        containsText(entity.name, searchValue)
    );

    useEffect(() => {
        pageRef.current?.reload()
    }, [selectedGitBranch, isEditMode, selectedContext]);

    return (
        <AppPage ref={pageRef} dataFn={getEntities} title="Entities" titleStrategy={TitleUpdateStrategy.OnInit}>
            <Container sx={{marginTop:"-41px"}}>
                <Box className="flex-box-start" sx={{backgroundColor: theme.palette.customColor.white, height: "100%", width:"100%"}}>
                    
                    {entities.length ? ( <>
                            <BusinessEntitiesList
                                isEditMode={isEditMode}
                                openAddEditEntityModal={openAddEditEntityModal}
                                searchValue={searchValue}
                                setSearchValue={setSearchValue}
                                entities={filteredEntities}
                                activeEntityId={entityId}
                            />

                            <Box className="flex-box-col-center"
                                sx={{
                                    width: "calc(75% - 125px)",
                                    flexGrow:1,
                                    justifyContent: "flex-start",
                                    [theme.breakpoints.up("md")]: {
                                        width: "77%",
                                    },
                                }}
                            >
                                {entityId ? (
                                    <Outlet context={{
                                        onClickEdit: () => openAddEditEntityModal(entityId),
                                        onDeleteClick: deleteEntity
                                    }}/>
                                ) : (
                                    <Box className="flex-box-col-center" sx={{margin: "20% 0"}}>
                                        <ArrowBack/>
                                        <Typography variant="h3" sx={{textAlign: "center", color: theme.palette.customColor.lightGrey2}}>
                                            Select an entity <br/> on the left
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        </>
                        ) : (isEditMode ? (false ? (
                            <></>
                        ) : (<BusinessEntitiesEditWelcomeComponent onCreateEntity={openAddEditEntityModal} />)) : <BusinessEntitiesWelcomeComponent />)}
                    <Box className="flex-box-col-center" sx={{margin: "32px auto 0 auto"}}>
                        <BusinessEntitiesModalViewComponent
                            entityId={editingEntityId}
                            open={openModal}
                            onClose={handleCloseModal}
                        />
                    </Box>
                </Box>
            </Container>
        </AppPage>
    );
};

export default BusinessEntitiesComponent;
import React, {createContext, useContext, useState, ReactNode, useCallback} from "react";
import { DataAssetFullResponse, getDataAssetById } from "services/data-assets";

export interface CacheContextValue {
    getAndCacheAssetById: (assetId: string, branch?: string | null) => Promise<DataAssetFullResponse>
}

const CacheContext = createContext<CacheContextValue | undefined>(undefined);

const CacheContextProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    // Single Map to store cached promises
    const [assetsCache] = useState<Map<string, Promise<DataAssetFullResponse>>>(() => new Map());
    
    const getAndCacheAssetById = useCallback(async (assetId: string, branch?: string | null) => {
        // Return cached promise if it exists
        if (assetsCache.has(assetId)) {
          return assetsCache.get(assetId) as Promise<DataAssetFullResponse>;
        }
        
        // Create and cache new promise
        const promise = (async () => {
          try {
            return getDataAssetById(assetId, branch)
          } catch (error) {
            // Remove failed promises from cache
            assetsCache.delete(assetId);
            throw error;
          }
        })();
        
        assetsCache.set(assetId, promise);
        return promise;
      }, [assetsCache]);

    return (
        <CacheContext.Provider value={{
            getAndCacheAssetById
        }}>
            {children}
        </CacheContext.Provider>
    );
};

const useCacheContext = (): CacheContextValue => {
    const context = useContext(CacheContext);
    if (context === undefined) {
        throw new Error("useCacheContext must be used within a CacheContextProvider");
    }
    return context;
};

export {CacheContextProvider, useCacheContext};

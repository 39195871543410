import {Box} from "@mui/material";
import {createMetricFeature} from "actions/metric-features-reducer";
import ButtonComponent from "components/shared/button/button.component";
import {useMetric} from "context/metric-feature-context";
import React, {useState} from "react";
import {DataAssetFullResponse} from "services/data-assets";
import theme from "theme";
import {getDataAssetName} from "../../data-asset-name";
import {MetricType} from "../../feature-types";
import AddFeaturesFilter from "./add-features-data-content/add-features-filter/add-features-filter";
import AddFeaturesMeasures from "./add-features-data-content/add-features-measures/add-features-measures";

interface AddFeaturesModalContentProps {
    selectedAsset: DataAssetFullResponse;
    selectedAssetJoinName: string | undefined;
    onMetricUpdated: (metric: MetricType) => void;
    onClose: () => void;
}

const AddFeaturesMetricModalContent: React.FC<AddFeaturesModalContentProps> = ({
    selectedAsset,
    selectedAssetJoinName,
    onMetricUpdated,
    onClose
}) => {
    const [isCreateMetricEnabled, setCreateMetricEnabled] = useState(false);
    const [metricDraft, setMetricDraft] = useState<MetricType>();
    const {dispatchMetricAction} = useMetric();

    const handleMetricUpdated = (metric: MetricType) => {
        setCreateMetricEnabled(Boolean(metric.measures?.length));
        setMetricDraft(metric);
        onMetricUpdated(metric);
    };

    const handleFiltersUpdated = (filters: MetricType["filters"]) => {
        const newMetric: MetricType = {
            ...metricDraft as MetricType,
            dataAssetId: getDataAssetName(selectedAsset),
            joinName: selectedAssetJoinName,
            filters: filters
        };
        handleMetricUpdated(newMetric);
    };

    const handleMeasuresUpdated = (measures: MetricType["measures"]) => {
        const newMetric: MetricType = {
            dataAssetId: getDataAssetName(selectedAsset),
            joinName: selectedAssetJoinName,
            dataAssetType: selectedAsset.type,
            filters: metricDraft?.filters,
            measures: measures
        };
        handleMetricUpdated(newMetric);
    };

    const saveMetric = () => {
        if (metricDraft) dispatchMetricAction(createMetricFeature(metricDraft));
        onClose();
    };

    return (
        <Box sx={{height: "100%"}}>
            <Box sx={{margin: "32px 0", height: "100%"}}>
                <AddFeaturesFilter
                    assetColumns={selectedAsset["columns"]}
                    selectedFilters={metricDraft?.filters}
                    onFiltersUpdated={handleFiltersUpdated}
                />
                <AddFeaturesMeasures
                    selectedAsset={selectedAsset}
                    measures={metricDraft?.measures}
                    onMeasuresUpdated={handleMeasuresUpdated}
                />
            </Box>
            <Box
                className="flex-box-end"
                position="sticky"
                bottom="0px"
                sx={{background: theme.palette.customColor.white}}
            >
                <ButtonComponent variant="text" label="Cancel" onClick={onClose}/>
                <ButtonComponent
                    disabled={!isCreateMetricEnabled}
                    variant="contained"
                    label="Create metric"
                    onClick={saveMetric}
                />
            </Box>
        </Box>
    );
};

export default AddFeaturesMetricModalContent;

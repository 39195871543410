import React, {ChangeEvent, ReactNode, useEffect, useState} from "react";
import {
    Select,
    Typography,
    SelectProps as MuiSelectProps,
    MenuProps,
    ListSubheader,
    TextField,
    InputAdornment,
    CircularProgress,
    Box, Divider
} from "@mui/material";
import {ChevronDown} from "lucide-react";
import SearchIcon from "@mui/icons-material/Search";
import theme from "theme";
import {SelectChangeEvent} from "@mui/material/Select";
import {containsText} from "utils/contains-text";
import { RelatedConfigType } from "constants/joins";

interface SelectOption {
    title: string;
}

interface SelectRelatedComponentProps<T extends SelectOption> {
    options: T[];
    renderOption: (option: T) => React.ReactNode;
    placeholder?: string;
    menuProps?: MenuProps;
    value?: string;
    isLoading?: boolean;
    onChange: (event: SelectChangeEvent<string>) => void;
    paperProps?: React.CSSProperties;
    onSearchChange?: (searchText: string) => void;
    finalDestinationOption: T | null;
    finalDestinationType: RelatedConfigType
}

const SelectRelatedComponent = <T extends SelectOption>({
    options,
    renderOption,
    placeholder = "Select",
    menuProps,
    value,
    isLoading,
    onChange,
    paperProps,
    onSearchChange,
    finalDestinationOption,
    ...props
}: SelectRelatedComponentProps<T> & Omit<MuiSelectProps<string>, "value" | "onChange">) => {
    const [searchText, setSearchText] = useState("");
    const [filteredOptions, setFilteredOptions] = useState<T[]>(options);
    const [finalDestOpt, setFinalDestOpt] = useState<T | null>(null);
    
    useEffect(() => {
        setFilteredOptions(options.filter((option) => containsText(option.title, searchText)))
    }, [searchText, options])

    useEffect(() => {
        onSearchChange && onSearchChange(searchText);
    }, [searchText])

    useEffect(() => {
        setFinalDestOpt(finalDestinationOption)
    }, [finalDestinationOption])

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    const buildDropdown = ()=>{
        const dropdownElements: ReactNode[] = []
        
        if (finalDestOpt){
            dropdownElements.push(renderOption(finalDestOpt))
        }

        dropdownElements.push((
            <ListSubheader className="lookup-divider">
                <Box className="flex-box-center-space-between">
                    <Divider
                        sx={{
                            flexGrow: 1,
                            height: "1px",
                            borderStyle: "none",
                            borderColor: "transparent",
                            borderRadius: "2px",
                            backgroundColor: theme.palette.customColor.lightGrey1,
                        }}
                    />
        
                    <Typography
                        variant="body1"
                        sx={{
                            color: theme.palette.customColor.grey,
                            lineHeight: "20px",
                            padding: "0 10px",
                        }}
                    >
                        {finalDestOpt && "or"} via lookup
                    </Typography>
        
                    <Divider
                        sx={{
                            flexGrow: 1,
                            height: "1px",
                            borderStyle: "none",
                            borderColor: "transparent",
                            backgroundColor: theme.palette.customColor.lightGrey1,
                        }}
                    />
                </Box>
            </ListSubheader>
        ))

        dropdownElements.push(...filteredOptions.map(renderOption))

        return dropdownElements
    }

    return (
        <Select
            variant="outlined"
            fullWidth
            displayEmpty
            onClose={() => setSearchText("")}
            renderValue={(selected) =>
                selected ? selected : <Typography className="select-placeholder">{placeholder}</Typography>
            }
            IconComponent={ChevronDown}
            MenuProps={{
                autoFocus: false,
                PaperProps: {
                    sx: {
                        borderRadius: "16px",
                        border: `1px solid ${theme.palette.customColor.lightGrey1}`,
                        background: theme.palette.customColor.white,
                        boxShadow: "0 4px 13px 0 rgba(97, 97, 97, 0.02)",
                        marginTop: "11px",
                        maxHeight: "300px",
                        li: {
                            backgroundColor: "transparent !important",
                            color: theme.palette.customColor.darkGrey,
                            fontSize: "16px",
                            lineHeight: "24px",
                            padding: "10px 16px",
                            "&:hover": {
                                backgroundColor: "transparent",
                            },
                        },
                        ...paperProps,
                    },
                },
                ...menuProps
            }}
            value={value}
            onChange={onChange}
            {...props}
        >
            <ListSubheader>
                <TextField
                    className="search-input"
                    size="small"
                    placeholder="Search..."
                    fullWidth
                    autoFocus
                    value={searchText}
                    sx={{
                        border: 0,
                        borderRadius: "16px",
                        backgroundColor: theme.palette.customColor.lightestGrey5,
                        boxShadow: "0 4px 13px 0 rgba(97, 97, 97, 0.02)",
                        paddingLeft: 0,
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start" className="select-search-icon">
                                <SearchIcon/>
                            </InputAdornment>
                        ),
                    }}
                    onChange={handleSearchChange}
                    onKeyDown={(e) => {
                        if (e.key !== "Escape") {
                            e.stopPropagation();
                        }
                    }}
                />
            </ListSubheader>
        
            { isLoading ? (
                <Box sx={{textAlign: "center"}}><CircularProgress color="inherit"/></Box>
            ) : 
                buildDropdown()
            }
            
        </Select>
    );
};

export default SelectRelatedComponent;

import { DataAssetResponse } from "services/data-assets"
import { EntityResponse, EntityToAssetRelationsEntity } from "services/entities"

export enum JoinType{
    lookup="lookup",
    sql="sql",
    fields="fields"
}

export type EntityRelationJoin = RelatedJoin | LookupJoin | null

export type DestinationDataType = DataAssetResponse | EntityResponse | null

export interface JoinDestination {
    destination: string
    destinationData: DestinationDataType
}

export interface RelatedJoinData extends JoinDestination{
    type: JoinType
    default: boolean
    name?: string
}

export interface RelatedSqlJoin{
    type: JoinType.sql
    sql: string
}

export interface RelatedFieldsJoinField{
    source: string
    destination: string
    operator: string
}

export interface RelatedFieldsJoin{
    type: JoinType.fields
    fields: RelatedFieldsJoinField[]
}

export type LookupJoin<T=RelatedFieldsJoin | RelatedSqlJoin> = T & JoinDestination

export interface RelatedLookupJoin{
    type: JoinType.lookup
    lookup: LookupJoin[]
}

export type RelatedJoin<T = RelatedSqlJoin | RelatedFieldsJoin | RelatedLookupJoin> = T & RelatedJoinData

export enum RelationshipType{
    OneToOne = "one_to_one",
    OneToMany = "one_to_many",
    ManyToOne = "many_to_one",
    ManyToMany = "many_to_many",
}


export interface EntityRelation{
    relationship: RelationshipType
    joins: RelatedJoin[]
}

export type RelatedConfigType = "asset" | "entity"

export interface RelatedConfig extends EntityRelation{
    id: any
    destinationType: RelatedConfigType
    destination?: string
}
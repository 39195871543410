import axios from 'axios';
import {ContextHolder} from "@frontegg/react";
import {DEFAULT_CONTEXT} from "context/global-context";
import { ApiErrorCodes, InvalidYmlApiError } from 'types/errors';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken: string | null = ContextHolder.getAccessToken();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    // TODO: add selectedBranch to params, if isEditModeEnabled is true
    // Add context to query params by default
    const selectedContext = localStorage.getItem("selectedContext");
    const context = selectedContext !== null ? encodeURIComponent(selectedContext) : DEFAULT_CONTEXT;
    config.params = {...config.params, context: context};
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    // Any status code that lies within the range of 2xx causes this function to trigger
    return response;
  },
  (error) => {
    if (error.response.status === ApiErrorCodes.InvalidYml){
      const responseDetails = error.response.data.detail;
      return Promise.reject(new InvalidYmlApiError(responseDetails.file_path,responseDetails.data))
    }
    
    
    // assigning FastAPI's verbose message to AxiosError.message
    if (typeof error.response?.data?.detail === 'string') {
      error.message = error.response?.data?.detail;
    } else if (typeof error.response?.data === 'string') {
      error.message = error.response?.data;
    } else {
      error.message = `${error.response.status}: ${error.response.statusText}`;
    }
    return Promise.reject(error);
  }
);

type ErrorMessage = string;

export const handleRequestError = (error: any): ErrorMessage => {
  return axios.isAxiosError(error) ? error.message : "An unexpected error occurred";
};

export default axiosInstance;

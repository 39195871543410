import React, { useEffect, useMemo, useState } from "react";
import { debounce, MenuItem, SelectChangeEvent } from "@mui/material";
import { DataAssetResponse, getDataAssets } from "../../../../services/data-assets";
import SelectComponent from "../../select/select.component";
import AssetTitle from "../asset-title/asset-title.component";
import {ChevronDown} from "lucide-react";
import theme from "../../../../theme";
import "./asset-selector.component.scss";
import { DataAssetTypes } from "constants/common_constants";

interface SelectOption {
    id: string;
    title: string;
}

interface DataAssetSelectorProps {
    name?: string;
    inputRef?: React.RefCallback<any>;
    initialValue?: string | null;
    onChange?: (event: any, newValue: DataAssetResponse | null) => void;
    placeholder? : string;
    type?: DataAssetTypes;
}

const DataAssetSelector: React.FC<DataAssetSelectorProps> = ({ initialValue, onChange, placeholder, type }) => {
    const [selectedValue, setSelectedValue] = useState<string>(initialValue || "");
    const [options, setOptions] = useState<SelectOption[]>([]);
    const [dataAssetMap, setDataAssetMap] = useState<Map<string, DataAssetResponse>>(new Map());
    const [searchText, setSearchText] = useState<string>("");
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const fetchAssets = useMemo(
        () =>
            debounce(async (filter?: string) => {
                setIsLoading(true)
                const dataAssetsPage = await getDataAssets({ tableId: filter, type });

                const options = dataAssetsPage.assets.map((asset: DataAssetResponse) => ({
                    id: asset.id,
                    title: asset.name,
                }));

                const dataAssetMap = new Map(dataAssetsPage.assets.map(asset => [asset.id, asset]));

                setOptions(options);
                setDataAssetMap(dataAssetMap);
                setIsLoading(false)
            }, 400),
        []
    );

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
        fetchAssets(event.target.value)
    };

    const handleChange = (event: SelectChangeEvent<string>) => {
        const selected = dataAssetMap.get(event.target.value) || null;
        setIsDirty(true)
        setSelectedValue(event.target.value);
        if (onChange) onChange(event, selected);
    };

    const renderSelectedValue = () => {
        const selected = dataAssetMap.get(selectedValue);
        if (selected || (!isDirty && selectedValue))
            return <AssetTitle assetId={selectedValue} />
        return (placeholder || "Select asset");
    };

    const onSelectOpen = () =>{
        if (!options || !options.length)
            fetchAssets(searchText)
    }

    return (
        <SelectComponent
            className="asset-select"
            options={options}
            value={selectedValue}
            onChange={handleChange}
            onOpen={onSelectOpen}
            isLoading={isLoading}
            onSearchChange={handleSearchChange}
            renderOption={(option: SelectOption) => (
                <MenuItem className="asset-select-item" key={option.id} value={option.id}>
                    <AssetTitle assetId={option.id} />
                </MenuItem>
            )}
            renderValue={renderSelectedValue}
            placeholder={placeholder || "Select asset"}
            showSearch={true}
            IconComponent={(props) => <ChevronDown size={18} color="#1B1A1C" {...props} />}
            MenuProps={{
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center"
                },
                PaperProps: {
                    sx: {
                        borderRadius: "16px",
                        border: `1px solid ${theme.palette.customColor.lightGrey1}`,
                        background: theme.palette.customColor.white,
                        boxShadow: "0px 4px 13px 0px rgba(97, 97, 97, 0.02)",
                        margin: "5px 0 0 10px",
                        padding: "6px",
                        ul: {
                            padding: 0,
                        },
                        li: {
                            backgroundColor: "transparent !important",
                            color: theme.palette.customColor.darkGrey,
                            fontSize: "16px",
                            lineHeight: "24px",
                            padding: "10px 16px",
                            "&:hover": {
                                backgroundColor: "transparent",
                            },
                            "&:first-of-type": {
                                padding: "0 0 8px 0",
                            },
                            "&.search-input": {
                                padding: "16px 16px 16px 41px",
                            },
                        },
                    },
                },
            }}
        />
    );
};

export default DataAssetSelector;
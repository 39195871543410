import React, {useEffect} from 'react';
import './onboarding.scss';
import {Box} from '@mui/material';
import {LogoHeader} from 'assets/icons';
import OnboardingComponent from 'components/onboarding-component/onboarding.component';
import { usePageTitle } from 'hooks/title';

const Onboarding = () => {
    const {setTitle} = usePageTitle();
    useEffect(() => {
        setTitle('Onboarding')
        document.body.style.overflowY = 'hidden';
        return () => {
            document.body.style.overflowY = 'auto';
        };
    }, []);

    return (
        <Box className="onboarding">
            <Box className="onboarding-box">
                <Box className="logo-header">
                    <LogoHeader/>
                </Box>
                <OnboardingComponent/>
            </Box>
        </Box>
    );
};


export default Onboarding;

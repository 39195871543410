import {
    FeatureFilterType,
    FieldType,
    FirstLastType,
    FormulaType,
    MetricType
} from "components/add-features-component/feature-types";
import {DerivedFeature, FeatureType, FieldFeature, FirstLastFeature, MetricFeature,} from "types/features";
import axiosInstance from "../utils/axios";
import { EntityRelation } from "constants/joins";

export interface EntityResponse {
    id: string;
    name: string;
    description: string;
}

export const getEntities = async (branch: string | null): Promise<EntityResponse[]> => {
    const params = branch ? {branch: branch} : null;
    const response = await axiosInstance.get("/entities", {params});
    return response.data;
};

export interface EntityDetail {
    name: string;
    description?: string;
    keyTable: string;
    aliases: string[];
    relatedAssets: { [key: string]: EntityRelation };
    relatedEntities: { [key: string]: EntityRelation };
    features: (MetricFeature | FirstLastFeature | FieldFeature | DerivedFeature)[];
}

export interface EntityToAssetRelationsEntity{
    id: string
    name: string
    features: string[]
}

export interface EntityDetailResponse extends EntityDetail {
    id: string;
    description: string;
}

export const getEntity = async (entityId: string, branch: string | null): Promise<EntityDetailResponse> => {
    const params = branch ? {branch: branch} : null;
    const response = await axiosInstance.get(`/entities/${entityId}`, {params});
    return response.data as EntityDetailResponse;
};

export const saveNewEntity = async (branch: string, entity: EntityDetail): Promise<void> => {
    const response = await axiosInstance.post(`/entities`, entity, {params: {branch}});
    return response.data;
};

export const updateEntity = async (entityId: string, branch: string, entity: EntityDetail): Promise<void> => {
    const response = await axiosInstance.put(`/entities/${entityId}`, entity, {params: {branch}});
    return response.data;
};

export const deleteEntity = async (entityId: string, branch: string): Promise<void> => {
    const response = await axiosInstance.delete(`/entities/${entityId}`, {params: {branch}});
    return response.data;
};

export const saveFeatures = async (
    entityId: string,
    branch: string,
    firstLastFeatures: FirstLastType[],
    metricFeatures: MetricType[],
    fieldFeatures: FieldType[],
    formulaFeatures: FormulaType[]
): Promise<void> => {

    const mapFilters = (filters: FeatureFilterType[] = []) => {
        return filters.map(filter => ({
            field: filter.name,
            operator: filter.operator,
            values: filter.values
        }));
    };

    const mapFirstLastFeatures = (feature: FirstLastType) => {
        return (feature.fields ?? []).map(field => ({
            type: FeatureType.firstLast,
            asset: feature.dataAssetId,
            joinName: feature.joinName,
            name: field.alias || field.name,
            dataType: field.dataType,
            timeField: field.timeField,
            features: [{
                options: {
                    method: feature.method,
                    sort_by: feature.sortBy,
                    field: field.name
                }
            }]
        }));
    };

    const mapMetricFeatures = (feature: MetricType) => {
        return (feature.measures ?? []).map(measure => ({
            type: FeatureType.metric,
            asset: feature.dataAssetId,
            joinName: feature.joinName,
            name: measure.alias || measure.name,
            dataType: measure.dataType,
            timeField: measure.timeField,
            measure: {
                name: measure.name,
                description: measure.description,
                definition: measure.definition,
                is_new: measure.isNew,
            }
        }));
    };

    const mapFieldFeatures = (feature: FieldType) => {
        return (feature.fields ?? []).map(field => ({
            type: FeatureType.field,
            asset: feature.dataAssetId,
            joinName: feature.joinName,
            name: field.alias || field.name,
            dataType: field.dataType,
            timeField: field.timeField,
            fields: [{
                name: field.name,
                alias: field.alias,
            }],
        }));
    };

    // TODO: Simplify, no need to map and merge features, send each separately
    const data: any[] = [
        ...firstLastFeatures.map(feature => ({
            asset: feature.dataAssetId,
            asset_type: feature.dataAssetType,
            filters: mapFilters(feature.filters),
            features: mapFirstLastFeatures(feature),
        })),
        ...metricFeatures.map(feature => ({
            asset: feature.dataAssetId,
            asset_type: feature.dataAssetType,
            filters: mapFilters(feature.filters),
            features: mapMetricFeatures(feature),
        })),
        ...fieldFeatures.map(feature => ({
            asset: feature.dataAssetId,
            asset_type: feature.dataAssetType,
            filters: mapFilters(feature.filters),
            features: mapFieldFeatures(feature),
        })),
        ...formulaFeatures.map(feature => ({
            name: feature.name,
            sql: feature.sql,
            type: FeatureType.formula,
            dataType: feature.dataType,
            features: feature.features
        })),
    ];

    const response = await axiosInstance.put(
        `/entities/${entityId}/features`,
        data,
        {params: {branch}}
    );

    return response.data;
};

import React, { useMemo, useState } from "react";
import { debounce, MenuItem, SelectChangeEvent } from "@mui/material";
import { DataAssetResponse, getDataAssets } from "../../../../services/data-assets";
import SelectComponent from "../../../shared/select/select.component";
import AssetName from "../../../shared/assets/asset-title/asset-name.component";
import { ChevronDown } from "lucide-react";
import theme from "theme";
import { DataAssetTypes } from "constants/common_constants";
import { DestinationDataType, RelatedConfigType } from "constants/joins";
import { EntityResponse, EntityToAssetRelationsEntity, getEntities } from "services/entities";
import { useGlobalContext } from "context/global-context";
import AssetTitle from "components/shared/assets/asset-title/asset-title.component";

interface SelectOption {
    id: string;
    title: string;
    type: RelatedConfigType
}

interface RelatedSelectorProps {
    name?: string;
    inputRef?: React.RefCallback<any>;
    initialValue?: string | null;
    onChange?: (event: any, newValue: DestinationDataType) => void;
    placeholder?: string;
    destinationType: RelatedConfigType
}

const RelatedSelector: React.FC<RelatedSelectorProps> = ({
    initialValue,
    onChange,
    placeholder = "Select destination",
    destinationType
}) => {
    const [selectedValue, setSelectedValue] = useState<string>(initialValue || "");
    const [options, setOptions] = useState<SelectOption[]>([]);
    const [relObjectsMap, setRelObjectsMap] = useState<Map<string, DestinationDataType>>(new Map());
    const [searchText, setSearchText] = useState<string>("");
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const {selectedGitBranch} = useGlobalContext();

    const fetchRelObjects = useMemo(
        () =>
            debounce(async (filter?: string) => {
                setIsLoading(true);
                
                let options: SelectOption[] = []
                let objectsMap: Map<string, DestinationDataType> = new Map()

                if(destinationType == "asset"){
                    const dataAssetsPage = await getDataAssets({ tableId: filter, type: DataAssetTypes.asset });
                    options = dataAssetsPage.assets.map((asset: DataAssetResponse) => ({
                        id: asset.id,
                        title: asset.name,
                        type: "asset"
                    }));
                    objectsMap = new Map(dataAssetsPage.assets.map((asset) => [asset.id, asset]));
                } else if (Array.from(relObjectsMap.keys()).length < 1) {
                    const entities = await getEntities(selectedGitBranch)
                    options = entities.map((entity: EntityResponse) => ({
                        id: entity.id,
                        title: entity.name,
                        type: "entity"
                    }));
                    objectsMap = new Map(entities.map((entity) => [entity.id, entity]));
                }
                setOptions(options);
                setRelObjectsMap(objectsMap);
                setIsLoading(false);
            }, 400),
        []
    );

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
        fetchRelObjects(event.target.value);
    };

    const handleChange = (event: SelectChangeEvent<string>) => {
        const selected = relObjectsMap.get(event.target.value) || null;
        setIsDirty(true);
        setSelectedValue(event.target.value);
        if (onChange) onChange(event, selected);
    };

    const renderSelectedValue = () => {
        const selected = relObjectsMap.get(selectedValue);
        if (selected || (!isDirty && selectedValue))
            return <AssetName assetId={selectedValue} />;
        return placeholder;
    };

    const onSelectOpen = () => {
        if (!options || !options.length) fetchRelObjects(searchText);
    };

    return (
        <SelectComponent
            options={options}
            value={selectedValue}
            onChange={handleChange}
            onOpen={onSelectOpen}
            isLoading={isLoading}
            onSearchChange={handleSearchChange}
            renderOption={(option: SelectOption) => (
                <MenuItem
                    key={option.id}
                    value={option.id}
                    sx={[{
                        borderRadius: "10px",
                        backgroundColor: theme.palette.customColor.lightestGrey5,
                        padding: "10px 16px",
                    },
                    option.type == "asset" && {
                        padding: "6px 12px",
                        "& h6": {
                            color: theme.palette.customColor.darkGrey
                        }
                    }]}
                >
                    <AssetTitle assetId={option.id} />
                </MenuItem>
            )}
            renderValue={renderSelectedValue}
            placeholder={placeholder}
            showSearch={true}
            IconComponent={(props) => <ChevronDown size={18} color={theme.palette.customColor.dark} {...props} />}
            sx={{
                "&.MuiOutlinedInput-root": {
                    "& .MuiSelect-select": {
                        border: `1px solid ${theme.palette.customColor.lightGrey1}`,
                        borderRadius: "10px",
                        boxShadow: "0px 4px 13px 0px rgba(97, 97, 97, 0.02)",
                        padding: "6px 16px",
                        color: theme.palette.customColor.grey,
                    },
                    "& .MuiSelect-iconOutlined": {
                        top: "calc(50% - .5em) !important",
                    },
                },
            }}
            MenuProps={{
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                },
                PaperProps: {
                    sx: {
                        borderRadius: "16px",
                        border: `1px solid ${theme.palette.customColor.lightGrey1}`,
                        background: theme.palette.customColor.white,
                        boxShadow: "0 4px 13px 0 rgba(97, 97, 97, 0.02)",
                        margin: "5px 0 0 10px",
                        padding: "6px",
                        maxHeight: "300px",
                        ul: {
                            padding: 0,
                        },
                        li: {
                            backgroundColor: "transparent !important",
                            color: theme.palette.customColor.darkGrey,
                            fontSize: "16px",
                            lineHeight: "24px",
                            padding: "10px 16px",
                            "&:first-of-type": {
                                padding: "0 0 8px 0",
                            },
                            "&.search-input": {
                                padding: "16px 16px 16px 41px",
                            },
                        },
                    },
                },
            }}
        />
    );
};

export default RelatedSelector;
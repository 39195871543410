import React from "react";
import {Box} from "@mui/material";

import AddEditBusinessEntitiesStepperView, {BusinessEntitiesModalCloseProps}
    from "./add-edit-business-entities-stepper/add-edit-business-entities-stepper-view.component";
import { CacheContextProvider } from "context/cache-context";

const BusinessEntityView: React.FC<BusinessEntitiesModalCloseProps> = ({onClose, entityId}) => {
    return (
        <Box
            className="flex-box-col-center"
        >
            <CacheContextProvider>
                <AddEditBusinessEntitiesStepperView entityId={entityId} onClose={onClose}/>
            </CacheContextProvider>
                        
        </Box>

    );
};

export default BusinessEntityView;

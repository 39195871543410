import { Dispatch, SetStateAction, useEffect, useState } from "react";

export function usePageTitle(): {title: string, setTitle:Dispatch<SetStateAction<string>>} {
  const [currrentTitle, setTitle] = useState<string>("")
  
  useEffect(()=>{
    document.title = `${currrentTitle} - Lynk Studio`;
  },[currrentTitle])
  
  return {title: currrentTitle, setTitle}
}
import React, {useMemo, useState} from "react";
import {
    Box,
    TextareaAutosize,
    TextField,
    Typography,
} from "@mui/material";
import {DataAssetFullResponse} from "services/data-assets";
import theme from "theme";
import SelectChips from "../../../shared/select/select-chips.component";
import {DataAssetName} from "../../../add-features-component/data-asset-name";
import {assetDataType} from "../../../data-asset-component/data-asset-modal/data-asset-modal-view.component";

interface AssetDetailsProp {
    assetId: DataAssetFullResponse["id"],
    description: DataAssetFullResponse["description"],
    businessKeys: DataAssetFullResponse["businessKeys"],
    timeField?: DataAssetFullResponse["defaults"]["timeField"],
    columns: DataAssetFullResponse["columns"],
    updateDataAsset: (value: assetDataType) => void;
}

const AssetDetails: React.FC<AssetDetailsProp> = ({ assetId, description,  businessKeys, timeField, columns, updateDataAsset }) => {
    const [openKey, setOpenKey] = useState(false);
    const [openTime, setOpenTime] = useState(false);
    const [businessKeyError, setBusinessKeyError] = useState(false);
    const [timeFieldError, setTimeFieldError] = useState(false);

    const dataAssetColumns = useMemo(() => {
        return columns.map(c => c.name);
    }, [columns]);
    const updateDescription = (value: string) => {
        updateDataAsset({description: value});
    };
    const updateBusinessKeys = (value: string[]) => {
        setBusinessKeyError(value.length === 0);
        updateDataAsset({businessKeys: value});
    };
    const updateTimeField = (value: string) => {
        setTimeFieldError(!Boolean(value));
        updateDataAsset({timeField: value});
    };

    return (
        <Box className="flex-box-col" sx={{maxWidth: "620px", width: "100%", gap: "32px"}}>
            <Box>
                <Typography variant="h5" sx={{color: theme.palette.customColor.darkGrey}}>
                    Location
                </Typography>
                <DataAssetName assetId={assetId}/>
            </Box>

            <Box>
                <Typography
                    variant="h5"
                    sx={{
                        color: theme.palette.customColor.darkGrey,
                        marginBottom: "10px",
                    }}
                >
                    Description
                </Typography>

                <TextField
                    value={description}
                    onChange={(e) => updateDescription(e.target.value)}
                    InputProps={{
                        inputComponent: TextareaAutosize,
                        inputProps: {
                            className: "text-area-connect-git",
                            minRows: 5,
                            placeholder: "Your data asset description..."
                        },
                    }}
                    sx={{width: "100%"}}
                />
            </Box>

            <Box>
                <Typography
                    variant="h5"
                    sx={{
                        color: theme.palette.customColor.darkGrey,
                        marginBottom: "10px",
                    }}
                >
                    Key *
                </Typography>
                <SelectChips
                    multiple={true}
                    options={dataAssetColumns}
                    selectedValues={[...businessKeys]}
                    onChange={(newValues) => {
                        updateBusinessKeys([...newValues]); // Create a new array to ensure React detects the change
                    }}
                    onOpen={() => setOpenKey(true)}
                    onClose={() => setOpenKey(false)}
                    open={openKey}
                />
                {businessKeyError && (
                    <Typography variant="body1" color="error">
                        At least one key is required.
                    </Typography>
                )}
            </Box>

            <Box>
                <Typography
                    variant="h5"
                    sx={{
                        color: theme.palette.customColor.darkGrey,
                        marginBottom: "10px",
                    }}
                >
                    Default Time Field *
                </Typography>
                <SelectChips
                    options={dataAssetColumns}
                    selectedValues={timeField ? [timeField] : []}
                    onChange={(newValues) => updateTimeField(newValues[0])}
                    onOpen={() => setOpenTime(true)}
                    onClose={() => setOpenTime(false)}
                    open={openTime}
                />
                {timeFieldError && (
                    <Typography color="error" variant="body1">
                        A default time field is required.
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

export default AssetDetails;
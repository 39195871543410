import React from "react";
import {Box, Typography} from "@mui/material";
import theme from "theme";

const BusinessEntitiesWelcomeComponent: React.FC = () => {
    return (
        <Box>
            <Box className="flex-box-col-center">
                <span className="flex-box-col-center" style={{color: theme.palette.customColor.lightGrey2}}>
                    <span
                        className="material-symbols-outlined"
                        style={{fontSize: "200px"}}
                    >
                        arrow_upward
                    </span>
                    <Typography variant="h3" sx={{textAlign: "center"}}>
                        You have no entities.<br />Switch to edit mode to start creating.
                    </Typography>
                </span>
            </Box>
        </Box>
    );
};

export default BusinessEntitiesWelcomeComponent;
import { enqueueSnackbar, OptionsWithExtraProps } from "notistack";

export interface NotificationOptions {
    message: string;
    title?: string;
    level: "info" | "error" | "success" | "warning",
    autoHideDuration?: number,
    persist?: boolean
}

const DEFAULT_NOTIFICATION_DURATION = 3000


export const showNotification = (options: NotificationOptions) => {
    return enqueueSnackbar(options.message, {
        variant: options.level,
        persist: options.persist,
        title: options.title,
        autoHideDuration: options.autoHideDuration || DEFAULT_NOTIFICATION_DURATION
    })
}
import React, { useState } from "react";
import ButtonComponent from "../../../../../../shared/button/button.component";
import AddFeaturesFormulasPopover from "../add-features-formulas-modal/add-features-formulas-popover";
import {FormulaFeatureSelection} from "../../../../../feature-types";
import { DataAssetColumn } from "services/data-assets";

interface AddFormulaFeatureModalProps {
    features: DataAssetColumn[]
    selectedFormulaData: FormulaFeatureSelection[];
    onSelected: (features: FormulaFeatureSelection[])=>void;
}

const AddFormulaFeatureButton: React.FC<AddFormulaFeatureModalProps> = ({
    features,
    selectedFormulaData,
    onSelected
}) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const onOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const onClose = () => {
        setAnchorEl(null)
    }

    return (
        <>
            <ButtonComponent
                onClick={onOpen}
                variant="textPurple"
                label="+ Add feature"
                sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    margin: "12px 0 10px 0",
                    padding: 0,
                }}
            />
            <AddFeaturesFormulasPopover
                anchorEl={anchorEl}
                onClose={onClose}
                features={features}
                selectedFormulaData={selectedFormulaData}
                setSelectedFormulaData={onSelected}
            />
        </>
    );
};

export default AddFormulaFeatureButton;

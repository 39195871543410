import LoadingSpinner from "components/shared/LoadingSpinner";
import React, {createRef, useEffect, useMemo, useState,} from "react";
import {Box} from "@mui/material";
import AddEditBusinessEntitiesDetails, {AddEditBusinessEntitiesDetailsValues, detailsValuesFromEntity}
    from "../add-edit-business-entities-details/add-edit-business-entities-details.component";
import AddEditBusinessEntitiesDimTable
, { dimValuesFromEntity }    from "../add-edit-business-entities-dim-table/add-edit-business-entities-dim-table.component";
import AddEditBusinessEntitiesRelatedDatasets
    , {
    RelatedAssetsStepFormData,
    relatedAssetsValuesFromEntity
} from "../add-edit-business-entities-related-datasets/add-edit-business-entities-related-datasets.component";
import AddEditBusinessEntitiesRelated
, { relatedEntitiesValuesFromEntity }    from "../add-edit-business-entities-related/add-edit-business-entities-related.component";
import {saveNewEntity, getEntity, EntityDetail, updateEntity, EntityDetailResponse} from "services/entities";
import {useGlobalContext} from "context/global-context";
import {
    RelatedEntitiesSelectData,
} from "../add-edit-business-entities-related/related-entities-select/related-entities-select.component";
import AddEditBusinessEntitiesViewStepper from "./add-edit-business-entities-view-stepper.component";
import AddEditBusinessEntitiesEditStepper from "./add-edit-business-entities-edit-stepper.component";
import { RelatedConfig } from "constants/joins";
import { DataAssetResponse } from "services/data-assets";
import WarningMessage from "components/shared/warning/warning-message.component";
import theme from "theme";
import { AxiosError } from "axios";
import { showNotification } from "services/notifications";
import { useCacheContext } from "context/cache-context";


export interface BusinessEntitiesModalCloseProps {
    onClose: (shouldRefresh: boolean) => void;
    entityId?: string | null;
}

type EntityDataArray = [AddEditBusinessEntitiesDetailsValues | null, {
    keyTable: string | null
} | null, RelatedAssetsStepFormData | null, RelatedEntitiesSelectData | null]

const AddEditBusinessEntitiesStepperView: React.FC<BusinessEntitiesModalCloseProps> = ({onClose, entityId}) => {
    const {selectedGitBranch} = useGlobalContext();
    const [goToIndex, setGoToIndex] = useState<number | null>(() => null);
    const [loading, setLoading] = useState<boolean>(true);
    const [saving, setSaving] = useState<boolean>(false);
    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const [loadError, setLoadError] = useState<any>(null);
    const [stepsValues, setStepsValue] = useState<EntityDataArray>([null, null, null, null]);
    const [originalEntity, setOriginalEntity] = useState<EntityDetailResponse | null>(null);
    const { getAndCacheAssetById } = useCacheContext()
    
    const formRefs = useMemo(() => Array(4).fill(0).map(() => createRef<HTMLFormElement>()), []);

    useEffect(() => {
        async function getEntityDetails() {
            const entity = await getEntity((entityId as string), selectedGitBranch);
            setOriginalEntity(entity)
        }

        async function run(){
            try {
                if (entityId) {
                    await getEntityDetails();
                }
            } catch (e){
                setLoadError(e)
            } finally {
                setLoading(false);
            }
        }
        run()
    }, [entityId]);

    // stepsValues are updating only when moving between steps
    useEffect(() => {
        async function move() {
            // If last step and no entity ID (new entity) / have entity ID (edit) and goToIndex is -1 - save
            if ((activeStepIndex === steps.length - 1 && !entityId) || goToIndex === -1) {
                await saveOrUpdate();
                return;
            }

            // -1 indicates saving directly
            if (goToIndex !== null && goToIndex !== -1) {
                setActiveStepIndex(goToIndex);
                setGoToIndex(null);
                return;
            }
        }

        move();
    }, [stepsValues]);

    const handleNext = (stepIndex?: number) => {
        formRefs[activeStepIndex].current?.requestSubmit();
        setGoToIndex((stepIndex || stepIndex === 0) ? stepIndex : activeStepIndex + 1);
    };

    const handleBack = () => {
        setActiveStepIndex((prevActiveStep) =>
            prevActiveStep > 0 ? prevActiveStep - 1 : prevActiveStep
        );
    };

    const handleCloseModal = (shouldRefresh: boolean = false) => {
        setGoToIndex(null);
        setActiveStepIndex(0);
        onClose(shouldRefresh);
    };

    const getParsedRelations = (relations: RelatedConfig[])=>{
        return relations.reduce((prev, cur) =>
            // TODO: join_pattern: cur.joinPattern
            ({...prev, [cur.destination || ""]: { relationship: cur.relationship, joins: cur.joins}}), {}
        );
    }

    const saveOrUpdate = async () => {
        let [details, dimTable, relatedAssets, relatedEntities] = (stepsValues as EntityDataArray);

        if ((!entityId || !originalEntity) && [details, dimTable, relatedAssets, relatedEntities].some(value => value === null)) {
            showNotification({
                level:"error",
                title: "Invalid form",
                message: "Some of the fields are not filled."
            })
            return;
        }

        setSaving(true);

        // StepValues will be null if user didn't visit tab
        details = details || detailsValuesFromEntity(originalEntity)
        dimTable = dimTable || dimValuesFromEntity(originalEntity)
        relatedAssets = relatedAssets || await relatedAssetsValuesFromEntity(originalEntity, selectedGitBranch, getAndCacheAssetById)
        relatedEntities = relatedEntities || await relatedEntitiesValuesFromEntity(originalEntity, selectedGitBranch, getAndCacheAssetById)

        const parsedRelatedEntities: EntityDetail["relatedEntities"] = getParsedRelations(relatedEntities?.entities || []);
        const parsedRelatedAssets: EntityDetail["relatedAssets"] = getParsedRelations(relatedAssets?.assets || []);

        const entity: EntityDetail = {
            name: details?.name.toLowerCase() || "",
            description: details?.description,
            aliases: (details?.aliases || []).map(a => a.value.toLowerCase()),
            keyTable: dimTable?.keyTable || "",
            relatedAssets: parsedRelatedAssets,
            relatedEntities: parsedRelatedEntities,
            features: originalEntity?.features || [],
        };

        try {
            // If is new, save as new
            if (!entityId)
                await saveNewEntity((selectedGitBranch as string), entity);
            // Update existing
            else
                await updateEntity(entityId, (selectedGitBranch as string), entity);

            onClose(true);
        } catch (err) {
            let message = String(err)
            if(err instanceof AxiosError){
                message = String(err.message)
            }
            showNotification({
                level: "error",
                title: "Failed to commit changes!",
                message: message,
                persist: true
            })
            setSaving(false);
        }
    };

    const handleStepFormValues = async (values: any) => {
        const valuesArray = (Array.from(stepsValues) as EntityDataArray);
        valuesArray[activeStepIndex] = values;
        setStepsValue(valuesArray);
    };

    const steps = [
        {
            label: "Entity details",
            subLabel: "",
            description: <AddEditBusinessEntitiesDetails handleFormValues={handleStepFormValues} initialValue={stepsValues[0]}
                                                         originalEntity={originalEntity} formRef={formRefs[0]}/>,
        },
        {
            label: "Entity key asset",
            subLabel: (
                <>
                    The DIM dataset is a dataset that contains all the possible instances of
                    <br/>
                    that entity and for each instance it has only one record.
                </>
            ),
            description: <AddEditBusinessEntitiesDimTable handleFormValues={handleStepFormValues} initialValue={stepsValues[1]}
                                                          originalEntity={originalEntity} formRef={formRefs[1]}
                                                          showDimTableInfo/>,
        },
        {
            label: "Related datasets",
            subLabel: (
                <>
                    Choose data assets that relate to "business entity name"
                    <br/>
                    You will be able to edit it later
                </>
            ),
            description: <AddEditBusinessEntitiesRelatedDatasets handleFormValues={handleStepFormValues}
                                                                initialValue={stepsValues[2]}
                                                                 dimTable={stepsValues[1]?.keyTable || originalEntity?.keyTable || ""}
                                                                 originalEntity={originalEntity}
                                                                 formRef={formRefs[2]}
                                                                 entityName={stepsValues[0]?.name || ""}

            />,
        },
        {
            label: "Related entities",
            subLabel: "Map the entity relationships for \"business entity name\"",
            description: <AddEditBusinessEntitiesRelated handleFormValues={handleStepFormValues}
                                                        dimTable={stepsValues[1]?.keyTable || originalEntity?.keyTable || ""}
                                                        initialValue={stepsValues[3]}
                                                         originalEntity={originalEntity} formRef={formRefs[3]}
                                                         entityName={stepsValues[0]?.name || ""}
            />,
        },
    ];

    return (
        loading ? (<LoadingSpinner open={true}/>) : ( loadError ? (
        <Box className="flex-box-col" sx={{gap: "30px", overflow: "auto", flexGrow: 1}}>
            <WarningMessage sx={{width: "100%"}} message={`Invalid Entity configuration:`}>
                <Box sx={{
                    background: theme.palette.customColor.lightGrey3,
                    borderRadius: "10px",
                    padding: "10px",
                    width: "90%",
                    border: `1px solid var(--Gray-300, ${theme.palette.customColor.lightGrey1})`
                }}>{String(loadError)}</Box>
            </WarningMessage>
        </Box>
        ) : (
            <Box className="flex-box-col-center"
                sx={{maxWidth: "1232px", width: "100%", marginTop: "32px", paddingBottom: "100px"}}>
                {entityId ?
                    (<AddEditBusinessEntitiesEditStepper entityName={entityId}
                                                        handleClose={() => handleCloseModal()} onStepClick={handleNext}
                                                        onSave={() => handleNext(-1)} activeStep={activeStepIndex}/>) :
                    (<AddEditBusinessEntitiesViewStepper handleBack={handleBack} handleNext={() => handleNext()}
                                                        steps={steps} activeStep={activeStepIndex}
                                                        onClose={handleCloseModal}/>)
                }
                <Box className="flex-box-center" sx={{width: "100%"}}>
                    {steps[activeStepIndex]?.description}
                </Box>
                <LoadingSpinner open={saving}/>
            </Box>
        )
    )
    );
};

export default AddEditBusinessEntitiesStepperView;
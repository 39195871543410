import React, {useMemo, useState} from "react";
import {Box, Popover, Typography} from "@mui/material";
import theme from "theme";
import ButtonComponent from "../../../../../../shared/button/button.component";
import {containsText} from "utils/contains-text";
import SearchComponent from "../../../../../../shared/search/search.component";
import {FormulaFeatureSelection} from "../../../../../feature-types";
import { DataAssetColumn } from "services/data-assets";
import { DataTypeIcons } from "types/data-types";

interface AddFeaturesFormulasPopoverProps {
    anchorEl: HTMLElement | null;
    onClose: () => void;
    features: DataAssetColumn[]
    selectedFormulaData: FormulaFeatureSelection[];
    setSelectedFormulaData: (FormulaData: FormulaFeatureSelection[]) => void;
}

const AddFeaturesFormulasPopover: React.FC<AddFeaturesFormulasPopoverProps> = ({
    features,
    anchorEl,
    onClose,
    setSelectedFormulaData,
}) => {
    const [featureStates, setFeatureStates] = useState<Record<number, boolean>>({});
    const [searchValue, setSearchValue] = useState<string>("");

    const handleCheckboxChange = (id: number) => {
        setFeatureStates(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    const handleAddSelectedFormulaData = () => {
        const selected = featuresOptions.filter(data => featureStates[data.id]);
        setSelectedFormulaData(selected);
        onClose();
    };

    const featuresOptions: FormulaFeatureSelection[] = useMemo<FormulaFeatureSelection[]>(() =>
        features.map((f, index)=>({
            id: index,
            name: f.name,
            dataType: f.type
        }))
    , [features]);

    const filteredFeatures = useMemo(() =>
        featuresOptions.filter(data => containsText(data.name, searchValue)).sort((a, b) => a.name.localeCompare(b.name))
    , [featuresOptions, searchValue]);

    return (
        <Popover 
            open={anchorEl !== null}
            anchorEl={anchorEl}
            onClose={onClose} className="container-modal-bg-none">
            <Box sx={{padding: "16px", width: "348px", boxSizing:"border-box"}}>
                <Box className="flex-box-align-center">

                    <SearchComponent
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                    />

                </Box>
                <Box
                    sx={{
                        maxHeight: "390px",
                        overflowY: "auto",
                        marginTop: "16px",
                    }}
                >
                    <Typography variant="h5" sx={{color: theme.palette.customColor.grey, paddingLeft: "12px"}}>
                        All Properties
                    </Typography>
                    {filteredFeatures.map((data) => {
                        const isSelected = featureStates[data.id] || false;

                        return (
                            <Box
                                key={data.id}
                                className="flex-box-align-center"
                                sx={{
                                    cursor: "pointer",
                                    borderRadius: "10px",
                                    backgroundColor: isSelected
                                        ? theme.palette.customColor.purpleLight
                                        : "transparent",
                                    marginBottom: "3px",
                                    padding: "8px 16px",
                                    "&:last-child": {
                                        marginBottom: 0,
                                    },
                                }}
                                onClick={() => handleCheckboxChange(data.id)}
                            >
                                <span
                                    className="material-symbols-outlined"
                                    style={{
                                        color: isSelected
                                            ? theme.palette.customColor.purple
                                            : "inherit",
                                    }}
                                >
                                    {DataTypeIcons[data.dataType] || DataTypeIcons["string"]}
                                </span>
                                <Box sx={{ml: "10px"}}>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{
                                            color: isSelected
                                                ? theme.palette.customColor.purple
                                                : theme.palette.customColor.darkGrey,
                                        }}
                                    >
                                        {data.name}
                                    </Typography>
                                </Box>
                                {isSelected && (
                                    <span
                                        className="material-symbols-outlined"
                                        style={{
                                            marginLeft: "auto",
                                            color: theme.palette.customColor.purple,
                                        }}
                                    >
                                        check
                                    </span>
                                )}
                            </Box>
                        );
                    })}
                </Box>
                <Box className="flex-box-end" sx={{marginTop: "8px"}}>
                    <ButtonComponent
                        variant="text"
                        label="Cancel"
                        onClick={onClose}
                        sx={{fontSize: "14px", lineHeight: "22px"}}
                    />
                    <ButtonComponent
                        disabled={!Object.values(featureStates).some(checked => checked)}
                        variant="contained"
                        label="Add feature"
                        onClick={handleAddSelectedFormulaData}
                        sx={{fontSize: "14px", lineHeight: "22px"}}
                    />
                </Box>
            </Box>
        </Popover>
    );
};

export default AddFeaturesFormulasPopover;

import React, {useEffect, useState} from "react";
import {Box} from "@mui/material";
import {AddEditBusinessEntityStepProps} from "../add-edit-business-entities-step-props";
import {DataAssetColumn, DataAssetFullResponse} from "services/data-assets";
import {useForm} from "react-hook-form";
import RelatedEntityDataAsset from "../add-edit-business-entities-stepper/related-data-asset.component";
import { RelatedConfig } from "constants/joins";
import { useGlobalContext } from "context/global-context";
import { GetAssetFunc, mapRelationToConfig } from "utils/map-relation-to-config";
import LoadingSpinner from "components/shared/LoadingSpinner";
import { EntityDetailResponse } from "services/entities";
import { AxiosError } from "axios";
import WarningMessage from "components/shared/warning/warning-message.component";
import theme from "theme";
import { useCacheContext } from "context/cache-context";

export interface RelatedAssetsStepFormData {
    assets: RelatedConfig[];
}

interface RelatedAssetsStepProps extends AddEditBusinessEntityStepProps<RelatedAssetsStepFormData> {
    dimTable: string;
    entityName: string;
}

export const relatedAssetsValuesFromEntity = async (entity: EntityDetailResponse | null, selectedGitBranch: string | null, getAssetFn: GetAssetFunc): Promise<RelatedAssetsStepFormData> => {
    return {
        assets: await mapRelationToConfig(entity?.relatedAssets || {}, "asset", selectedGitBranch, getAssetFn)
    }
}

const AddEditBusinessEntitiesRelatedDatasets: React.FC<RelatedAssetsStepProps> = ({
    handleFormValues,
    dimTable,
    originalEntity,
    initialValue,
    formRef,
    entityName,
}) => {
    const [entityColumns, setEntityColumns] = useState<DataAssetColumn[]>([]);
    const [dimTableData, setDimTableData] = useState<DataAssetFullResponse|null>(null)
    const [loading, setLoading] = useState<boolean>(true);
    const [defaultValue, setDefaultValue] = useState<RelatedAssetsStepFormData | null>(null);
    const [loadError, setLoadError] = useState<any>(null);
    const {selectedGitBranch} = useGlobalContext();
    const { getAndCacheAssetById } = useCacheContext()

    const {
        handleSubmit,
        formState: {errors},
        setValue
    } = useForm({defaultValues: async ()=>{
        let formVal = initialValue
        try {
            formVal = initialValue || await relatedAssetsValuesFromEntity(originalEntity, selectedGitBranch, getAndCacheAssetById)
        } catch (e){
            if (e instanceof AxiosError)
                e = new Error(e.message)
            setLoadError(e)
        }
        setLoading(false)
        setDefaultValue(formVal)
        return formVal
    }});

    const updateEntityColumns = ()=>{
        setEntityColumns([
            ...(dimTableData?.columns || []),
            ...((originalEntity?.features || []).map(feature=>({
                name: feature.name,
                type: feature.dataType || "string",
                description: ""
            })))
        ])
    }

    const fetchDataAsset = async (assetId: string) => {
        try {
            const dimTableData = await getAndCacheAssetById(assetId, selectedGitBranch);
            setDimTableData(dimTableData)
            setEntityColumns(dimTableData?.columns || []);
        } catch (error: any) {
            console.log(error.message);
        }
    };

    useEffect(() => {
        if (dimTable.length) fetchDataAsset(dimTable);
    }, [dimTable]);

    useEffect(() => {
        updateEntityColumns()
    }, [dimTableData]);

    const onSubmit = (data: any) => {
        const values = {
            assets: data.assets
        };
        handleFormValues(values);
    };

    const relatedAssetsChanged = (data: RelatedAssetsStepFormData) => {
        setValue("assets", data.assets);
    };

    return (
        loading ? <LoadingSpinner open={true}/> : (loadError ? (
            <Box className="flex-box-col" sx={{gap: "30px", overflow: "auto", marginTop: "20px", flexGrow: 1}}>
                <WarningMessage message={`Invalid Entity configuration:`}>
                    <Box sx={{
                        background: theme.palette.customColor.lightGrey3,
                        borderRadius: "10px",
                        padding: "10px",
                        width: "90%",
                        border: `1px solid var(--Gray-300, ${theme.palette.customColor.lightGrey1})`
                    }}>{String(loadError)}</Box>
                    <Box sx={{marginTop: "10px"}}>Please fix the issue first and refresh.</Box>
                </WarningMessage>
            </Box>
            ) : (<Box component="form" ref={formRef} onSubmit={handleSubmit(onSubmit)} className="flex-box-col-center"
             sx={{maxWidth: "1161px", width: "100%"}}>
            {defaultValue && <RelatedEntityDataAsset
                initialValues={defaultValue}
                onChange={relatedAssetsChanged}
                entityColumns={entityColumns}
                entityName={entityName}
            />}
        </Box>))
    );
};

export default AddEditBusinessEntitiesRelatedDatasets;
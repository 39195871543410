import ContextModal from "components/admin-component/account/contexts-table/context-create-modal";
import {DEFAULT_CONTEXT, useGlobalContext} from "context/global-context";
import React, {useCallback, useEffect, useState} from "react";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import {deleteUser as deleteUserApi, TenantUser, TenantUserStatus} from "services/system";
import ButtonComponent from "../../../shared/button/button.component";
import { Box, Typography } from "@mui/material";
import theme from "theme";
import ConfirmationModal from "components/shared/modal/confirmation-modal";
import dayjs from "dayjs";
import { useLoggedInUser } from "context/logged-in-user-context";
import { showNotification } from "services/notifications";

export interface UsersTableProps{
    users: TenantUser[]
    onUserDeleted: (userId: string) => void
}

const UsersTable: React.FC<UsersTableProps> = ({
    users,
    onUserDeleted
}) => {
    const [userToDelete, setUserToDelete] = useState<TenantUser | null>(null);
    const [displayUsers, setDisplayUsers] = useState<TenantUser[] | undefined>(undefined);
    const {user} = useLoggedInUser();

    const deleteUser = async ()=>{
        if (userToDelete === null) 
            return
        await deleteUserApi(userToDelete.id)
        showNotification({
            level: "success",
            title: "User removed",
            message: `${userToDelete.name} successfully removed.`
        })
        setUserToDelete(null);
        onUserDeleted(userToDelete.id)
    }

    useEffect(()=>{
        setDisplayUsers(users)
    },[users])

    const columns: GridColDef[] = [
        {
            field: "name",
            headerName: "Name",
            flex: .9,
            sortable: false,
            renderCell: (params) => {
                return (
                    <Box className="flex-box-center">
                        <img src={params.row.profilePictureUrl} 
                            style={{borderRadius: "50%", marginRight:"12px"}}
                            alt="profile pic" 
                            width="32px"/>
                        <Typography
                            variant="subtitle2"
                            sx={{
                                color: theme.palette.customColor.dark,
                            }}
                            >
                            {params.row.name}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "email",
            headerName: "E-mail",
            flex: .9,
            sortable: false,
            renderCell: (params) => (
                <Typography
                    variant="h5"
                    sx={{
                        color: theme.palette.customColor.slateGrey,
                    }}
                >
                    {params.row.email}
                </Typography>
            ),
        },
        {
            field: "lastLogin",
            headerName: "Last Login",
            flex: .9,
            sortable: false,
            renderCell: (params) => (
                <Typography
                    variant="h5"
                    sx={{
                        color: theme.palette.customColor.slateGrey,
                    }}
                >
                    {params.row.status == TenantUserStatus.Active ? dayjs(params.row.lastLogin).format("MMM DD, YYYY, hh:mm A [GMT]Z") : "Pending"}
                </Typography>
            ),
        },
        {
            field: "deleteAction",
            headerName: "",
            width: 50,
            sortable: false,
            renderCell: (params) => {
                return params.row.id !== user?.id ? (<span
                        className="material-symbols-outlined flex-box-center"
                        style={{cursor: "pointer", width: "100%", color: theme.palette.customColor.slateGrey}}
                        onClick={()=>setUserToDelete(params.row)}
                    >
                        delete
                    </span>) : <></>
            }
        },
    ];

    return (
        <Box className="dim-table-style" sx={{ width: "100%", marginTop: "32px" }}>
            <DataGrid
                autoHeight={true}
                className="entity-data-grid"
                columns={columns}
                rows={displayUsers}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                getRowHeight={() => "auto"}
                hideFooter={true}
                sx={{
                    margin: "16px 0 0 0",
                    "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": { py: "15px" },
                }}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: false,
                    },
                }}
            />

            <ConfirmationModal
                open={Boolean(userToDelete)}
                onClose={()=>setUserToDelete(null)}
                onConfirm={()=>deleteUser()}
                title={`Are you sure you want to remove ${userToDelete?.name}?`}
                body={`This action will permanently remove all access of this user to Lynk studio.`}
                confirmText="Delete"
                cancelText="Cancel"
            />
        </Box>
    );
};

export default UsersTable;
import React from 'react';
import {Box, Typography} from "@mui/material";
import theme from "theme";
import {Link, Outlet, useLocation} from 'react-router-dom';

const AdminComponent = () => {
    const location = useLocation();

    const adminCenterList = [
        {label: "Account", icon: "person", path: "/admin/account"},
        {label: "Users", icon: "group", path: "/admin/users"},
        {label: "API Tokens", icon: "settings", path: "/admin/api-tokens"}
    ];

    return (
        <Box sx={{display: "flex", marginTop: "-41px", width: "100%"}}>
            <Box
                sx={{
                    boxSizing: "border-box",
                    borderRight: `1px solid ${theme.palette.customColor.lightGrey1}`,
                    height: "100%",
                    width: "240px",
                    flexShrink: 0,
                    padding: "32px 24px 0 0",
                }}
            >
                <Typography
                    variant="body2"
                    sx={{
                        color: theme.palette.customColor.dark,
                        marginBottom: "24px",
                    }}
                >
                    Admin center
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                    }}
                >
                    {adminCenterList.map((item, index) => (
                        <Link
                            key={index}
                            to={item.path}
                            style={{
                                textDecoration: "none",
                                display: "flex",
                                alignItems: "center",
                                color: location.pathname === item.path
                                    ? theme.palette.customColor.purple
                                    : theme.palette.customColor.slateGrey,
                                backgroundColor: location.pathname === item.path
                                    ? theme.palette.customColor.lightestGrey5
                                    : "transparent",
                                borderRadius: "12px",
                                padding: "10px 12px",
                                fontFamily: "BlinkMacSystemFont",
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "24px",
                            }}
                        >
                            <span
                                className="material-symbols-outlined"
                                style={{marginRight: "10px"}}
                            >
                                {item.icon}
                            </span>
                            {item.label}
                        </Link>
                    ))}
                </Box>
            </Box>

            <Box
                className="admin-box"
                sx={{
                    boxSizing: "border-box",
                    maxWidth: "1000px",
                    width: "100%",
                    margin: "0 auto",
                    padding: "40px 0",
                    "@media screen and (max-width: 1600px)": {
                        padding: "40px",
                    },
                }}>
                <Outlet/>
            </Box>
        </Box>
    );
};

export default AdminComponent;
import React from "react";
import {Box, Typography} from "@mui/material";
import AddFeaturesFormulaModalData, {
} from "../add-features-modal-data/add-features-formula-modal-data";
import theme from "theme";

interface AddFeaturesFormulaProps {
    handleCloseModal: () => void
}

const AddFeaturesFormula: React.FC<AddFeaturesFormulaProps> = ({handleCloseModal}) => {
    return (
        <Box className="flex-box-col-center" sx={{height:"100%"}}>
            <Typography
                variant="h4"
                sx={{
                    color: theme.palette.customColor.dark,
                    fontWeight: 600,
                    marginBottom: "24px"
                }}
            >
                Add formula
            </Typography>
            <AddFeaturesFormulaModalData handleCloseModal={handleCloseModal}/>
        </Box>
    );
};

export default AddFeaturesFormula;
import React from "react";
import { Box, Typography } from "@mui/material";
import theme from "theme";
import "./asset-title.component.scss";

interface AssetNameProps {
    assetId: string;
}

const AssetName: React.FC<AssetNameProps> = ({ assetId }) => {
    if (!assetId) {
        return null;
    }

    const assetsName = assetId.split(".").pop() || assetId;

    return (
        <Box className="flex-box-center-space-between" width="100%">
            <Typography
                variant="subtitle2"
                sx={{ color: theme.palette.customColor.black, marginRight: "16px" }}
            >
                {assetsName}
            </Typography>
        </Box>
    );
};

export default AssetName;
import React, { useEffect } from "react";
import {Box, SxProps} from "@mui/material";
import { Warning } from "assets/icons";

interface ErrorProps {
    message: string,
    sx?: SxProps
    children?: React.ReactElement | React.ReactElement[]
}

const WarningMessage: React.FC<ErrorProps> = ({message, sx, children}) => {
    return (
        <Box className="flex-box-col-center" sx={{
                borderRadius: "12px",
                background: "rgba(244, 73, 73, 0.10)",
                fontFamily: "BlinkMacSystemFont",
                padding: "16px",
                gap: "5px",
                ...sx
            }}>
            <Box className="flex-box-start-center" sx={{width: "100%"}}>
                <Box sx={{flexShrink: 0}}><Warning /></Box>
                <span style={{
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "24px"
                }}>
                    {message}
                </span>
            </Box>
            <div style={{
                fontSize: "15px",
                fontWeight: "400",
                lineHeight: "22px",
                width: "100%"
            }}>
                {children}
            </div>
        </Box>
    );
};

export default WarningMessage;

import React from "react";
import { Box, MenuItem, SxProps, Theme } from "@mui/material";
import { DataType, DataTypeIcons } from "types/data-types";
import SelectComponent from "../select/select.component";
import theme from "theme";

interface DataTypeSelectorProps {
    value?: string;
    onChange?: (type: DataType)=>void;
    layout?: "normal" | "minimized";
}

const DataTypeSelector: React.FC<DataTypeSelectorProps> = ({ value, onChange, layout="normal"}) => {
    const dataTypeOptions = Object.keys(DataTypeIcons).map((dataType) => ({
        id: dataType,
        value: dataType,
        title: dataType,
        icon: DataTypeIcons[dataType as DataType]
    }))

    return (
        <SelectComponent
            options={dataTypeOptions}
            onChange={(selectedType) => onChange && onChange(selectedType.target.value as DataType)}
            value={value}
            renderOption={(option) => (
                <MenuItem key={option.id} value={option.title} disableRipple={true}>
                    <Box className="flex-box-row-start" sx={{display: "flex"}}>
                        {option.icon && (
                            <span className="material-symbols-outlined" style={{marginRight: "8px", lineHeight:"normal"}}>
                                {option.icon}
                            </span>
                        )}
                        {option.title}
                    </Box>
                </MenuItem>
            )}
            placeholder="Choose data type"
            sx={[
                layout == "minimized" && {
                    "&.MuiOutlinedInput-root": {
                        "& .MuiSelect-select": {
                            border: `1px solid ${theme.palette.customColor.lightGrey1}`,
                            borderRadius: "10px",
                            boxShadow: "0px 4px 13px 0px rgba(97, 97, 97, 0.02)",
                            padding: "6px 16px",
                            color: theme.palette.customColor.grey,
                        },
                    },
                }
            ]}
        />
    );
};

export default DataTypeSelector;
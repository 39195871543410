import { RelationshipType } from "./joins"

export const relationships = [
    {label: "One to one", value: RelationshipType.OneToOne},
    {label: "One to many", value: RelationshipType.OneToMany},
    {label: "Many to one", value: RelationshipType.ManyToOne},
    {label: "Many to many", value: RelationshipType.ManyToMany}
]

export function getRelationshipByValue(value: string){
    return relationships.find(r=>r.value === value)
}

export function getRelationshipLabelByValue(value: string){
    return getRelationshipByValue(value)?.label
}
import React, { useMemo, useState } from "react";
import { debounce, MenuItem, SelectChangeEvent } from "@mui/material";
import { DataAssetResponse, getDataAssets } from "../../../../services/data-assets";
import { ChevronDown } from "lucide-react";
import theme from "theme";
import { DataAssetTypes } from "constants/common_constants";
import AssetTitle from "../../../shared/assets/asset-title/asset-title.component";
import SelectRelatedComponent from "../select-related.component";
import { RelatedConfigType } from "constants/joins";

interface SelectOption {
    id: string;
    title: string;
}

interface RelatedDataAssetSelectorProps {
    name?: string;
    initialValue?: string | null;
    finalDestinationId?: string;
    onChange?: (event: any, newValue: DataAssetResponse | null) => void;
    placeholder?: string;
    type?: DataAssetTypes;
    finalDestinationType: RelatedConfigType
}

const DataAssetDestinationSelector: React.FC<RelatedDataAssetSelectorProps> = ({
    initialValue,
    finalDestinationId,
    onChange,
    placeholder,
    type,
    finalDestinationType
}) => {
    const [selectedValue, setSelectedValue] = useState<string>(initialValue || "");
    const [finalDestOption, setFinalDestOption] = useState<SelectOption | null>(null);
    const [options, setOptions] = useState<SelectOption[]>([]);
    const [dataAssetMap, setDataAssetMap] = useState<Map<string, DataAssetResponse>>(new Map());
    const [searchText, setSearchText] = useState<string>("");
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const fetchAssets = useMemo(
        () =>
            debounce(async (filter?: string) => {
                setIsLoading(true);
                const dataAssetsPage = await getDataAssets({ tableId: filter, type });
                const selectOptions: SelectOption[] = []
                let finalOption: SelectOption | null = finalDestinationType=="entity" ? {
                    id: finalDestinationId as string,
                    title: finalDestinationId as string,
                } : null
                
                dataAssetsPage.assets.forEach((asset: DataAssetResponse) => {
                    const option = {
                        id: asset.id,
                        title: asset.name,
                    }
                    if (asset.id === finalDestinationId && finalDestinationType=="asset")
                        finalOption = option
                    else{
                        selectOptions.push(option)
                    }
                });

                const dataAssetMap = new Map(dataAssetsPage.assets.map((asset) => [asset.id, asset]));

                setOptions(selectOptions);
                setFinalDestOption(finalOption);
                setDataAssetMap(dataAssetMap);
                setIsLoading(false);
            }, 400),
        []
    );

    const handleSearchChange = (value: string) => {
        setSearchText(value);
        fetchAssets(value);
    };

    const handleChange = (event: SelectChangeEvent<string>) => {
        const selected = dataAssetMap.get(event.target.value) || null;
        setIsDirty(true);
        setSelectedValue(event.target.value);
        if (onChange) onChange(event, selected);
    };

    const renderSelectedValue = () => {
        const selected = dataAssetMap.get(selectedValue);
        if (selected || (!isDirty && selectedValue))
            return <AssetTitle assetId={selectedValue} />;
        return placeholder || "Select destination";
    };

    const onSelectOpen = () => {
        if (!options || !options.length) fetchAssets(searchText);
    };

    return (
        <SelectRelatedComponent
            options={options}
            value={selectedValue}
            onChange={handleChange}
            onOpen={onSelectOpen}
            isLoading={isLoading}
            finalDestinationOption={finalDestOption}
            finalDestinationType={finalDestinationType}
            onSearchChange={handleSearchChange}
            renderOption={(option: SelectOption) => (
                <MenuItem
                    key={option.id}
                    value={option.id}
                    sx={{
                        borderRadius: "10px",
                        backgroundColor: theme.palette.customColor.lightestGrey5,
                        padding: "6px 12px",
                        "& h6": {
                            color: theme.palette.customColor.darkGrey
                        }
                    }}
                >
                    <AssetTitle assetId={option.id} />
                </MenuItem>
            )}
            renderValue={renderSelectedValue}
            placeholder={placeholder || "Select destination (data asset)"}
            IconComponent={(props) => <ChevronDown size={18} color="#1B1A1C" {...props} />}
            sx={{
                width: "325px",
                "&.MuiOutlinedInput-root": {
                    "& .MuiSelect-select": {
                        border: `1px solid ${theme.palette.customColor.lightGrey1}`,
                        borderRadius: "10px",
                        boxShadow: "0px 4px 13px 0px rgba(97, 97, 97, 0.02)",
                        color: theme.palette.customColor.grey,
                        padding: "6px 40px 6px 12px",
                        "& h6": {
                            color: theme.palette.customColor.darkGrey
                        },
                    },
                    "& .MuiSelect-iconOutlined": {
                        top: "calc(50% - .5em) !important",
                    },
                },
            }}
            MenuProps={{
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                },
                PaperProps: {
                    sx: {
                        width: "380px",
                        borderRadius: "16px",
                        border: `1px solid ${theme.palette.customColor.lightGrey1}`,
                        background: theme.palette.customColor.white,
                        boxShadow: "0 4px 13px 0 rgba(97, 97, 97, 0.02)",
                        margin: "5px 0 0 10px",
                        padding: "6px",
                        ul: {
                            padding: 0,
                        },
                        li: {
                            backgroundColor: "transparent !important",
                            color: theme.palette.customColor.darkGrey,
                            fontSize: "16px",
                            lineHeight: "24px",
                            padding: "10px 16px",
                            "&:nth-child(1)": {
                                padding: "0 0 8px 0",
                            },
                            "&.lookup-divider": {
                                padding: "10px 0",
                            },
                            "&.search-input": {
                                padding: "16px 16px 16px 41px",
                            },
                        },
                    },
                },
            }}
        />
    );
};

export default DataAssetDestinationSelector;
import React, {useState, useEffect} from "react";
import {
    Box,
    Typography,
    MenuItem,
    ListSubheader,
    Divider,
    Select,
    SelectChangeEvent,
    Chip,
    TextField,
    InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {ChevronDown, GitBranch} from "lucide-react";
import {getGitBranches} from "services/integrations";
import theme from "theme";
import {useGlobalContext} from "context/global-context";
import CustomSwitch from "../../shared/switch/switch.component";

const HeaderSwitch: React.FC = () => {
    const {
        isEditMode,
        toggleEditMode,
        selectedGitBranch,
        setSelectedGitBranch,
    } = useGlobalContext();

    const [branches, setBranches] = useState<string[]>([]);
    const [open, setOpen] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>("");

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setSearchText("");
    };

    const handleBranchSelect = (event: SelectChangeEvent<string>) => {
        const selectedBranch = event.target.value;
        if (selectedBranch !== selectedGitBranch) {
            setSelectedGitBranch(selectedBranch);
        }
    };

    const handleToggleEditMode = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        toggleEditMode(checked);
    };

    const filteredBranches = branches.filter((branch) =>
        branch.toLowerCase().includes(searchText.toLowerCase())
    );

    useEffect(() => {
        const fetchBranches = async () => {
            try {
                const data = await getGitBranches();
                setBranches(data);

                if (
                    (!selectedGitBranch || !data.includes(selectedGitBranch)) &&
                    data.length > 0
                ) {
                    setSelectedGitBranch(data[0]);
                }
            } catch (error) {
                console.error("Error fetching branches:", error);
            }
        };

        if (branches.length === 0) fetchBranches();
    }, [selectedGitBranch, branches]);

    return (
        <Box className="flex-box-align-center">
            <Box
                className="flex-box-align-center"
                sx={{
                    boxSizing: "border-box",
                    "& .MuiInputBase-input.MuiSelect-select[aria-expanded='true']": {
                        border: `1px solid ${theme.palette.customColor.lightGrey1}`,
                        outline: `2px solid ${theme.palette.customColor.lightGrey1}`
                    },
                    "& MuiInputBase-input.MuiSelect-select[aria-expanded='false']": {
                        border: `1px solid ${theme.palette.customColor.lightGrey1}`,
                        outline: `2px solid transparent`
                    }
                }}
            >
                <Select
                    variant="outlined"
                    fullWidth
                    displayEmpty
                    value={selectedGitBranch ?? ""}
                    onChange={handleBranchSelect}
                    onOpen={handleOpen}
                    onClose={handleClose}
                    MenuProps={{
                        autoFocus: false,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                        },
                        marginThreshold: 0,
                        PaperProps: {
                            sx: {
                                boxSizing: "border-box",
                                width: "249px",
                                borderRadius: "16px",
                                border: `1px solid ${theme.palette.customColor.lightGrey1}`,
                                backgroundColor: theme.palette.customColor.white,
                                boxShadow: "0px 4px 13px 0px rgba(97, 97, 97, 0.02)",
                                marginTop: "10px",
                                padding: "6px",
                                "& ul": {
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "8px",
                                    padding: 0,
                                },
                                "& li": {
                                    padding: 0,
                                },
                                "& .MuiMenuItem-root": {
                                    padding: "10px 16px",
                                },
                            },
                        },
                    }}

                    IconComponent={(props) => (
                        <ChevronDown
                            {...props}
                            style={{
                                color: theme.palette.customColor.dark,
                                width: "17px",
                                height: "17px",
                                strokeWidth: "2.2px",
                                top: "unset !important",
                            }}
                        />
                    )}
                    renderValue={() => (
                        <Box sx={{display: "flex", alignItems: "center"}}>
                            <GitBranch size="18" style={{marginRight: "8px"}}/>
                            {!isEditMode && (
                                <Typography
                                    variant="h5"
                                    noWrap
                                    sx={{color: theme.palette.customColor.dark, mr: "6px"}}
                                >
                                    Edit mode:
                                </Typography>
                            )}
                            {isEditMode && (
                                <Typography
                                    variant="subtitle2"
                                    sx={{color: theme.palette.customColor.dark, mr: "6px"}}
                                >
                                    {selectedGitBranch}
                                </Typography>
                            )}
                            <Chip
                                label={isEditMode ? "EDIT MODE" : "OFF"}
                                sx={{
                                    height: "auto",
                                    fontSize: "12px",
                                    fontWeight: 600,
                                    lineHeight: "20px",
                                    borderRadius: "10px",
                                    mr: "6px",
                                    color: isEditMode
                                        ? theme.palette.customColor.white
                                        : theme.palette.customColor.slateGrey,
                                    backgroundColor: isEditMode
                                        ? theme.palette.customColor.purple
                                        : theme.palette.customColor.lightGrey1,
                                    "& .MuiChip-label": {
                                        padding: "0 6px",
                                    },
                                }}
                            />
                        </Box>
                    )}
                >
                    {/* TODO: // TODO: revert when implementing graph sync */}
                    {/*<ListSubheader>
                         <Box
                            className="flex-box-align-center"
                            sx={{padding: "6px"}}
                        >
                            <CustomSwitch checked={isEditMode} onChange={handleToggleEditMode}/>
                            <Typography
                                variant="subtitle2"
                                sx={{
                                    ml: "14px",
                                    color: theme.palette.customColor.darkGrey,
                                }}
                            >
                                Edit mode
                            </Typography>
                        </Box> 
                        <Divider
                            sx={{
                                height: "1px",
                                borderStyle: "none",
                                borderColor: "transparent",
                                borderRadius: "2px",
                                backgroundColor: theme.palette.customColor.lightGrey1,
                                margin: "8px 0 0 0",
                            }}
                        />
                    </ListSubheader>*/}
                    <ListSubheader>
                        <TextField
                            size="small"
                            autoFocus
                            placeholder="Search Git branch.."
                            fullWidth
                            autoComplete="off"
                            sx={{
                                "& .MuiInputBase-root": {
                                    position: "relative",
                                    paddingLeft: 0,
                                    "& input": {
                                        border: 0,
                                        color: theme.palette.customColor.grey,
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        lineHeight: "22px",
                                        background: theme.palette.customColor.lightestGrey5,
                                        padding: "13px 16px 13px 42px",
                                    },
                                    "& .MuiInputAdornment-root": {
                                        marginRight: 0,
                                        "& .MuiSvgIcon-root": {
                                            color: theme.palette.customColor.darkGrey,
                                        }
                                    },
                                }
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon sx={{
                                            width: "20px",
                                            height: "100%",
                                            position: "absolute",
                                            pointerEvents: "none",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            left: "16px",
                                            zIndex: 999,
                                        }}/>
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(e) => setSearchText(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key !== "Escape") {
                                    e.stopPropagation();
                                }
                            }}
                        />
                    </ListSubheader>
                    {filteredBranches.map((branch) => (
                        <MenuItem
                            key={branch}
                            value={branch}
                            disabled={!isEditMode}
                            sx={{
                                borderRadius: "10px",
                                "&:hover": {
                                    backgroundColor: `${theme.palette.customColor.lightestGrey5} !important`,
                                },
                                "&.Mui-selected": {
                                    backgroundColor: isEditMode
                                        ? `${theme.palette.customColor.lightestGrey5} !important`
                                        : `transparent !important`,
                                },
                                "&.Mui-disabled": {
                                    opacity: 1,
                                }
                            }}
                        >
                            <Typography
                                variant="subtitle2"
                                sx={{
                                    color: isEditMode
                                        ? theme.palette.customColor.darkGrey
                                        : theme.palette.customColor.lavenderGrey,
                                    marginLeft: "8px",
                                }}
                            >
                                {branch}
                            </Typography>
                            {isEditMode && branch === selectedGitBranch && (
                                <span
                                    className="material-symbols-outlined"
                                    style={{
                                        color: theme.palette.primary.main,
                                        position: "absolute",
                                        right: "10px",
                                    }}
                                >
                check
            </span>
                            )}
                        </MenuItem>
                    ))}
                </Select>
            </Box>
        </Box>
    );
};

export default HeaderSwitch;
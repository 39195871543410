import React, {useState} from "react";
import "./onboarding-linear-stepper.component.scss";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import {StepIconProps} from "@mui/material/StepIcon";
import OnboardingStepConnect from "../onboarding-step-connect/onboarding-step-connect.component";
import OnboardingStepConnectGit from "../onboarding-step-connect-git/onboarding-step-connect-git.component";
import OnboardingStepFinish from "../onboarding-step-finish/onboarding-step-finish.component";
import theme from "theme";
import ButtonComponent from "../../shared/button/button.component";
import {ArrowLeftSymbols} from "assets/icons";
import {SnowflakeIntegrationPayload, syncSchemas} from "services/integrations";
import {SnowflakeSchemaNames} from "types";

const steps = [
    "Connect data",
    "Connect Git Repo",
    "Do the magic",
];

export interface GitStepFormValues {
    username?: string,
    token?: string,
    repo: string,
    ssh?: string,
}

const OnboardingLinearStepper = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [gitConnectionValues, setGitConnectionValues] = useState<GitStepFormValues | null>(null);
    const [snowflakeConnectionValues, setSnowflakeConnectionValues] = useState<SnowflakeIntegrationPayload | null>(null);
    const [snowflakeSchemasSelected, setSnowflakeSchemas] = useState<SnowflakeSchemaNames | []>([]);

    const handleNext = () => {
        setActiveStep(prev => prev + 1);
        if (activeStep + 1 === steps.length){
            syncSchemas()
        }
    };

    const handleBack = () => {
        setActiveStep(prev => prev - 1);
    };

    const onGitFormValuesSaved = (values: GitStepFormValues) => {
        setGitConnectionValues(values);
    };
    const onSnowflakeFormValuesSaved = (values: SnowflakeIntegrationPayload) => {
        setSnowflakeConnectionValues(values);
    };

    const onSnowflakeSchemasSaved = (values: SnowflakeSchemaNames) => {
        setSnowflakeSchemas(values);
    };

    const StepIconComponent: React.FC<StepIconProps> = ({active, completed}) => {
        const className = completed
            ? "symbol-checked"
            : active
                ? "symbol-active"
                : "symbol-unchecked";

        return (
            <button
                type="button"
                className={`step-icon ${className}`}
                onClick={handleBack}
                disabled={activeStep === 0}
            >
                {completed && (
                    <span className="material-symbols-outlined">check</span>
                )}
            </button>
        );
    };

    const renderActiveStepContent = (): React.ReactNode => {
        switch (activeStep) {
            case 0:
                return <OnboardingStepConnect handleNext={handleNext}
                                              handleFormValues={onSnowflakeFormValuesSaved}
                                              stepFormValues={snowflakeConnectionValues}/>;
            case 1:
                return <OnboardingStepConnectGit handleNext={handleNext}
                                                 handleFormValues={onGitFormValuesSaved}
                                                 stepFormValues={gitConnectionValues}/>;
            case 2:
                return <OnboardingStepFinish
                    snowflakeSchemasSelected={snowflakeSchemasSelected}
                    handleNext={handleNext}
                    handleFormValues={onSnowflakeSchemasSaved}/>;
            default:
                return <OnboardingStepFinish snowflakeSchemasSelected={snowflakeSchemasSelected}
                                             handleNext={handleNext}
                                             handleFormValues={onSnowflakeSchemasSaved}/>;
        }
    };

    return (
        <Box>
            <Box sx={{position: "relative", height: "100svh"}}>
                {activeStep < steps.length && (
                    <>
                        <Stepper activeStep={activeStep} alternativeLabel
                                 sx={{width: "100%", maxWidth: 376, margin: "0 auto", padding: "40px 0"}}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel
                                        StepIconComponent={(props) => <StepIconComponent {...props} />}
                                        sx={{
                                            "& .MuiStepLabel-alternativeLabel": {marginTop: "0"},
                                            "& .MuiStepLabel-label.Mui-active": {color: theme.palette.customColor.purple}
                                        }}
                                    >
                                        <Typography variant="h5" align="center" marginTop="7px">
                                            {label}
                                        </Typography>
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <Box className="flex-box-row stepper-arrow" sx={{pt: 2}}>
                            {activeStep > 0 && (
                                <ButtonComponent variant="text" label="Back" onClick={handleBack}
                                                 startIcon={<ArrowLeftSymbols/>}/>
                            )}
                            <Box sx={{flex: "1 1 auto"}}/>
                        </Box>
                    </>
                )}
                {activeStep === steps.length && (
                    <Box
                        className="flex-box-col-center"
                        height="100%"
                    >
                        <Typography variant="h1" textAlign="center">
                            🎉
                        </Typography>
                        <Typography variant="h1" sx={{mt: 4, mb: 6}} textAlign="center">
                            Magic in progress! <br/>
                            While Lynk is analyzing,
                        </Typography>
                        <Typography variant="subtitle1" sx={{mt: 2, mb: 1}}>
                            Let’s learn a bit more about your business.
                        </Typography>
                    </Box>
                )}
                {activeStep < steps.length && (
                    <Box className="onboarding-step-content">
                        {renderActiveStepContent()}
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default OnboardingLinearStepper;

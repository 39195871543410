import React from "react";
import ReactDOM from "react-dom/client";
import "index.scss";
import reportWebVitals from "reportWebVitals";
import {ThemeProvider} from "@mui/material";
import theme from "theme";
import "fonts.css";
import {GlobalContextProvider} from "./context/global-context";
import AuthProvider from "./context/auth-context";
import App from "./components/app";
import {LoggedInUserProvider} from "./context/logged-in-user-context";
import { SnackbarProvider } from "notistack";
import NotificationComponent from "components/shared/notification.component";


const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

declare module 'notistack' {
    interface VariantOverrides {
      info: {
        title?: string
      },
      error: {
        title?: string
      },
      success: {
        title?: string
      }
    }
  }

root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <GlobalContextProvider>
                <AuthProvider>
                    <LoggedInUserProvider>
                        <SnackbarProvider 
                            Components={{
                                info: NotificationComponent,
                                error: NotificationComponent,
                                success: NotificationComponent
                            }}
                            anchorOrigin={{horizontal: "right", vertical: "top"}} 
                        />
                        <App/>
                    </LoggedInUserProvider>
                </AuthProvider>
            </GlobalContextProvider>
        </ThemeProvider>
    </React.StrictMode>
);

reportWebVitals();

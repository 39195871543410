import {Box, IconButton, Typography} from "@mui/material";
import theme from "theme";
import AliasInput from "../../../../../alias-input.component";
import React from "react";
import {FirstLastFieldType} from "../../../../../feature-types";
import { DataTypeIcons } from "types/data-types";
import { DataAssetFullResponse } from "services/data-assets";
import FeatureTimeFieldSelect from "components/add-features-component/feature-time-field-select";

interface FirstLastSelectedFieldProps {
    field: FirstLastFieldType,
    selectedAsset?: DataAssetFullResponse,
    selectTimeField?: boolean
    onAliasChanged: (fieldName: FirstLastFieldType["name"], alias: FirstLastFieldType["alias"]) => void,
    onTimeFieldChanged?: (fieldName: FirstLastFieldType["name"], timeField?: string) => void,
    onFieldDeleted: (fieldName: FirstLastFieldType["name"]) => void,
}

const FirstLastSelectedField: React.FC<FirstLastSelectedFieldProps> = ({field, onAliasChanged, onFieldDeleted, onTimeFieldChanged, selectedAsset, selectTimeField}) => {
    return (
        <Box
            key={field.name}
            className="measures-selected"
            sx={{
                position: "relative",
                marginBottom: "12px",
                "&:last-child": {
                    marginBottom: 0,
                },
            }}
        >
            <Box className="flex-box-align-center" sx={{marginBottom: "6px"}}>
                <span
                    className="material-symbols-outlined"
                    style={{
                        fontSize: "22px",
                        color: theme.palette.customColor.purple,
                        marginRight: "6px",
                    }}
                >
                    {DataTypeIcons[field.dataType] || DataTypeIcons["string"]}
                </span>
                <Typography variant="subtitle2" sx={{color: theme.palette.customColor.dark}}>
                    {field.name}
                </Typography>
            </Box>
            <AliasInput onChange={(alias) => onAliasChanged(field.name, alias)}/>
            {selectTimeField &&
                <FeatureTimeFieldSelect 
                    selectedAsset={selectedAsset as DataAssetFullResponse} 
                    value={field.timeField}
                    onChange={(timeField)=> onTimeFieldChanged && onTimeFieldChanged(field.name, timeField)} 
                />
            }
            <IconButton
                onClick={() => onFieldDeleted(field.name)}
                sx={{
                    position: "absolute",
                    top: "4px",
                    right: "4px",
                    color: theme.palette.customColor.darkGrey,
                    "&:hover": {
                        color: theme.palette.customColor.purple,
                    },
                }}
            >
                <span className="material-symbols-outlined" style={{fontSize: "18px"}}>
                    delete
                </span>
            </IconButton>
        </Box>
    )
};

export default FirstLastSelectedField;
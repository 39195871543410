import React, {useCallback, useEffect, useState} from 'react';
import {Box, Divider, TextField, Typography} from "@mui/material";
import theme from "theme";
import CopyButton from "../../shared/button/copy-button.component";
import UsersTable from './users-table/users-table';
import ButtonComponent from 'components/shared/button/button.component';
import UserInviteModal from './user-invite-modal';
import { getUsers, TenantUser } from 'services/system';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import { Warning } from 'assets/icons';
import { usePageTitle } from 'hooks/title';

// TODO: adjust when implementing plans and billing,
// The value should be taken by the plan.
const ALLOWED_USERS = 3

const AdminUsersComponent: React.FC = ({}) => {
    const [openInviteModal, setOpenInviteModal] = useState<boolean>(false);
    const [users, setUsers] = useState<TenantUser[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const {setTitle} = usePageTitle();

    const fetchData = async () => {
        setTitle('Users - Admin')
        setIsLoading(true)
        const data = await getUsers();
        setUsers(data)
        setIsLoading(false)
    };

    useEffect(() => {
        fetchData()
    }, []);

    return (
        <Box>
            <Box className="flex-box-center-space-between" sx={{position:"relative"}}>

                <Typography
                    variant="h2"
                    sx={{
                        color: theme.palette.customColor.dark,
                        fontWeight: 600
                    }}
                >
                Users
                </Typography>
                <Box className="flex-box-col-center" sx={{
                        borderRadius: "12px",
                        background: "rgba(244, 73, 73, 0.10)",
                        fontFamily: "BlinkMacSystemFont",
                        left: 0,
                        right: 0,
                        position: "absolute",
                        margin: "0 auto",
                        padding: "16px",
                        width: "280px",
                        gap: "5px"
                    }}>
                    <Box className="flex-box-start-center" sx={{width: "100%"}}>
                        <Warning />
                        <span style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            lineHeight: "24px"
                        }}>
                            You are using a free plan!
                        </span>
                    </Box>
                    <div style={{
                        fontSize: "15px",
                        fontWeight: "400",
                        lineHeight: "22px",
                        width: "100%"
                    }}>
                        Free plan is limited to {ALLOWED_USERS} users,<br />
                        <a href="#" target="_blank">contact us</a> to upgrade
                    </div>
                </Box>
                <ButtonComponent
                    disabled={users.length >= ALLOWED_USERS}
                    onClick={()=>setOpenInviteModal(true)}
                    variant="contained"
                    label="+ Invite user"
                />
            </Box>
                
            <Divider sx={{
                height: "1px",
                backgroundColor: theme.palette.customColor.lightGrey1,
                borderColor: "unset",
                borderBottomWidth: "inherit",
                margin: "32px 0",
            }}
            />

            { !isLoading && (
                <Box>
                    <UsersTable users={users} onUserDeleted={()=>fetchData()}/>
                </Box>
            ) }
            <LoadingSpinner open={isLoading}/>
            <UserInviteModal
                open={openInviteModal}
                onClose={(shouldRefresh: boolean) => {setOpenInviteModal(false); shouldRefresh && fetchData();}} />
        </Box>
    );
}

export default AdminUsersComponent;
import React from 'react';
import { closeSnackbar, CustomContentProps, SnackbarContent } from 'notistack';
import { Box, Button, SxProps } from '@mui/material';

interface NotificationProps extends CustomContentProps {
    title?: string
}

function WhiteCloseIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <g opacity="0.7">
                <path d="M4.26634 12.6673L3.33301 11.734L7.06634 8.00065L3.33301 4.26732L4.26634 3.33398L7.99967 7.06732L11.733 3.33398L12.6663 4.26732L8.93301 8.00065L12.6663 11.734L11.733 12.6673L7.99967 8.93398L4.26634 12.6673Z" fill="white"/>
            </g>
        </svg>
    )
}

function BlackCloseIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M4.26634 12.6673L3.33301 11.734L7.06634 8.00065L3.33301 4.26732L4.26634 3.33398L7.99967 7.06732L11.733 3.33398L12.6663 4.26732L8.93301 8.00065L12.6663 11.734L11.733 12.6673L7.99967 8.93398L4.26634 12.6673Z" fill="#78737D"/>
        </svg>
    )
}

interface VariantConfiguration {
    closeIcon? : JSX.Element,
    boxStyle: SxProps
}
const variantsStyles: {[key:string]: VariantConfiguration} = {
    success: {
        boxStyle: {
            background: "var(--Accent-Positive, #40B169)",
            color: "white"
        }
    },
    error: {
        boxStyle: {
            background: "#DC2626",
            color: "white"
        }
    },
    info: {
        closeIcon: (<BlackCloseIcon />),
        boxStyle: {
            background: "var(--Core-White, #FFF)",
            color: "var(--Core-Black, #1B1A1C)",
            border: "1px solid var(--Gray-300, #DBDADD)"
        }
    }
}

const NotificationComponent = React.forwardRef<HTMLDivElement, NotificationProps>((props, ref) => {
    const {
        // notistack props and options 👇🏼
        id,
        message,
        variant,
        // custom props 👇🏼
        title,
        ...other
      } = props
      
      const variantStyles = variantsStyles[variant]

      return (
        <SnackbarContent ref={ref} role="alert" {...other}>
            <Box sx={{position: "relative"}}>
                <Button onClick={()=>closeSnackbar(id)}
                    sx={{minWidth: "auto", padding: 0, position: "absolute", top: "10px", right: "5px"}}>
                    {variantStyles.closeIcon ? variantStyles.closeIcon  : <WhiteCloseIcon />}
                </Button>
                <Box sx={{
                    fontFamily: "BlinkMacSystemFont",
                    boxSizing: "border-box",
                    borderRadius: "16px",
                    width: "380px",
                    padding: "20px 24px",
                    fontSize: "16px",
                    lineHeight: "24px",
                    ...variantStyles.boxStyle
                }}>
                    {title && (
                        <Box sx={{
                            fontWeight: 600
                        }}>
                            {title}
                        </Box>)
                    }
                    <Box>{message}</Box>
                </Box>
            </Box>
        </SnackbarContent>
      )
})

export default NotificationComponent;
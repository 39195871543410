import React, { useState, useEffect } from "react";
import { Box, Typography, TextField, IconButton } from "@mui/material";
import theme from "theme";
import AddFormulaFeatureButton from "./add-features-formulas-modal-button/add-features-formulas-modal-button";
import AliasInput from "../../../../alias-input.component";
import { FormulaFeatureSelection, FormulaType} from "../../../../feature-types";
import { useParams } from "react-router-dom";
import { useGlobalContext } from "context/global-context";
import { DataAssetColumn, getDataAssetById } from "services/data-assets";
import DataTypeSelector from "components/shared/data-type/data-type-selector.component";
import { DataType } from "types/data-types";


interface AddFeaturesFormulasProps {
    setCreateFormulaEnabled: (enabled: boolean) => void;
    onChange: (feature: FormulaType) => void;
}

interface FormulaState {
    formulaName: string;
    formulaSQL: string;
    dataType: DataType;
    selectedFormulaData: FormulaFeatureSelection[];
}

const AddFeaturesFormulas: React.FC<AddFeaturesFormulasProps> = ({ setCreateFormulaEnabled, onChange }) => {
    const [formulaState, setFormulaState] = useState<FormulaState>({
        formulaName: "",
        formulaSQL: "",
        selectedFormulaData: [],
        dataType: "string"
    });
    const {entityId} = useParams<{ entityId: string }>();
    const {selectedGitBranch} = useGlobalContext();
    const [features, setFeatures] = useState<DataAssetColumn[]>([]);

    const fetchEntityFeatures = async () => {
        // Fetch entity's virtual asset which includes all features.
        const entityAsset = await getDataAssetById((entityId as string), selectedGitBranch)
        setFeatures(entityAsset.columns)
    }

    useEffect(() => {
        fetchEntityFeatures();
    }, [selectedGitBranch]);

    useEffect(() => {
        onChange({
            name: formulaState.formulaName,
            sql: formulaState.formulaSQL,
            dataType: formulaState.dataType,
            features: formulaState.selectedFormulaData.map(s=>({
                feature: s.name,
                alias: s.alias
            }))
        })
    }, [formulaState]);

    const handleFormChange = (stateData: Partial<FormulaState>) => {
        setFormulaState((prevState) => ({
            ...prevState,
            ...stateData
        }));
    };

    const handleRemoveItem = (id: number) => {
        handleFormChange({
            selectedFormulaData: formulaState.selectedFormulaData.filter((item) => item.id !== id)
        })
    };

    const handleChangeFeature = (alias: string, id: number) => {
        handleFormChange({
            selectedFormulaData: formulaState.selectedFormulaData.map((item) =>
                id === item.id ? { ...item, alias } : item
            )
        });
    };

    useEffect(() => {
        setCreateFormulaEnabled(formulaState.selectedFormulaData.length > 0);
    }, [formulaState.selectedFormulaData, setCreateFormulaEnabled]);

    return (
        <Box className="flex-box-col-start" sx={{gap: "24px"}}>
            <Box sx={{ width: "100%"}}>
                <Typography
                    variant="h5"
                    sx={{ color: theme.palette.customColor.darkGrey, marginBottom: "10px" }}
                >
                    Formula name
                </Typography>
                <TextField
                    value={formulaState.formulaName}
                    onChange={(e) => handleFormChange({formulaName : e.target.value})}
                    sx={{ width: "100%" }}
                    placeholder="Enter formula name"
                />
            </Box>

            <Box sx={{ width: "100%" }}>
                <Typography
                    variant="h5"
                    sx={{ color: theme.palette.customColor.darkGrey, marginBottom: "10px" }}
                >
                    Formula SQL
                </Typography>
                <TextField
                    value={formulaState.formulaSQL}
                    onChange={(e) => handleFormChange({formulaSQL: e.target.value})}
                    sx={{ width: "100%" }}
                    placeholder="Enter formula SQL..."
                />
            </Box>

            <Box sx={{ width: "100%" }}>
                <Typography
                    variant="h5"
                    sx={{ color: theme.palette.customColor.darkGrey, marginBottom: "10px" }}
                >
                    Data Type
                </Typography>
                <DataTypeSelector value={formulaState.dataType} onChange={(e) => handleFormChange({dataType: e})} />
            </Box>

            <Box sx={{ width: "100%"}}>
                <Typography
                    variant="h5"
                    sx={{ color: theme.palette.customColor.darkGrey, marginBottom: "10px" }}
                >
                    Choose a feature:
                </Typography>

                <Box sx={{ width: "100%" }}>
                    {formulaState.selectedFormulaData.map((data) => (
                        <Box
                            key={data.id}
                            className="measures-selected"
                            sx={{
                                position: "relative",
                                marginBottom: "12px",
                                "&:last-child": {
                                    marginBottom: 0,
                                },
                            }}
                        >
                            <Box className="flex-box-align-center" sx={{ marginBottom: "6px" }}>
                                {data.showIcon && (
                                    <span
                                        className="material-symbols-outlined"
                                        style={{
                                            fontSize: "24px",
                                            color: theme.palette.customColor.purple,
                                            marginRight: "6px",
                                        }}
                                    >
                                        match_case
                                    </span>
                                )}
                                <Typography
                                    variant="subtitle2"
                                    sx={{ color: theme.palette.customColor.dark }}
                                >
                                    {data.name}
                                </Typography>
                            </Box>
                            <Box className="flex-box-col-start" sx={{ width: "95%" }}>
                                <AliasInput onChange={(alias) => handleChangeFeature(alias, data.id)} />
                                {/*TODO: uncomment when supporting timeframe <AddFeaturesFormulasSelects /> */}
                            </Box>
                            <IconButton
                                onClick={() => handleRemoveItem(data.id)}
                                sx={{
                                    position: "absolute",
                                    top: "4px",
                                    right: "4px",
                                    color: theme.palette.customColor.darkGrey,
                                    "&:hover": {
                                        color: theme.palette.customColor.purple,
                                    },
                                }}
                            >
                                <span className="material-symbols-outlined" style={{ fontSize: "18px" }}>
                                    delete
                                </span>
                            </IconButton>
                        </Box>
                    ))}
                </Box>

                <AddFormulaFeatureButton
                    features={features}
                    selectedFormulaData={formulaState.selectedFormulaData}
                    onSelected={(data) => handleFormChange({selectedFormulaData: data})}
                />
            </Box>
        </Box>
    );
};

export default AddFeaturesFormulas;
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import { AddEditBusinessEntityStepProps } from '../add-edit-business-entities-step-props';
import { GetAssetFunc, mapRelationToConfig } from 'utils/map-relation-to-config';
import { useGlobalContext } from 'context/global-context';
import { EntityDetailResponse } from 'services/entities';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import { AxiosError } from 'axios';
import WarningMessage from 'components/shared/warning/warning-message.component';
import theme from 'theme';
import { DataAssetColumn, DataAssetFullResponse } from 'services/data-assets';
import { useCacheContext } from 'context/cache-context';
import RelatedEntitiesSelect from './related-entities-select/related-entities-select.component';
import { RelatedConfig } from 'constants/joins';

interface RelatedAssetsStepProps extends AddEditBusinessEntityStepProps<RelatedEntitiesStepFormData> {}

interface RelatedAssetsStepProps extends AddEditBusinessEntityStepProps<RelatedEntitiesStepFormData> {
    entityName: string;
    dimTable: string
}

export interface RelatedEntitiesStepFormData {
    entities: RelatedConfig[];
}

export const relatedEntitiesValuesFromEntity = async (entity: EntityDetailResponse | null, selectedGitBranch: string | null, getAssetFn?: GetAssetFunc): Promise<RelatedEntitiesStepFormData> => {
    return {
        entities: await mapRelationToConfig(entity?.relatedEntities || {}, "entity", selectedGitBranch, getAssetFn)
    }
}

const AddEditBusinessEntitiesRelated: React.FC<RelatedAssetsStepProps> = ({
    handleFormValues,
    originalEntity,
    initialValue,
    formRef,
    dimTable,
    entityName,  // Destructure entityName
}) => {
    const [defaultValue, setDefaultValue] = useState<RelatedEntitiesStepFormData | null>(null)
    const [loading, setLoading] = useState<boolean>(true);
    const [loadError, setLoadError] = useState<any>(null);
    const {selectedGitBranch} = useGlobalContext();
    const { getAndCacheAssetById } = useCacheContext()
    
    const [entityColumns, setEntityColumns] = useState<DataAssetColumn[]>([]);
    const [dimTableData, setDimTableData] = useState<DataAssetFullResponse|null>(null)

    const updateEntityColumns = ()=>{
        setEntityColumns([
            ...(dimTableData?.columns || []),
            ...((originalEntity?.features || []).map(feature=>({
                name: feature.name,
                type: feature.dataType || "string",
                description: ""
            })))
        ])
    }

    const fetchDimTableData = async (assetId?: string) => {
        if (!assetId) return
        try {
            const dimTableData = await getAndCacheAssetById(assetId, selectedGitBranch);
            setDimTableData(dimTableData)
        } catch (error: any) {
            console.log(error.message);
        }
    };

    useEffect(()=>{fetchDimTableData(dimTable)});
    
    useEffect(() => {
        fetchDimTableData(dimTable);
    }, [dimTable]);
    
    useEffect(() => {
        updateEntityColumns()
    }, [dimTableData]);

    const {
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm({ defaultValues: async ()=> {
        let formValue = initialValue
        
        try{
            formValue = initialValue || await relatedEntitiesValuesFromEntity(originalEntity, selectedGitBranch, getAndCacheAssetById)
        } catch (e){
            if (e instanceof AxiosError)
                e = new Error(e.message)
            setLoadError(e)
        }
        setLoading(false)
        setDefaultValue(formValue)
        return formValue
    } });

    const onSubmit = (data: any) => {
        const values = {
            entities: data.entities,
        };
        handleFormValues(values);
    };

    const relatedEntitiesChanged = (data: any) => {
        setValue("entities", data.entities);
    };

    return (
        loading ? <LoadingSpinner open={true}/> : (loadError ? (
            <Box className="flex-box-col" sx={{gap: "30px", overflow: "auto", marginTop: "20px", flexGrow: 1}}>
                <WarningMessage sx={{width: "100%"}} message={`Invalid Entity configuration:`}>
                    <Box sx={{
                        background: theme.palette.customColor.lightGrey3,
                        borderRadius: "10px",
                        padding: "10px",
                        width: "90%",
                        border: `1px solid var(--Gray-300, ${theme.palette.customColor.lightGrey1})`
                    }}>{String(loadError)}</Box>
                    <Box sx={{marginTop: "10px"}}>Please fix the issue first and refresh.</Box>
                </WarningMessage>
            </Box>
        ) : (<Box component="form" ref={formRef} onSubmit={handleSubmit(onSubmit)} className="flex-box-col-center" sx={{ width: '100%' }}>
            <RelatedEntitiesSelect
                initialValues={defaultValue}
                onChange={relatedEntitiesChanged}
                entityColumns={entityColumns}
                entityName={entityName}
            />
        </Box>))
    );
};

export default AddEditBusinessEntitiesRelated;
import React, {useEffect, useMemo, useState} from "react";
import {Box, Typography, MenuItem} from "@mui/material";
import theme from "theme";
import SelectComponent from "components/shared/select/select.component";
import {SelectChangeEvent} from "@mui/material/Select";
import {DataAssetFullResponse} from "services/data-assets";
import {FirstLastType} from "../../../../feature-types";
import { DataTypeIcons } from "types/data-types";

// Define SelectOption type
export interface SelectOption<T=string> {
    id?: string; // Optional if not used in some cases
    title: string;
    icon?: string; // Optional if not used in some cases
    value: T,
}

// Define the type for featuresFirstLastData
export const firstLastMethods: SelectOption<FirstLastType["method"]>[] = [
    {title: "First", value: "first"},
    {title: "Last", value: "last"},
];

interface AddFeaturesFirstLastProps {
    selectedAssetColumns?: DataAssetFullResponse["columns"],
    onMethodUpdated: (method: FirstLastType["method"]) => void,
    onSortByUpdated: (sortBy: FirstLastType["sortBy"]) => void,
    method: FirstLastType["method"],
    sortBy: FirstLastType["sortBy"]
}

const AddFeaturesFirstLastConfiguration: React.FC<AddFeaturesFirstLastProps> = ({
    selectedAssetColumns,
    onMethodUpdated,
    onSortByUpdated,
    method,
    sortBy
}) => {
    const selectOptions = useMemo<SelectOption[]>(() => {
        return selectedAssetColumns?.map(c => ({
            id: c.name,
            title: c.name,
            icon: DataTypeIcons[c.type] || DataTypeIcons.string,
            value: c.name
        })) || [];
    }, [selectedAssetColumns]);

    const onSortChange = (e: SelectChangeEvent<string>) => {
        const newSort = e.target.value as FirstLastType["sortBy"]
        onSortByUpdated(newSort)
    }

    const onMethodChange = (e: SelectChangeEvent<string>) => {
        const newMethod = e.target.value as FirstLastType["method"]
        onMethodUpdated(newMethod)
    }

    return (
        <Box className="flex-box-col-start" sx={{margin: "32px 0"}}>
            <Typography
                variant="subtitle2"
                sx={{
                    color: theme.palette.customColor.darkGrey,
                    fontWeight: 600,
                    margin: "10px 0 12px 0",
                }}
            >
                First / Last Configuration
            </Typography>

            <Box sx={{width: "100%", marginBottom: "32px"}}>
                <Typography
                    variant="h5"
                    sx={{color: theme.palette.customColor.darkGrey, marginBottom: "10px"}}
                >
                    Method
                </Typography>

                <SelectComponent
                    onChange={onMethodChange}
                    options={firstLastMethods}
                    value={method}
                    renderOption={(option) => (
                        <MenuItem key={option.title} value={option.value} disableRipple={true}>
                            {option.title}
                        </MenuItem>
                    )}
                    placeholder="Select method"
                />
            </Box>

            <Box sx={{width: "100%"}}>
                <Typography
                    variant="h5"
                    sx={{color: theme.palette.customColor.darkGrey, marginBottom: "10px"}}
                >
                    Sort by:
                </Typography>

                <SelectComponent
                    onChange={onSortChange}
                    options={selectOptions}
                    value={sortBy}
                    renderOption={(option) => (
                        <MenuItem key={option.id} value={option.title} disableRipple={true}>
                            <Box className="flex-box-row-start" sx={{display: "flex"}}>
                                {option.icon && (
                                    <span className="material-symbols-outlined" style={{marginRight: "8px", lineHeight:"normal"}}>
                                        {option.icon}
                                    </span>
                                )}
                                {option.title}
                            </Box>
                        </MenuItem>
                    )}
                    placeholder="Select sort"
                    showSearch
                    paperProps={{"maxHeight": "440px", marginTop: "-200px"}}
                />
            </Box>

        </Box>
    );
};

export default AddFeaturesFirstLastConfiguration;

import React, { useEffect, useState } from "react";
import { Box, MenuItem, Typography } from "@mui/material";
import theme from "theme";
import DataAssetDestinationSelector from "./related-selector/related-selector.component";
import ButtonComponent from "../../shared/button/button.component";
import RelatedJoinComponent from "../related-join/related-join";
import { DataAssetColumn, DataAssetResponse } from "services/data-assets";
import SelectComponent from "components/shared/select/select.component";
import { relationships } from "constants/entity-relationships";
import { ChevronDown } from "lucide-react";
import { DestinationDataType, JoinType, RelatedConfig, RelatedConfigType, RelatedFieldsJoin, RelatedJoin, RelationshipType } from "constants/joins";

interface EntityRelatedAssetOrEntityBoxProps {
    initialValues: any;
    relatedConfig: RelatedConfig
    onChange:(relatedAsset: RelatedConfig | null)=>void,
    entityColumns: DataAssetColumn[]
    entityName: string
}

const relationshipsOptions = relationships.map(r=>({
    id: r.value,
    value: r.value,
    title: r.label
}))

const EntityRelatedAssetOrEntityBox: React.FC<EntityRelatedAssetOrEntityBoxProps> = ({
    onChange,
    entityColumns,
    entityName,
    initialValues,
    relatedConfig
}) => {
    const [currentRelatedConfig, setRelatedConfig] = useState<RelatedConfig>(relatedConfig);
    
    const handleJoinChange = (id: number, joinIndex: number, newJoin:RelatedJoin | null) => {
        onChange({
            ...currentRelatedConfig,
            joins: newJoin === null ? 
                // null means join deleted
                currentRelatedConfig.joins.filter((_,i)=> i!==joinIndex) :  
                currentRelatedConfig.joins.map((join, i)=> i===joinIndex ? newJoin : {
                    ...join,
                    default: newJoin.default ? false : join.default
                })
        });
    };

    const addJoinToRelation = () => {
        onChange({
            ...currentRelatedConfig,
            joins: [
                ...currentRelatedConfig.joins,
                {
                    name: "",
                    default: false,
                    type: JoinType.fields,
                    fields: [],
                    destination: currentRelatedConfig.joins[0].destination,
                    destinationData: currentRelatedConfig.joins[0].destinationData,
                } as RelatedJoin<RelatedFieldsJoin>
            ]
        })
    };

    const getJoinTableKey = (join: RelatedJoin, joinIndex: number) => {
        let suffix = `${joinIndex}`
        return `${join.destination}-${suffix}`
    }

    const onFinalDestinationChanged = (destination:DestinationDataType)=>{
        onChange({
            id: currentRelatedConfig.id,
            destinationType: currentRelatedConfig.destinationType,
            destination: destination?.id,
            relationship: currentRelatedConfig.relationship,
            joins: [{
                type: "fields",
                default: true,
                destination: destination?.id,
                destinationData: destination,
                fields: [{
                    source: "",
                    destination: "",
                    operator: "equal"
                }]
            } as RelatedJoin<RelatedFieldsJoin>],
        })
    }

    const onRelationTypeChanged = (relationType: RelationshipType)=>{
        const n = {
            ...currentRelatedConfig,
            relationship: relationType
        }
        onChange(n)
    }

    useEffect(()=>{
        setRelatedConfig(relatedConfig)
    },[relatedConfig])

    return (
        <Box
            key={relatedConfig.id}
            sx={{
                boxSizing: "border-box",
                width: "100%",
                borderRadius: "20px",
                border: `1px solid ${theme.palette.customColor.lightGrey1}`,
                padding: "40px",
            }}
        >
            <Box className="flex-box-col-start" sx={{ gap: "32px"}}>
                <Box sx={{ width: "100%" }}>
                    <Box className="flex-box-center-space-between" sx={{ width: "100%", position: "relative", gap: "32px"}}>
                        <Box className="flex-box-col" sx={{flexGrow: 1, gap: "20px"}}>
                            <Typography
                                variant="body2"
                                sx={{
                                    color: theme.palette.customColor.dark,
                                    lineHeight: "22px",
                                }}
                            >
                                {relatedConfig.destinationType == "asset" ? "Data asset" : "Entity"}
                            </Typography>
                            <DataAssetDestinationSelector destinationType={relatedConfig.destinationType} initialValue={relatedConfig.destination} onChange={(e, change)=>onFinalDestinationChanged(change)} />
                        </Box>
                        <Box className="flex-box-col" sx={{flexGrow: 1, gap: "20px"}}>
                            <Typography
                                variant="body2"
                                sx={{
                                    color: theme.palette.customColor.dark,
                                    lineHeight: "22px",
                                }}
                            >
                                Relationship
                            </Typography>

                            <SelectComponent
                                sx={{
                                    "&.MuiOutlinedInput-root": {
                                        "& .MuiSelect-select": {
                                            border: `1px solid ${theme.palette.customColor.lightGrey1}`,
                                            borderRadius: "10px",
                                            boxShadow: "0px 4px 13px 0px rgba(97, 97, 97, 0.02)",
                                            padding: "6px 16px",
                                        },
                                        "& .MuiSelect-iconOutlined": {
                                            top: "calc(50% - .5em) !important",
                                        },
                                    },
                                }}
                                value={relatedConfig.relationship}
                                IconComponent={(props) => <ChevronDown size={18} color="#1B1A1C" {...props} />}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "center",
                                    },
                                    PaperProps: {
                                        sx: {
                                            borderRadius: "16px",
                                            border: `1px solid ${theme.palette.customColor.lightGrey1}`,
                                            background: theme.palette.customColor.white,
                                            boxShadow: "0 4px 13px 0 rgba(97, 97, 97, 0.02)",
                                            margin: "5px 0 0 10px",
                                            padding: "6px",
                                            maxHeight: "300px",
                                            ul: {
                                                padding: 0,
                                            },
                                            li: {
                                                backgroundColor: "transparent !important",
                                                color: theme.palette.customColor.darkGrey,
                                                fontSize: "16px",
                                                lineHeight: "24px",
                                                padding: "10px 16px",
                                                "&.search-input": {
                                                    padding: "16px 16px 16px 41px",
                                                },
                                            },
                                        },
                                    },
                                }}
                                onChange={(e) => onRelationTypeChanged(e.target.value as RelationshipType)}
                                options={relationshipsOptions}
                                renderOption={(option) => (
                                    <MenuItem key={option.value} value={option.value} disableRipple={true}>
                                        {option.title}
                                    </MenuItem>
                                )}
                                placeholder="Select relationship"
                            />
                        </Box>
                        <span
                            className="material-symbols-outlined"
                            style={{ cursor: "pointer", position: "absolute", top: 0, right: 0}}
                            onClick={() => onChange(null)}
                        >
                            delete
                        </span>
                    </Box>

                </Box>

                {currentRelatedConfig.joins.map((join, joinIndex) => (
                    <RelatedJoinComponent
                        key={getJoinTableKey(join, joinIndex)}
                        join={join}
                        destinationType={relatedConfig.destinationType}
                        initialValues={initialValues}
                        onChange={(newJoin)=>{
                            handleJoinChange(relatedConfig.id, joinIndex, newJoin)
                        }}
                        entityColumns={entityColumns}
                        entityName={entityName}
                    /> 
                ))}
            </Box>
            { relatedConfig.destination ? (
                <ButtonComponent
                    onClick={() => addJoinToRelation()}
                    variant="textPurple"
                    label="+ Add join"
                    sx={{
                        fontSize: "16px",
                        marginTop: "25px",
                        padding: 0,
                        "&:hover": {
                            textDecorationLine: "none",
                        },
                    }}
                />
            ) : <></>}
        </Box>
    );
};

export default EntityRelatedAssetOrEntityBox;
import { DataType } from "types/data-types"

// Generic types used for Features
export enum FeatureType {
    metric = "metric",
    firstLast = "first_last",
    formula = "formula",
    field = "field",
}

export interface EntityFeature {
  name: string,
  dataType?: DataType,
  type: FeatureType,
}

export interface AssetFeature extends EntityFeature {
    asset: string,
    filters?: FeatureFilterApi[],
}

export interface MetricFeature extends AssetFeature {
    measure: string
}

export interface FirstLastFeature extends AssetFeature {
    options: {
        method: "first" | "last",
        sortBy: string,
        field: string,
    }
}

export interface FieldFeature extends AssetFeature {
    alias?: string
}

export interface DerivedFeature extends EntityFeature {
    sql: string,
    features: {
        feature: string,
        alias: string
    }[]   
}

export interface MeasureType {
    alias?: string,
    name: string,
    definition: string,
    description?: string,
    selected?: boolean,
    isNew?: boolean,
    dataType: DataType,
    timeField?: string
}

export interface FieldFeatureType {
    alias?: string,
    name: string,
    dataType: DataType,
    timeField?: string
}

export interface FeatureFilterApi {
    field: string;
    operator: string;
    values: (string | number)[];
    type: string;
}
